import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import cross from '../../assets/img/icons8-cancel.svg';
import config from '../../config.json';
import axios from 'axios';
import { useParams } from 'react-router';
import { getAPIHeaders } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import path from 'path';
import commonDoc from '../../assets/img/common-document.png';
import RejectDocumentModal from "./RejectModal";
import ReactDOM from "react-dom";
import ConfirmModal from './ConfirmModal';

const FormButton = (props) => {
  
  const [thumbnails, setThumbnails] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  const [comment, setComment] = useState(null);
  const [rejectModalObject, setRejectModalObject] = useState({ modalVisible: false });
  const [confirmModal, setConfirmModal] = useState({ modalVisible: false });
  const [isRejectedByCustomer, setIsRejectedByCustomer] = useState("");
  const [jobStatus, setJobStatus] = useState("");
  let history = useHistory();
  
  useEffect(() => {
    getThumbnail();
  }, []);
  
  const RejectmodalHandler = (event, modalVisible) => {
    event.preventDefault();
    setRejectModalObject
    ({
      modalVisible: modalVisible,
    });
  }
  
  const handleChange = (event) => {
    const { value } = event.target;
    setComment(value);
  }
  
  const jobRejectHandler = (event) => {
    let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
    axios.put(url, { action: 'Rejected', comment: comment }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  const actionHandler = (event, action) => {
    event.preventDefault();
    let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      if (response.data.job_status == 'Approved') {
        addToast(response.data.message, { appearance: 'success', autoDismiss: true });
        confirmModalAction(event, true);
      } else {
        addToast(response.data.message, { appearance: 'success', autoDismiss: true });
        history.goBack();
      }
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  const getUploadParams = ({ meta }) => { 
    return { 
      url: config.BaseURL + '/v1/jobs/' + job_id + '/forms/' + form_id + '/file-upload',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    }
  }
  
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === 'done') {
      const obj = JSON.parse(xhr.response);
      const _thumbnails = [ ...thumbnails ];
      _thumbnails.push(obj.path);
      setThumbnails(_thumbnails);
    }
  }
  
  const getThumbnail = () => {
    axios.get(config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id, { headers: getAPIHeaders() }).then((response) => {
      setThumbnails(response.data.files);
      setIsRejectedByCustomer(response.data.rejected_by_customer);
      setJobStatus(response.data.job_status);
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  const removeThumbnail = (id, event) => {
    event.preventDefault();
    axios.delete(config.BaseURL + '/v1/jobs/delete-file/' + id, { headers: getAPIHeaders() }).then(function (response) {
      addToast("File Deleted Successfully!", { appearance: 'error', autoDismiss: true });
      getThumbnail();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  const confirmModalHandler = (event, status) => {
    event.preventDefault();
    axios.get(config.BaseURL + '/v1/send-email/' + job_id + '/status/' + status, { headers: getAPIHeaders() }).then(function (response) {
      if (response.data.message) {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      }
      setRejectModalObject({modalVisible: false});
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  const confirmModalAction = (event, modalVisible) => {
    setConfirmModal({
      modalVisible: modalVisible,
    });
  }
  
  return (
    <React.Fragment>
    {ReactDOM.createPortal(<RejectDocumentModal JobId={job_id} isModalVisible={rejectModalObject.modalVisible} RejectmodalHandler={RejectmodalHandler} jobRejectHandler={jobRejectHandler} handleChange={handleChange}  comment={comment} />, document.getElementById('modal-root'))}
    {ReactDOM.createPortal(<ConfirmModal isModalVisible={confirmModal.modalVisible} confirmModalAction={confirmModalAction} confirmModalHandler={confirmModalHandler}  />, document.getElementById('modal-root'))}
    <div className="row mb-5">
    { props.role !== '' && props.role === 'assignee' && ( props.form_status === 'Pending' || props.form_status === 'Rejected' ) ? (
      <div className="col-md-12 custom-dropzone w-25" style={{ justifyContent: 'center'}}>
      <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept="image/*,.doc/*,.docx,.pdf"
      PreviewComponent={null}
      />
      </div>
      )
      : ""
    }
    
    {
      thumbnails.map(item => {
        let crossIcon = props.role !== '' && props.role === 'assignee' && ( props.form_status === 'Pending' || props.form_status === 'Rejected' ) ?
        (<img className="cross" src={ cross } onClick={(event) => removeThumbnail(item.id, event)} />) : "";
        
        return (
          <div className="thumbnail-item mt-4 ml-3">
          {path.extname(item.file) === '.pdf' || path.extname(item.file) === '.doc' || path.extname(item.file) === '.docx' ?
          <>
          <a href={item.file} target="_blank" ><img className="thumbnail-img" src={commonDoc}  /></a>
          <p className="mb-0">{item.original_file_name}</p>
          {crossIcon}
          
          </>
          :
          <>
          <a href={item.file} target="_blank" ><img className="thumbnail-img" src={item.file } /></a>
          <p className="mb-0">{item.original_file_name}</p>
          { crossIcon }
          </>
        }
        </div>
        )
      })
    }
    
    </div>
    <div>
    { props.role !== '' && props.role === 'approver' && props.form_status === 'Pending For Approval' && (<a onClick={(event) => actionHandler(event, 'Approved')} href="#ex" className="continue-btn bg-approve"><i className="fa fa-check"></i>&nbsp; Approve</a>) }
    { props.role !== '' && props.role === 'reviewer' && props.form_status === 'Pending For Review' && (<a onClick={(event) => actionHandler(event, 'Approved')} href="#ex" className="continue-btn bg-approve"><i className="fa fa-check"></i>&nbsp; Review</a>) }
    &nbsp;
    &nbsp;
    { props.role !== '' && props.role === 'approver' && props.form_status === 'Pending For Approval' && (<a onClick={(event) => RejectmodalHandler(event, true)} href="#ex" data-toggle="modal" className="continue-btn bg-rejected"><i className="fa fa-times-circle"></i>&nbsp; Reject </a>) }
    { props.role !== '' && props.role === 'reviewer' && props.form_status == 'Pending For Review' && (<a onClick={(event) => RejectmodalHandler(event, true)} href="#ex" data-toggle="modal" className="continue-btn bg-rejected"><i className="fa fa-times-circle"></i>&nbsp; Reject </a>) }
    
    
    &nbsp;
    { props.role !== '' && props.role === 'approver' && jobStatus === 'Rejected' && isRejectedByCustomer === 1 && props.form_status !== 'Rejected' && (<a onClick={(event) => RejectmodalHandler(event, true)} href="#ex" data-toggle="modal" className="continue-btn bg-rejected"><i className="fa fa-times-circle"></i>&nbsp; Reject </a>) }

    &nbsp;
    { props.role !== '' && props.role === 'assignee' && props.form_status === 'Pending' && (<a onClick={props.submitHandler} href="#ex" className="continue-btn  bg-approve"><i class="fa fa-send"></i>&nbsp; Submit</a>) }
    
    { props.role !== '' && props.role === 'assignee' && props.form_status === 'Rejected' && (<a onClick={props.submitHandler} href="#ex" className="continue-btn  bg-approve"><i class="fa fa-send"></i>&nbsp; Submit</a>) }
    &nbsp;
    { (<Link  to={`/manage-jobs/${props.job_id}/detail`} className="continue-btn"><i class="fa fa-arrow-left"></i>&nbsp; Back</Link>) }
    </div>
    </React.Fragment>
    );
  }
  
  export default FormButton;