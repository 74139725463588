import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import { getComUserBlankObject, getAPIHeaders } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';

const Edit = (props) => {

  const [userFields, setUserFields] = useState(getComUserBlankObject(props.editableObject));
  const [roles, setRoles] = useState([]);
  const { addToast } = useToasts();

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  useEffect(() => {
    axios.get(config.BaseURL + '/v1/roles', { headers: getAPIHeaders() }).then(function (response) {
      setRoles(response.data);
    });
    setUserFields(props.editableObject)
  },[props.editableObject]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const userFieldsObject = {
      ...userFields,
      [name]: value
    }
    setUserFields(userFieldsObject);
  }

  const submitHandler = (event) => {
     event.preventDefault();
     var userData = { ...userFields };
       axios.put(config.BaseURL + "/v1/company/" + userData.id + "/user", userData, { headers: getAPIHeaders() }).then((response) => {
         addToast("User Update Successfully!", { appearance: 'success', autoDismiss: true });
         resetForm(event);
         props.refreshList();
       }).catch(function (error) {
         addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true })
       });
  }

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div className="modal"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Add User</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times; </span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="" method="">
                <div className="form-group">
                  <label>Name</label>
                  <input onChange={handleChange} type="text" name="name" value={userFields['name']} className="form-control" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input onChange={handleChange} type="text" name="email" value={userFields['email']} className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label>Contact No.</label>
                  <input onChange={handleChange} type="text" name="contact_no" value={userFields['contact_no']} className="form-control" placeholder="Contact No." />
                </div>
                {/* <div className="form-group">
                  <label>Role</label>
                  <div className="styled-select">
                    <select onChange={handleChange} name="role_id" value={userFields['role_id']}>
                      <option value=""> Select Role </option>
                      {
                       roles.map((role, index) => {
                         return(
                          <option key={index} value={role.id}>{role.name}</option>
                         )
                       })
                      }
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div> */}
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={onModalClose} type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
              <button onClickCapture={submitHandler} type="button" className="btn btn-primary" onClick="window.location.href='manage-users.php'"><i className="fa fa-save"></i>&nbsp; Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default Edit;