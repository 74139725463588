import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../../config.json';
import { getFlagBlankObject, getAPIHeaders } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';

const Modal = (props) => {

  const[formFields, setFormFields] = useState(getFlagBlankObject());
  const { addToast } = useToasts();

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  useEffect(() => {
    setFormFields(props.editableObject);
  },[props.editableObject]);

  const handleChange = (event) => {
   event.preventDefault();
   const { name, value } = event.target;
   const formFieldsObject = {
     ...formFields,
     [name]: value
   }
   setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
     var formData = { ...formFields };
     if (!formData.hasOwnProperty("id")) {
      axios.post(config.BaseURL + "/v1/flags", formData, { headers: getAPIHeaders() }).then((response) => {
        addToast("Flag Added Successfully!", { appearance: 'success', autoDismiss: true });
         resetForm(event);
         props.refreshList();
       }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
     } else {
      axios.put(config.BaseURL + "/v1/flags/" + formData.id, formData, { headers: getAPIHeaders() }).then((response) => {
        addToast("Flag Updated Successfully!", { appearance: 'success', autoDismiss: true });
        resetForm(event);
        props.refreshList();
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
     }
  }

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }
  
  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div className="modal"  aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Add Flag</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times; </span>
              </button>
            </div>
            <div className="modal-body">
              <form  action="" method="">
                <div className="form-group">
                  <input onChange={handleChange} type="text" name="name" value={formFields['name']} className="form-control" placeholder="Add Flag" />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={submitHandler} type="button" className="btn btn-primary" data-dismiss="modal"><i className="fa fa-save"></i>&nbsp; Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default Modal;