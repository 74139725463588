import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const WetChemicalSystem = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', technical_detail_make_type: '', technical_detail_manufacturing_date: '', technical_detail_serial_number: '', technical_detail_control_manual_auto: '', technical_detail_extinguishing_medium: '', technical_detail_tank_capacity: '', technical_detail_last_pr_test_date_cylinder: '', technical_detail_coverage_area: '', inspection_type_annual: false, inspection_type_five: false, inspection_type_ten: false, secure_service_valve_carried_out: false, secure_service_valve_not_carried_out: false, secure_service_valve_not_applicable: false, secure_service_valve_comment: '', pull_cable_panel_carried_out: false, pull_cable_panel_not_carried_out: false, pull_cable_panel_not_applicable: false, pull_cable_panel_comment: '', check_content_chemical_carried_out: false, check_content_chemical_not_carried_out: false, check_content_chemical_not_applicable: false, check_content_chemical_comment: '', discharge_sprinkler_heads_carried_out: false, discharge_sprinkler_heads_not_carried_out: false, discharge_sprinkler_heads_not_applicable: false, discharge_sprinkler_heads_comment: '', propellant_cylinder_and_alarm_tested: false, propellant_cylinder_and_alarm_carried_out: false, propellant_cylinder_and_alarm_not_carried_out: false, propellant_cylinder_and_alarm_not_applicable: false, propellant_cylinder_and_alarm_comment: '', blow_through_line_carried_out: false, blow_through_line_not_carried_out: false, blow_through_line_not_applicable: false, blow_through_line_comment: '', system_re_connected_sealed_carried_out: false, system_re_connected_sealed_not_carried_out: false, system_re_connected_sealed_not_applicable: false, system_re_connected_sealed_comment: '', service_engineer_name: '', service_engineer_sign: '' });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Wet Chemical System" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <label>Technical Details</label>
                  <div class="row p-0">
                    <table class="table table-responsive table-striped table-hover">
                      <tbody>
                        <tr>
                          <td>Make/Type</td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_make_type" value={formFields['technical_detail_make_type']} class="form-control" placeholder="Make/Type" disabled={props.can_edit_form} /></td>
                          <td>Manufacturing Date</td>
                          <td><input onChange={handleChange} type="date" name="technical_detail_manufacturing_date" value={formFields['technical_detail_manufacturing_date']} class="form-control" placeholder="Manufacturing Date" disabled={props.can_edit_form} /></td>
                        </tr>
                        <tr>
                          <td>Serial Number</td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_serial_number" value={formFields['technical_detail_serial_number']} class="form-control" placeholder="Serial Number" disabled={props.can_edit_form} /></td>
                          <td>Control: Manual/ Auto </td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_control_manual_auto" value={formFields['technical_detail_control_manual_auto']} class="form-control" placeholder="Control: Manual/ Auto " disabled={props.can_edit_form} /></td>
                        </tr>
                        <tr>
                          <td>Extinguishing Medium</td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_extinguishing_medium" value={formFields['technical_detail_extinguishing_medium']} class="form-control" placeholder="Extinguishing Medium" disabled={props.can_edit_form} /></td>
                          <td>Capacity Of Tank/cylinder</td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_tank_capacity" value={formFields['technical_detail_tank_capacity']} class="form-control" placeholder="Capacity Of Tank/cylinder" disabled={props.can_edit_form} /></td>
                        </tr>
                        <tr>
                          <td>Last Pr. Test Date Of Cylinder</td>
                          <td><input onChange={handleChange} type="date" name="technical_detail_last_pr_test_date_cylinder" value={formFields['technical_detail_last_pr_test_date_cylinder']} class="form-control" placeholder="Last Pr. Test Date Of Cylinder" disabled={props.can_edit_form} /></td>
                          <td>Coverage Area</td>
                          <td><input onChange={handleChange} type="text" name="technical_detail_coverage_area" value={formFields['technical_detail_coverage_area']} class="form-control" placeholder="Coverage Area" disabled={props.can_edit_form} /></td>
                        </tr>
                        <tr>
                          <td>Type Of Inspection</td>
                          <td><input type="checkbox" onClick={checkboxHandleChange} name="inspection_type_annual" checked={formFields['inspection_type_annual']} class="mr-2" disabled={props.can_edit_form} /> Annual</td>
                          <td><input type="checkbox" onClick={checkboxHandleChange} name="inspection_type_five" checked={formFields['inspection_type_five']} class="mr-2" disabled={props.can_edit_form} /> 5 Yearly</td>
                          <td><input type="checkbox" onClick={checkboxHandleChange} name="inspection_type_ten" checked={formFields['inspection_type_ten']} class="mr-2" disabled={props.can_edit_form} /> 10 Yearly</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: "5%" }} />
                      <col style={{ width: "45%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Description of Inspection / Test</th>
                        <th className="text-center">Carried Out</th>
                        <th className="text-center">Not Carried Out</th>
                        <th className="text-center">Not Applicable</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Close and secure service valve/s</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="secure_service_valve_carried_out" checked={formFields['secure_service_valve_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="secure_service_valve_not_carried_out" checked={formFields['secure_service_valve_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="secure_service_valve_not_applicable" checked={formFields['secure_service_valve_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="secure_service_valve_comment" value={formFields['secure_service_valve_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Check pull cable panel</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="pull_cable_panel_carried_out" checked={formFields['pull_cable_panel_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="pull_cable_panel_not_carried_out" checked={formFields['pull_cable_panel_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="pull_cable_panel_not_applicable" checked={formFields['pull_cable_panel_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="pull_cable_panel_comment" value={formFields['pull_cable_panel_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Check content chemical</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="check_content_chemical_carried_out" checked={formFields['check_content_chemical_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="check_content_chemical_not_carried_out" checked={formFields['check_content_chemical_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="check_content_chemical_not_applicable" checked={formFields['check_content_chemical_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="check_content_chemical_comment" value={formFields['check_content_chemical_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Checked discharge sprinkler heads </td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="discharge_sprinkler_heads_carried_out" checked={formFields['discharge_sprinkler_heads_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="discharge_sprinkler_heads_not_carried_out" checked={formFields['discharge_sprinkler_heads_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="discharge_sprinkler_heads_not_applicable" checked={formFields['discharge_sprinkler_heads_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="discharge_sprinkler_heads_comment" value={formFields['discharge_sprinkler_heads_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Checked N2 propellant cylinder and alarm Tested <input type="checkbox" onClick={checkboxHandleChange} name="propellant_cylinder_and_alarm_tested" checked={formFields['propellant_cylinder_and_alarm_tested']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="propellant_cylinder_and_alarm_carried_out" checked={formFields['propellant_cylinder_and_alarm_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="propellant_cylinder_and_alarm_not_carried_out" checked={formFields['propellant_cylinder_and_alarm_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="propellant_cylinder_and_alarm_not_applicable" checked={formFields['propellant_cylinder_and_alarm_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="propellant_cylinder_and_alarm_comment" value={formFields['propellant_cylinder_and_alarm_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Blow Through line with Nozzle</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="blow_through_line_carried_out" checked={formFields['blow_through_line_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="blow_through_line_not_carried_out" checked={formFields['blow_through_line_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="blow_through_line_not_applicable" checked={formFields['blow_through_line_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="blow_through_line_comment" value={formFields['blow_through_line_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>System re-connected, sealed and left in operational order with inspection date labels attached and confirmed by vessel officer in charge.</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="system_re_connected_sealed_carried_out" checked={formFields['system_re_connected_sealed_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="system_re_connected_sealed_not_carried_out" checked={formFields['system_re_connected_sealed_not_carried_out']} disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} name="system_re_connected_sealed_not_applicable" checked={formFields['system_re_connected_sealed_not_applicable']} disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="system_re_connected_sealed_comment" value={formFields['system_re_connected_sealed_comment']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}

export default WetChemicalSystem;