import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const FoamConcentrateTest = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', sample_manufacturer: '', concentration_1: false, concentration_2: false, concentration_3: false, concentration_6: false, concentration_other: false, dom: '', date_of_tank_filling: '', type_protein: false, type_fluro_protein: false, type_synthetic: false, expansion_low: false, expansion_medium: false, expansion_high: false, expansion_afff: false, volume_of_sample: '', sample_form_top: false, sample_form_middle: false, sample_form_bottom: false, manufacture_form_system: '', proteched_areas_deck: false, proteched_areas_helideck: false, proteched_areas_engine_pump: false, proteched_areas_others: false, capacity_of_tank: '', location_of_tank_deck: false, location_of_tank_helideck: false, location_of_tank_engine_pump: false, location_of_tank_others: false, material_of_tank_steel_coated: false, material_of_tank_steel_uncoated: false, material_of_tank_fibre_glass: false, material_of_tank_stainless_steel: false, sea_water_mix_1: false, sea_water_mix_2: false, sea_water_mix_3: false, sea_water_mix_6: false, sea_water_mix_others: false, test_values_1: '', result_1: '', test_values_2: '', result_2: '', test_values_3: '', result_3: '', test_values_4: '', result_4: '', test_values_5: '', result_5: '', test_values_6: '', result_6: '', test_values_7: '', result_7: '', standard_7: '', test_values_8: '', result_8: '', standard_8: '', test_values_9: '', result_9: '', standard_9: '', test_values_10: '', result_10: '', test_values_11: '', result_11: '', standard_11: '', test_values_12: '', acceptance_critetia_12: '', result_12: '', test_values_13: '', acceptance_critetia_13: '', result_13: '', test_values_14: '', acceptance_critetia_14: '', result_14: '', service_engineer_name: '', service_engineer_sign: '' });
  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  return (
    <React.Fragment>
      <InnerHeader title="Foam Concentrate Test" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" onChange={handleChange} id="start" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '70%' }} />
                    </colgroup>
                    <tr>
                      <th colspan="2" className="text-center">TECHNICAL DESCRIPTION OF SAMPLE</th>
                    </tr>
                    <tr>
                      <td>Manufacturer</td>
                      <td><input type="text" name="sample_manufacturer" onChange={handleChange} value={formFields['sample_manufacturer']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Concentration</td>
                      <td>
                        <input type="checkbox" name="concentration_1" onChange={checkboxHandleChange} checked={formFields['concentration_1']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />1% &nbsp;
                        <input type="checkbox" name="concentration_2" onChange={checkboxHandleChange} checked={formFields['concentration_2']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />2% &nbsp;
                        <input type="checkbox" name="concentration_3" onChange={checkboxHandleChange} checked={formFields['concentration_3']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />3% &nbsp;
                        <input type="checkbox" name="concentration_6" onChange={checkboxHandleChange} checked={formFields['concentration_6']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />6% &nbsp;
                        <input type="checkbox" name="concentration_other" onChange={checkboxHandleChange} checked={formFields['concentration_other']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Other%
                      </td>
                    </tr>
                    <tr>
                      <td>Date Of Manufacturing </td>
                      <td><input type="date" name="dom" onChange={handleChange} value={formFields['dom']} className="form-control pointer-cursor-class" placeholder="Date Of Manufacturing" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Date Of Tank Filling</td>
                      <td><input type="date" name="date_of_tank_filling" onChange={handleChange} value={formFields['date_of_tank_filling']} className="form-control pointer-cursor-class" placeholder="Date Of Tank Filling" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>
                        <input type="checkbox" name="type_protein" onChange={checkboxHandleChange} checked={formFields['type_protein']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Protein &nbsp;
                        <input type="checkbox" name="type_fluro_protein" onChange={checkboxHandleChange} checked={formFields['type_fluro_protein']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Fluro protein &nbsp;
                        <input type="checkbox" name="type_synthetic" onChange={checkboxHandleChange} checked={formFields['type_synthetic']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Synthetic
                      </td>
                    </tr>
                    <tr>
                      <td>Expansion</td>
                      <td>
                        <input type="checkbox" name="expansion_low" onChange={checkboxHandleChange} checked={formFields['expansion_low']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Low &nbsp;
                        <input type="checkbox" name="expansion_medium" onChange={checkboxHandleChange} checked={formFields['expansion_medium']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Medium &nbsp;
                        <input type="checkbox" name="expansion_high" onChange={checkboxHandleChange} checked={formFields['expansion_high']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />High &nbsp;
                        <input type="checkbox" name="expansion_afff" onChange={checkboxHandleChange} checked={formFields['expansion_afff']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />AFFF
                      </td>
                    </tr>
                    <tr>
                      <td>Volume Of Sample (2 Ltrs min)</td>
                      <td><input type="text" name="volume_of_sample" onChange={handleChange} value={formFields['volume_of_sample']} className="form-control" placeholder="Volume Of Sample" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Sample From</td>
                      <td>
                        <input type="checkbox" name="sample_form_top" onChange={checkboxHandleChange} checked={formFields['sample_form_top']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Top &nbsp;
                        <input type="checkbox" name="sample_form_middle" onChange={checkboxHandleChange} checked={formFields['sample_form_middle']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Middle &nbsp;
                        <input type="checkbox" name="sample_form_bottom" onChange={checkboxHandleChange} checked={formFields['sample_form_bottom']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Bottom
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '70%' }} />
                    </colgroup>
                    <tr>
                      <th colspan="2" className="text-center">TECHNICAL DESCRIPTION OF FOAM SYSTEM</th>
                    </tr>
                    <tr>
                      <td>Manufacturer</td>
                      <td><input type="text" onChange={handleChange} name="manufacture_form_system" value={formFields['manufacture_form_system']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Protected Areas</td>
                      <td>
                        <input type="checkbox" onChange={checkboxHandleChange} name="proteched_areas_deck" checked={formFields['proteched_areas_deck']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Deck &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="proteched_areas_helideck" checked={formFields['proteched_areas_helideck']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Helideck &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="proteched_areas_engine_pump" checked={formFields['proteched_areas_engine_pump']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Engine Room/ Pump Room &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="proteched_areas_others" checked={formFields['proteched_areas_others']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Others
                      </td>
                    </tr>
                    <tr>
                      <td>Capacity of Tank</td>
                      <td><input type="text" onChange={handleChange} name="capacity_of_tank" value={formFields['capacity_of_tank']} className="form-control" placeholder="Capacity of Tank" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Location Of Tank</td>
                      <td>
                        <input type="checkbox" onChange={checkboxHandleChange} name="location_of_tank_deck" checked={formFields['location_of_tank_deck']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Deck &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="location_of_tank_helideck" checked={formFields['location_of_tank_helideck']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Helideck &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="location_of_tank_engine_pump" checked={formFields['location_of_tank_engine_pump']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Engine Room/ Pump Room &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="location_of_tank_others" checked={formFields['location_of_tank_others']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Others
                      </td>
                    </tr>
                    <tr>
                      <td>Material Of Tank</td>
                      <td>
                        <input type="checkbox" onChange={checkboxHandleChange} name="material_of_tank_steel_coated" checked={formFields['material_of_tank_steel_coated']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Steel-Coated &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="material_of_tank_steel_uncoated" checked={formFields['material_of_tank_steel_uncoated']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Steel-Uncoated &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="material_of_tank_fibre_glass" checked={formFields['material_of_tank_fibre_glass']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Fibre Glass &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="material_of_tank_stainless_steel" checked={formFields['material_of_tank_stainless_steel']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Stainless Steel
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '70%' }} />
                    </colgroup>
                    <tr>
                      <th colspan="2" className="text-center">TESTS PERFORMED</th>
                    </tr>
                    <tr>
                      <td>Mixture Prepared with Lab made Sea water</td>
                      <td>
                        <input type="checkbox" onChange={checkboxHandleChange} name="sea_water_mix_1" checked={formFields['sea_water_mix_1']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />1% &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="sea_water_mix_2" checked={formFields['sea_water_mix_2']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />2% &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="sea_water_mix_3" checked={formFields['sea_water_mix_3']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />3% &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="sea_water_mix_6" checked={formFields['sea_water_mix_6']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />6% &nbsp;
                        <input type="checkbox" onChange={checkboxHandleChange} name="sea_water_mix_others" checked={formFields['sea_water_mix_others']} className="mr-2" autocomplete="off" disabled={props.can_edit_form} />Others%
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <table className="table table-responsive table-striped table-hover">
                  <colgroup>
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th colspan="1">Type of Tests</th>
                      <th className="text-center">Test Values</th>
                      <th className="text-center">Acceptance Criteria</th>
                      <th className="text-center">Results</th>
                      <th className="text-center">Standards</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="5">1.&nbsp; FOAM PROPERTY TESTS</td>
                    </tr>
                    <tr>
                      <td>a) Expansion Ratio</td>
                      <td><input type="text" onChange={handleChange} name="test_values_1" value={formFields['test_values_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Less Than 20-LOW, 20-500-MED, Greater Than 500-HIGH</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_1" value={formFields['result_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">EN 1568-G</td>
                    </tr>
                    <tr>
                      <td>b) Viscosity@25 Deg C</td>
                      <td><input type="text" onChange={handleChange} name="test_values_2" value={formFields['test_values_2']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Less Than 20 cSt</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_2" value={formFields['result_2']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ISO 3104</td>
                    </tr>
                    <tr>
                      <td colspan="5">2.&nbsp; PHYSIOCHEMICAL TESTS</td>
                    </tr>
                    <tr>
                      <td>a) PH Value</td>
                      <td><input type="text" name="test_values_3" onChange={handleChange} value={formFields['test_values_3']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">6-9.8</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_3" value={formFields['result_3']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ASTM D1293, EN 1568</td>
                    </tr>
                    <tr>
                      <td>b) SP. Gravity</td>
                      <td><input type="text" name="test_values_4" onChange={handleChange} value={formFields['test_values_4']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Greater Than 1.0</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_4" value={formFields['result_4']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ASTM D4052</td>
                    </tr>
                    <tr>
                      <td>c) 25% Drainage Time</td>
                      <td><input type="text" name="test_values_5" onChange={handleChange} value={formFields['test_values_5']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Greater Than 5min</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_5" value={formFields['result_5']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">EN 1568-G</td>
                    </tr>
                    <tr>
                      <td>d) Sediment %v/v</td>
                      <td><input type="text" name="test_values_6" onChange={handleChange} value={formFields['test_values_6']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Less Than 0.25%</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_6" value={formFields['result_6']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">EN 1568-C</td>
                    </tr>
                    <tr>
                      <td colspan="5">3.&nbsp; CHEMICAL PROPERTY TESTS</td>
                    </tr>
                    <tr>
                      <td>a) Freezing And Thawing</td>
                      <td><input type="text" name="test_values_7" onChange={handleChange} value={formFields['test_values_7']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">No Stratification, Non-homogeneity, or Sedimentation for 7 days</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_7" value={formFields['result_7']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="standard_7" value={formFields['standard_7']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>b) Heat Stability</td>
                      <td><input type="text" name="test_values_8" onChange={handleChange} value={formFields['test_values_8']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">7 days at 60 deg C</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_8" value={formFields['result_8']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="standard_8" value={formFields['standard_8']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td colspan="5">4.&nbsp; SMALL SCALE FIRE TEST-AIR TEMP-17 DEG C, FUEL –17 DEG C, W ATER-19.5 DEG C, FOAM SOLUTION TEMP-19.5DEG C, W IND SPEED-3 M.SEC</td>
                    </tr>
                    <tr>
                      <td>a) Extinction Time</td>
                      <td><input type="text" name="test_values_9" onChange={handleChange} value={formFields['test_values_9']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Less Than 5min</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_9" value={formFields['result_9']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="standard_9" value={formFields['standard_9']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>b) Burnback Time</td>
                      <td><input type="text" name="test_values_10" onChange={handleChange} value={formFields['test_values_10']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Greater Than 15 mins for 25% of surface</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_10" value={formFields['result_10']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">EN 1568-I</td>
                    </tr>
                    <tr>
                      <td>c) Corrosiveness</td>
                      <td><input type="text" name="test_values_11" onChange={handleChange} value={formFields['test_values_11']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">Non-Corrosive to plastic and Metal</td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_11" value={formFields['result_11']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="standard_11" value={formFields['standard_11']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td colspan="5">5.&nbsp; PREMIX SAMPLE TESTS</td>
                    </tr>
                    <tr>
                      <td>d) Refractive Index Test</td>
                      <td><input type="text" name="test_values_12" onChange={handleChange} value={formFields['test_values_12']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" name="acceptance_critetia_12" onChange={handleChange} value={formFields['acceptance_critetia_12']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_12" value={formFields['result_12']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ASTM D1218</td>
                    </tr>
                    <tr>
                      <td>e) Pour Point</td>
                      <td><input type="text" name="test_values_13" onChange={handleChange} value={formFields['test_values_13']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" name="acceptance_critetia_13" onChange={handleChange} value={formFields['acceptance_critetia_13']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_13" value={formFields['result_13']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ASTM D5950</td>
                    </tr>
                    <tr>
                      <td>f) Surface Tension</td>
                      <td><input type="text" name="test_values_14" onChange={handleChange} value={formFields['test_values_14']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" name="acceptance_critetia_14" onChange={handleChange} value={formFields['acceptance_critetia_14']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left"><input type="text" onChange={handleChange} name="result_14" value={formFields['result_14']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      <td className="text-left">ISO 304, ASTM D-971</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12 col-sm-12">Periodicalcontrol of foam concentrate is to be performed in accordance with IMO MSC/Circ. 1312 and MSC/Circ.670 for high expansion foam samples.</div>
              <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
              </div>
            </div>
          </form>
          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
export default FoamConcentrateTest;