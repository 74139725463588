import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const TemperatureCalibrator = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', calibrator_maker: '', master_thermocouple_maker: '', calibrator_model_no: '', master_thermocouple_model_no: '', calibrator_serial_no: '', master_thermocouple_serial_no: '', calibrator_range: '', master_thermocouple_range: '', description_inspection: '', digital_temperature_guage_make: '', digital_temperature_guage_model: '', digital_temperature_guage_serial: '', digital_temperature_guage_range: '', test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '', custom_list: [] });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.custom_list[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addMakeRow = () => {
    const _formFields = { ...formFields };
    _formFields.custom_list.push({ type_of_gas_cylinder: '', cylinder_serial_no: '', cylinder_volume: '', tare_weight_kg: '', gross_weight_kg: '', check_weight_kg: '', testing_pressure_bar: '', working_pressure_bar: '', last_pressure_test_date: '', temp: '', working_codes: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.custom_list.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }


  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }


  return (
    <React.Fragment>
      <InnerHeader title="Temperature Calibrator" />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid">
          <form name="" action="" autoComplete="off">
            <div class="bg-white has-shadow">
              <div class="row px-0 pb-0">
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} class="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} class="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} class="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} class="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} class="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} class="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Class</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Flag</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} class="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span class="fa fa-calendar left-side"></span> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 mt-3">
                  <table class="table table-responsive table-striped table-hover">
                    <thead class="height-50">
                      <th colspan="2" class="text-center">Calibrator</th>
                      <th colspan="2" class="text-center">Master Thermocouple</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Maker</td>
                        <td><input onChange={handleChange} type="text" name="calibrator_maker" value={formFields['calibrator_maker']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Maker </td>
                        <td><input onChange={handleChange} type="text" name="master_thermocouple_maker" value={formFields['master_thermocouple_maker']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model No.</td>
                        <td><input onChange={handleChange} type="text" name="calibrator_model_no" value={formFields['calibrator_model_no']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Model No.</td>
                        <td><input onChange={handleChange} type="text" name="master_thermocouple_model_no" value={formFields['master_thermocouple_model_no']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Serial No.</td>
                        <td><input onChange={handleChange} type="text" name="calibrator_serial_no" value={formFields['calibrator_serial_no']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial No.</td>
                        <td><input onChange={handleChange} type="text" name="master_thermocouple_serial_no" value={formFields['master_thermocouple_serial_no']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Range</td>
                        <td><input onChange={handleChange} type="text" name="calibrator_range" value={formFields['calibrator_range']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Range</td>
                        <td><input onChange={handleChange} type="text" name="master_thermocouple_range" value={formFields['master_thermocouple_range']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input onChange={handleChange} type="text" name="description_inspection" value={formFields['description_inspection']} placeholder="The temperaturegauge has been calibrated by comparison with digital temperaturecalibrator." class="form-control" autocomplete="off" disabled={props.can_edit_form} />
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <h4 class="mb-0">Reference Digital Temperature Guage</h4>
                </div>

                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th class="text-center">MAKE</th>
                        <th class="text-center">MODEL</th>
                        <th class="text-center">SERIAL</th>
                        <th class="text-center">RANGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input onChange={handleChange} type="text" name="digital_temperature_guage_make" value={formFields['digital_temperature_guage_make']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="digital_temperature_guage_model" value={formFields['digital_temperature_guage_model']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="digital_temperature_guage_serial" value={formFields['digital_temperature_guage_serial']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="digital_temperature_guage_range" value={formFields['digital_temperature_guage_range']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <h4 class="mb-0">Calibration Results</h4>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">APPLIED TEMPERATURE(Deg Celcius)</th>
                        <th colspan="2" class="text-center">READING (Deg Celcius)</th>
                        <th colspan="2" class="text-center">DEVIATION (Deg Celcius)</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td>&nbsp;</td>
                      </tr>
                      {
                        formFields.custom_list.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows(event, 'applied_temperature_increasing', index)} value={item['applied_temperature_increasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'applied_temperature_decreasing', index)} value={item['applied_temperature_decreasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'reading_increasing', index)} value={item['reading_increasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'reading_decreasing', index)} value={item['reading_decreasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'deviation_increasing', index)} value={item['deviation_increasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'deviation_decreasing', index)} value={item['deviation_decreasing']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td class="vertical-mid"><button onClick={(event) => handleRemoveFields(event, index)} type="button" class="removeBtn"><i class="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div class="text-left mb-3 disable-button-custom">
                    <button onClick={() => addMakeRow()} type="button" class="btn btn-success mr-2" disabled={props.can_edit_form}><i class="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Test Result</label>
                  <textarea onChange={handleChange} name="test_result" value={formFields['test_result']} placeholder="We hereby certify that we have tested the above instrument in accordance tostandard inspection procedures and that the quality is in full conformance with the specifications and the standards." class="form-control height-100" autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea onChange={handleChange} name="tracebility" value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" class="form-control height-100" autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea onChange={handleChange} name="remarks" value={formFields['remarks']} placeholder="Remarks" class="form-control height-100" autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border my-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default TemperatureCalibrator;