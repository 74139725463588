import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";


const Co2CylinerTestingRecord = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', system_description: '', service_engineer_name: '', service_engineer_sign: '', bottom_list_result: [] });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }


  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.bottom_list_result[index][name] = event.target.value;
    console.log(_formFields.bottom_list_result);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.bottom_list_result.push({ type_of_gas_cylinders: '', cylinder_serial_no: '', last_test_date: '', date_tested: '', cylinder_volume: '', tare_weight_kg: '', gross_weight_kg: '', check_weight_kg: '', testing_pressure_bar: '', lr_serial_number: '', make: '', working_codes: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.bottom_list_result.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }



  return (
    <React.Fragment>
      <InnerHeader title="Co2 Cylinder Testing Record" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Description/ System</label>
                  <input onChange={handleChange} type="text" name="system_description" value={formFields['system_description']} placeholder="Description/ System" className="form-control" disabled={props.can_edit_form} />
                </div>

                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Working Codes</label>
                    <div className="row p-0">
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tbody><tr>
                            <td>1</td>
                            <td>External inspectionand Maintenance</td>
                          </tr>
                            <tr>
                              <td>2</td>
                              <td>Contents checked by weight  </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Contents checked by pressure</td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Contents checked by level</td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Recharging</td>
                            </tr>
                          </tbody></table>
                      </div>
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tbody><tr>
                            <td>6</td>
                            <td>Hydrostatic test</td>
                          </tr>
                            <tr>
                              <td>7</td>
                              <td>Valve renewal</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Valve repair</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>Condemned</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>New cylinder</td>
                            </tr>
                          </tbody></table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12" style={{ overflowX: "auto" }}>
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type of Gas Cylinder</th>
                        <th>Cylinder Serial Number</th>
                        <th>Last Test Date</th>
                        <th>Date Tested</th>
                        <th>Cylinder Volume (KG)</th>
                        <th>Tare Weight (KG)</th>
                        <th>Gross Weight (KG)</th>
                        <th>Check weight</th>
                        <th>Testing Pressure (Bar)</th>
                        <th>LR Serial Number</th>
                        <th>Make</th>
                        <th>Working Codes</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.bottom_list_result.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td><input onChange={(event) => handleChangeRows(event, 'type_of_gas_cylinders', index)} type="text" name="type_of_gas_cylinders" value={item['type_of_gas_cylinders']} className="form-control" placeholder="CO2" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_serial_no', index)} type="text" name="cylinder_serial_no" value={item['cylinder_serial_no']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'last_test_date', index)} type="date" name="last_test_date" value={item['last_test_date']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'date_tested', index)} type="date" name="date_tested" value={item['date_tested']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_volume', index)} type="text" name="cylinder_volume" value={item['cylinder_volume']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'tare_weight_kg', index)} type="text" name="tare_weight_kg" value={item['tare_weight_kg']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'gross_weight_kg', index)} type="text" name="gross_weight_kg" value={item['gross_weight_kg']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'check_weight_kg', index)} type="text" name="check_weight_kg" value={item['check_weight_kg']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'testing_pressure_bar', index)} type="text" name="testing_pressure_bar" value={item['testing_pressure_bar']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'lr_serial_number', index)} type="text" name="lr_serial_number" value={item['lr_serial_number']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'make', index)} type="text" name="make" value={item['make']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_codes', index)} type="text" name="working_codes" value={item['working_codes']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i class="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div class="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" class="btn btn-success mr-2" disabled={props.can_edit_form}><i class="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border my-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: 0, background: 'none' }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default Co2CylinerTestingRecord;