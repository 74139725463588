import React, { useState, useEffect } from "react";
import config from '../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../helpers/functions";
import { useToasts } from 'react-toast-notifications';
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router';

const AcceptDocumentModal = (props) => {

  const [comment, setComment] = useState({ comment: '' });
  const { addToast } = useToasts();
  let job_id = props.JobId;
  let history = useHistory();
  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  let url = config.BaseURL + "/v1/block-chain-update/" + job_id;
  let { token } = useParams();
  
  const handleChange = (event) => {
      event.preventDefault();
      const { name, value } = event.target;
      const setCommentObject = {
        ...comment,
        [name]: value
      }
      setComment(setCommentObject);
  }

  const submitHandler = (event) => {
     event.preventDefault();
     var reason = { ...comment };
     axios.put(url + '?status_type=approve', reason, { headers: getAPIHeaders(), params: { status: 'Approve' } }).then((response) => {
      resetForm(event);
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.push('/feedback/'+token)
     }).catch((error) => {
      //  console.log('Hello There');
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  function resetForm(event) {
    props.AcceptModalCloseHandler(event, false)
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div class="modal" id="acceptModal"  aria-hidden="true" style={style}>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLongTitle">Are you sure accept?</h3>
              <button onClick={onModalClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-left">
              <div class="form-group">
                <label>Accepted Reason</label>
                <textarea onChange={handleChange} name="comment" value={comment['comment']} class="form-control height-100" placeholder="Accepted reason here"></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button onClick={submitHandler} type="button" class="btn btn-primary">Yes, Accept</button>
              <button onClick={onModalClose} type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default AcceptDocumentModal;