import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const FixedNSystemMastRiserCylinder = (props) => {
  const [formFields, setFormFields] = useState({
    control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '',
    technical_detail_make_type: '', technical_detail_cylinder_no: '', technical_detail_N2_cylinders_no: '', technical_detail_pilot_cylinder_capacity: '', technical_detail_N2_cylinders_capacity: '', technical_detail_distribution_line: '',
    technical_detail_last_test_date_of_cly: '', technical_detail_coverage_areas: '', inspection_type_annual: false, inspection_type_five: false, inspection_type_ten: false,
    system_set_test_mode_carried_out: false, system_set_test_mode_not_carried_out: false, system_set_test_mode_not_applicable: false, system_set_test_mode_comment: '', fault_log_inspected_carried_out: false, fault_log_inspected_not_carried_out: false, fault_log_inspected_not_applicable: false,
    fault_log_inspected_comment: '', fault_test_pushbutton_carried_out: false, fault_test_pushbutton_not_carried_out: false, fault_test_pushbutton_not_applicable: false, fault_test_pushbutton_comment: '', lamp_test_pushbutton_carried_out: false, lamp_test_pushbutton_not_carried_out: false, lamp_test_pushbutton_not_applicable: false, lamp_test_pushbutton_comment: '',
    batteries_inspected_carried_out: false, batteries_inspected_not_carried_out: false, batteries_inspected_not_applicable: false, batteries_inspected_comment: '', emergency_changeover_carried_out: false, emergency_changeover_not_carried_out: false,
    emergency_changeover_not_applicable: false, emergency_changeover_comment: '', verification_status_verified_carried_out: false, verification_status_verified_not_carried_out: false, verification_status_verified_not_applicable: false, verification_status_verified_comment: '', loop_currents_verified_carried_out: false, loop_currents_verified_not_carried_out: false,
    loop_currents_verified_not_applicable: false, loop_currents_verified_comment: '', analogue_detector_condition_carried_out: false, analogue_detector_condition_not_carried_out: false, analogue_detector_condition_not_applicable: false, analogue_detector_condition_comment: '', manual_call_carried_out: false, manual_call_not_carried_out: false,
    manual_call_not_applicable: false, manual_call_comment: '', smoke_detectors_carried_out: false, smoke_detectors_not_carried_out: false, smoke_detectors_not_applicable: false, smoke_detectors_comment: '', heat_detector_tested_carried_out: false,
    heat_detector_tested_not_carried_out: false, heat_detector_tested_not_applicable: false, heat_detector_tested_comment: '', audio_visual_warning_carried_out: false, audio_visual_warning_not_carried_out: false, audio_visual_warning_not_applicable: false,
    audio_visual_warning_comment: '', automatic_detectors_tested_carried_out: false, automatic_detectors_tested_not_carried_out: false, automatic_detectors_tested_not_applicable: false, automatic_detectors_tested_comment: '', installation_inspected_carried_out: false,
    installation_inspected_not_carried_out: false, installation_inspected_not_applicable: false, installation_inspected_comment: '', remote_panel_checked_carried_out: false, remote_panel_checked_not_carried_out: false, remote_panel_checked_not_applicable: false, remote_panel_checked_comment: '', general_alarm_tested_carried_out: false,
    general_alarm_tested_not_carried_out: false, general_alarm_tested_not_applicable: false, general_alarm_tested_comment: '', system_reconnected_carried_out: false, system_reconnected_not_carried_out: false, system_reconnected_not_applicable: false, system_reconnected_comment: '', system_now_operational_carried_out: false, system_now_operational_not_carried_out: false,
    system_now_operational_not_applicable: false, system_now_operational_comment: '', custom_list: [],
    manifold_inspected: "", manifold_tested: "", distribution_inspected: "", distribution_tested: "", total_flooding_inspected: "", total_flooding_tested: "", distributed_lines_inspected: "", distributed_lines_tested: "", distributed_lines_blow_through: "", alarm_11: "", fan_11: "", tested_11: ""
  });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.custom_list[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addMakeRow = () => {
    const _formFields = { ...formFields };
    _formFields.custom_list.push({ type_of_gas_cylinder: '', cylinder_serial_no: '', cylinder_volume: '', tare_weight_kg: '', gross_weight_kg: '', check_weight_kg: '', testing_pressure_bar: '', working_pressure_bar: '', last_pressure_test_date: '', temp: '', working_codes: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.custom_list.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }


  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Fixed Nitrogen System for Mast Riser" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="email" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label>Technical Details</label>
                  <div className="row p-0">
                    <table className="table table-responsive table-striped table-hover">
                      <tr>
                        <td>Make/Type</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_make_type" value={formFields['technical_detail_make_type']} className="form-control" placeholder="Make/Type" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>No. Of Pilot Cylinder</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_pilot_cylinder_no" value={formFields['technical_detail_pilot_cylinder_no']} className="form-control" placeholder="No. Of Pilot Cylinder" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>No Of N2 Cylinders</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_N2_cylinders_no" value={formFields['technical_detail_N2_cylinders_no']} className="form-control" placeholder="No Of N2 Cylinders" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Capacity Of Pilot Cylinder</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_pilot_cylinder_capacity" value={formFields['technical_detail_pilot_cylinder_capacity']} className="form-control" placeholder="Capacity Of Pilot Cylinder" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Capacity Of N2 Cylinders</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_N2_cylinders_capacity" value={formFields['technical_detail_N2_cylinders_capacity']} className="form-control" placeholder="Capacity Of N2 Cylinders" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>No Of Distribution Line</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_distribution_line" value={formFields['technical_detail_distribution_line']} className="form-control" placeholder="No Of Distribution Line" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Last Hp Test Date Of Cly</td>
                        <td><input onChange={handleChange} type="date" name="technical_detail_last_test_date_of_cly" value={formFields['technical_detail_last_test_date_of_cly']} className="form-control" placeholder="Last Test Date Of System" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Coverage Areas</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_coverage_areas" value={formFields['technical_detail_coverage_areas']} className="form-control" placeholder="Coverage Areas" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type Of Inspection</td>
                        <td><input type="checkbox" name="inspection_type_annual" onClick={checkboxHandleChange} checked={formFields['inspection_type_annual']} className="mr-2" disabled={props.can_edit_form} /> Annual</td>
                        <td><input type="checkbox" name="inspection_type_five" onClick={checkboxHandleChange} checked={formFields['inspection_type_five']} className="mr-2" disabled={props.can_edit_form} /> 5 Yearly</td>
                        <td><input type="checkbox" name="inspection_type_ten" onClick={checkboxHandleChange} checked={formFields['inspection_type_ten']} className="mr-2" disabled={props.can_edit_form} /> 10 Yearly</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "29%" }} />
                      <col style={{ width: "13%" }} />
                      <col style={{ width: "13%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Description Of Inspection</th>
                        <th class="text-center">Carried<br />Out</th>
                        <th class="text-center">Not<br />Carried<br />Out</th>
                        <th class="text-center">Not<br />Applicable</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Release System and Distribution Valves Secured</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_set_test_mode_carried_out']} name="system_set_test_mode_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_set_test_mode_not_carried_out']} name="system_set_test_mode_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_set_test_mode_not_applicable']} name="system_set_test_mode_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="system_set_test_mode_comment" value={formFields['system_set_test_mode_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Contents in Cylinders Checked by Pressure</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_log_inspected_carried_out']} name="fault_log_inspected_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_log_inspected_not_carried_out']} name="fault_log_inspected_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_log_inspected_not_applicable']} name="fault_log_inspected_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="fault_log_inspected_comment" value={formFields['fault_log_inspected_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Cylinders Specified According to Service Chart HPCTR (If Applicable)</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_test_pushbutton_carried_out']} name="fault_test_pushbutton_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_test_pushbutton_not_carried_out']} name="fault_test_pushbutton_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fault_test_pushbutton_not_applicable']} name="fault_test_pushbutton_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="fault_test_pushbutton_comment" value={formFields['fault_test_pushbutton_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Contents of Pilot Cylinders Checked</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['lamp_test_pushbutton_carried_out']} name="lamp_test_pushbutton_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['lamp_test_pushbutton_not_carried_out']} name="lamp_test_pushbutton_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['lamp_test_pushbutton_not_applicable']} name="lamp_test_pushbutton_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="lamp_test_pushbutton_comment" value={formFields['lamp_test_pushbutton_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>All Cylinder Valves Visually Inspected</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['batteries_inspected_carried_out']} name="batteries_inspected_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['batteries_inspected_not_carried_out']} name="batteries_inspected_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['batteries_inspected_not_applicable']} name="batteries_inspected_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="batteries_inspected_comment" value={formFields['batteries_inspected_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>All Cylinder Frames and Connections Checked for Tightness</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['emergency_changeover_carried_out']} name="emergency_changeover_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['emergency_changeover_not_carried_out']} name="emergency_changeover_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['emergency_changeover_not_applicable']} name="emergency_changeover_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="emergency_changeover_comment" value={formFields['emergency_changeover_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Manifold Inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['manifold_inspected']} name="manifold_inspected" disabled={props.can_edit_form} /> Tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['manifold_tested']} name="manifold_tested" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['verification_status_verified_carried_out']} name="verification_status_verified_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['verification_status_verified_not_carried_out']} name="verification_status_verified_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['verification_status_verified_not_applicable']} name="verification_status_verified_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="verification_status_verified_comment" value={formFields['verification_status_verified_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Distribution Valves Inspected<input type="checkbox" onClick={checkboxHandleChange} checked={formFields['distribution_inspected']} name="distribution_inspected" disabled={props.can_edit_form} /> Tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['distribution_tested']} name="distribution_tested" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['loop_currents_verified_carried_out']} name="loop_currents_verified_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['loop_currents_verified_not_carried_out']} name="loop_currents_verified_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['loop_currents_verified_not_applicable']} name="loop_currents_verified_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="loop_currents_verified_comment" value={formFields['loop_currents_verified_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Total Flooding Release System <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['total_flooding_inspected']} name="total_flooding_inspected" disabled={props.can_edit_form} /> Tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['total_flooding_tested']} name="total_flooding_tested" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['analogue_detector_condition_carried_out']} name="analogue_detector_condition_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['analogue_detector_condition_not_carried_out']} name="analogue_detector_condition_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['analogue_detector_condition_not_applicable']} name="analogue_detector_condition_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="analogue_detector_condition_comment" value={formFields['analogue_detector_condition_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>10</td>
                        <td>Release Station Inspected</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['manual_call_carried_out']} name="manual_call_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['manual_call_not_carried_out']} name="manual_call_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['manual_call_not_applicable']} name="manual_call_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="manual_call_comment" value={formFields['manual_call_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>11</td>
                        <td>Alarm <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['alarm_11']} name="alarm_11" disabled={props.can_edit_form} /> Fan Stop <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['fan_11']} name="fan_11" disabled={props.can_edit_form} /> Tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['tested_11']} name="tested_11" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['smoke_detectors_carried_out']} name="smoke_detectors_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['smoke_detectors_not_carried_out']} name="smoke_detectors_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['smoke_detectors_not_applicable']} name="smoke_detectors_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="smoke_detectors_comment" value={formFields['smoke_detectors_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>12</td>
                        <td>Distribution Lines and Nozzle Inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['distributed_lines_inspected']} name="distributed_lines_inspected" disabled={props.can_edit_form} />  Tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['distributed_lines_tested']} name="distributed_lines_tested" disabled={props.can_edit_form} />  Blow Trough <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['distributed_lines_blow_through']} name="distributed_lines_blow_through" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['heat_detector_tested_carried_out']} name="heat_detector_tested_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['heat_detector_tested_not_carried_out']} name="heat_detector_tested_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['heat_detector_tested_not_applicable']} name="heat_detector_tested_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="heat_detector_tested_comment" value={formFields['heat_detector_tested_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>13</td>
                        <td>All Door, Hinges, Locks, Inspected</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['audio_visual_warning_carried_out']} name="audio_visual_warning_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['audio_visual_warning_not_carried_out']} name="audio_visual_warning_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['audio_visual_warning_not_applicable']} name="audio_visual_warning_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="audio_visual_warning_comment" value={formFields['audio_visual_warning_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>14</td>
                        <td>All Instruction Plates on Installation Inspected</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['automatic_detectors_tested_carried_out']} name="automatic_detectors_tested_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['automatic_detectors_tested_not_carried_out']} name="automatic_detectors_tested_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['automatic_detectors_tested_not_applicable']} name="automatic_detectors_tested_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="automatic_detectors_tested_comment" value={formFields['automatic_detectors_tested_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>15</td>
                        <td>System Reconnected, Left in Operation Order, Inspected Date Labels Attached</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['installation_inspected_carried_out']} name="installation_inspected_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['installation_inspected_not_carried_out']} name="installation_inspected_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['installation_inspected_not_applicable']} name="installation_inspected_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="installation_inspected_comment" value={formFields['installation_inspected_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>16</td>
                        <td>Master, Chief Engineer Informed System Now Operation</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['remote_panel_checked_carried_out']} name="remote_panel_checked_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['remote_panel_checked_not_carried_out']} name="remote_panel_checked_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['remote_panel_checked_not_applicable']} name="remote_panel_checked_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="remote_panel_checked_comment" value={formFields['remote_panel_checked_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      {/* <tr>
                        <td>17</td>
                        <td>General Alarm tested if integral to system</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['general_alarm_tested_carried_out']} name="general_alarm_tested_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['general_alarm_tested_not_carried_out']} name="general_alarm_tested_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['general_alarm_tested_not_applicable']} name="general_alarm_tested_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="general_alarm_tested_comment" value={formFields['general_alarm_tested_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>18</td>
                        <td>Release system reconnected, sealed if fitted</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_reconnected_carried_out']} name="system_reconnected_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_reconnected_not_carried_out']} name="system_reconnected_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_reconnected_not_applicable']} name="system_reconnected_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="system_reconnected_comment" value={formFields['system_reconnected_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>19</td>
                        <td>Master, Chief Engineer informed system now operational</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_now_operational_carried_out']} name="system_now_operational_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_now_operational_not_carried_out']} name="system_now_operational_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['system_now_operational_not_applicable']} name="system_now_operational_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="system_now_operational_comment" value={formFields['system_now_operational_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Working Codes For Cylinder Test Records</label>
                    <div class="row p-0">
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>External inspection </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Contents checked by weight</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Contents checked by pressure </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Contents checked by level </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Recharging</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tr>
                            <td>6</td>
                            <td>Hydrostatic test</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Valve renewal</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Valve repair</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>External maintenance</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>New cylinder</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type of Gas Cylinder</th>
                        <th>Cylinder Serial Number</th>
                        <th>Cylinder Volume </th>
                        <th>Tare Weight (Kg)</th>
                        <th>Gross Weight (Kg)</th>
                        <th>Check Weight(Kg)</th>
                        <th>Testing Pressure (Bar)</th>
                        <th>Working Pressure (Bar)</th>
                        <th>Last Pressure test Date </th>
                        <th>Temp °C / °F</th>
                        <th>Working Codes</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.custom_list.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              <td><input onChange={(event) => handleChangeRows(event, 'type_of_gas_cylinder', index)} type="text" name="type_of_gas_cylinder" value={item['type_of_gas_cylinder']} class="form-control" placeholder="CO2" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_serial_no', index)} type="text" name="cylinder_serial_no" value={item['cylinder_serial_no']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_volume', index)} type="text" name="cylinder_volume" value={item['cylinder_volume']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'tare_weight_kg', index)} type="text" name="tare_weight_kg" value={item['tare_weight_kg']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'gross_weight_kg', index)} type="text" name="gross_weight_kg" value={item['gross_weight_kg']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'check_weight_kg', index)} type="text" name="check_weight_kg" value={item['check_weight_kg']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'testing_pressure_bar', index)} type="text" name="testing_pressure_bar" value={item['testing_pressure_bar']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_pressure_bar', index)} type="text" name="working_pressure_bar" value={item['working_pressure_bar']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'last_pressure_test_date', index)} type="date" name="last_pressure_test_date" value={item['last_pressure_test_date']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'temp', index)} type="text" name="temp" value={item['temp']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_codes', index)} type="text" name="working_codes" value={item['working_codes']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button type="button" onClick={(event) => handleRemoveFields(event, index)} class="removeBtn"><i class="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div class="text-left disable-button-custom">
                    <button onClick={() => addMakeRow()} type="button" class="btn btn-success mr-2" disabled={props.can_edit_form}><i class="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default FixedNSystemMastRiserCylinder;