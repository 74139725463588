import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const UtiGauage = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', type: '', make: '', model: '', serial_no: '', last_cal_date: '', next_due_date: '', location: '', description_of_inspection_tests: '', reference_master_guage_make: '', reference_master_guage_model: '', reference_master_guage_serial: '', reference_master_guage_range: '', test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '', calibration_results: [] });
  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }



  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.calibration_results[index][name] = event.target.value;
    console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.calibration_results.push({ ullage_ref_tape: '', ullage_sample_tape: '', ullage_deviation: '', temprature_test_temp: '', temprature_sensor_temp: '', temprature_deviation: '', interface: '', response_time: '', test_medium: '', audiable_alarm: '', result: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.calibration_results.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="UTI Guage" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" onChange={handleChange} id="start" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mt-1">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>Equipment</td>
                        <td><input type="text" onChange={handleChange} name="equipment" value={formFields['equipment']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial Number</td>
                        <td><input type="text" onChange={handleChange} name="serial_no" value={formFields['serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td><input type="text" onChange={handleChange} name="type" value={formFields['type']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Last Cal Date</td>
                        <td><input type="date" onChange={handleChange} name="last_cal_date" value={formFields['last_cal_date']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td><input type="text" onChange={handleChange} name="make" value={formFields['make']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Next Due Date</td>
                        <td><input type="date" onChange={handleChange} name="next_due_date" value={formFields['next_due_date']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td><input type="text" onChange={handleChange} name="model" value={formFields['model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Location</td>
                        <td><input type="text" onChange={handleChange} name="location" value={formFields['location']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input type="text" onChange={handleChange} name="description_of_inspection_tests" value={formFields['description_of_inspection_tests']} placeholder="This instrument was calibrated using the standard inspection procedures and the quality is fully conformed with the specification’s standards." className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Reference Master Guage</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th className="text-center">MAKE</th>
                        <th className="text-center">MODEL</th>
                        <th className="text-center">SERIAL</th>
                        <th className="text-center">RANGE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="text" onChange={handleChange} name="reference_master_guage_make" value={formFields['reference_master_guage_make']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input type="text" onChange={handleChange} name="reference_master_guage_model" value={formFields['reference_master_guage_model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input type="text" onChange={handleChange} name="reference_master_guage_serial" value={formFields['reference_master_guage_serial']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input type="text" onChange={handleChange} name="reference_master_guage_range" value={formFields['reference_master_guage_range']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Calibration Results</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th colspan="3" className="text-center">ULLAGE (Mtrs)</th>
                        <th colspan="3" className="text-center">TEMPERATURE (Celsius)</th>
                        <th colspan="5" className="text-center">INTERFACE</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="text-center">Ref Tape</td>
                        <td className="text-center">Sampler Tape</td>
                        <td className="text-center">Deviation</td>
                        <td className="text-center">Test Temp</td>
                        <td className="text-center">Sensor Temp</td>
                        <td className="text-center">Deviation</td>
                        <td className="text-center">Interface</td>
                        <td className="text-center">Response time</td>
                        <td className="text-center">Test Medium</td>
                        <td className="text-center">Audible Alarm</td>
                        <td className="text-center">Result</td>
                        <td>&nbsp;</td>
                      </tr>
                      {
                        formFields.calibration_results.map((item, index) => {
                          return (
                            <tr>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'ullage_ref_tape', index)} value={item['ullage_ref_tape']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'ullage_sample_tape', index)} value={item['ullage_sample_tape']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'ullage_deviation', index)} value={item['ullage_deviation']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'temprature_test_temp', index)} value={item['temprature_test_temp']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'temprature_sensor_temp', index)} value={item['temprature_sensor_temp']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'temprature_deviation', index)} value={item['temprature_deviation']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'interface', index)} value={item['interface']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'response_time', index)} value={item['response_time']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'test_medium', index)} value={item['test_medium']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'audiable_alarm', index)} value={item['audiable_alarm']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'result', index)} value={item['result']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="vertical-mid"><button type="button" onClick={(event) => handleRemoveFields(event, index)} className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }


                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3 mt-3">
                  <label>Test Result</label>
                  <textarea name="test_result" onChange={handleChange} value={formFields['test_result']} placeholder="We hereby certify that we have tested the above instrument in accordance tostandard inspection procedures and that the quality is in full conformance with the specifications and the standards." className="form-control height-100" autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea name="tracebility" onChange={handleChange} value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea onChange={handleChange} name="remarks" value={formFields['remarks']} placeholder="Remarks" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div>
                <b>Master/Chief Officer</b><br />Endorsement
              </div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
export default UtiGauage;