import React from 'react';
import Pace from 'react-pace-progress';

const LoadingBar = () => {
  return (
    <div className="pace-container">
      <Pace color="#f00" height={2} />
    </div>
  );
}

export default LoadingBar;