import React from "react";

const RejectedView = () => {
    return ( 
        <React.Fragment>
            <section className="dashboard-counts no-padding-bottom">
      <div className="container-fluid">
        <form name="" action="">
          <div className="bg-white has-shadow">
            <div className="row px-0 pb-0">
            <div className="col-lg-12 col-sm-12 mb-3">
                <div className="md-font">List Of Service Certificates:</div>
            </div>
                <div className="col-lg-2 col-sm-2">
                <div className="form-group">
                  <label>Year</label>
                  <div className="styled-select">
                    <select id="" name="">
                      <option value="" selected="selected"> Year </option>
                      <option value="1"> Year</option>
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div>
              </div>
                 <div className="col-lg-2 col-sm-2">
                <div className="form-group">
                  <label>Month</label>
                  <div className="styled-select">
                    <select id="" name="">
                      <option value="" selected="selected"> Month </option>
                      <option value="1"> Month</option>
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div>
              </div>
                <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>Vessel Name</label>
                  <input type="text" name="" value="" className="form-control" placeholder="Vessel Name" />
                </div>
              </div>
                <div className="col-lg-2 col-sm-2">
                <div className="form-group">
                  <label>IMO Number</label>
                  <input type="text" name="" value="" className="form-control" placeholder="IMO Number" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>Certificate No.</label>
                  <input type="text" name="" value="" className="form-control" placeholder="Certificate No." />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>Place of Service</label>
                  <input type="text" name="" value="" className="form-control" placeholder="Place of Service" />
                </div>
              </div>
                 <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>Flag</label>
                  <input type="text" name="" value="" className="form-control" placeholder="Flag" />
                </div>
              </div>
                 <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>classification Society</label>
                  <input type="text" name="" value="" className="form-control" placeholder="classification Society" />
                </div>
              </div>
              <div className="col-lg-3 col-sm-3">
                <div className="form-group">
                  <label>No. Of Pages</label>
                  <input type="text" name="" value="" className="form-control" placeholder="No. Of Pages" />
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 mb-4">    
                  We hereby certify that the Fire Fighting Systems & Equipments as specified below and in the Service Chart/s have been inspected and serviced.
             </div>
              <div className="col-lg-12 col-sm-12 mb-3">
                <div className="md-font">Scope Of Service:</div>
              </div>
              <div className="col-lg-12 col-sm-12 checkBoxWid">
                <div className="form-group">
                    <span><input type="checkbox" name="" value="" className="mr-2" />Airline Trolley System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Fireman Suits</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Self-contained Breathing Apparatus</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Chemical Suits</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Emergency Escape Breathing Device</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Liferafts</span>   
                    <span><input type="checkbox" name="" value="" className="mr-2" />SCBA Compressor</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Other Systems & Equipments (OSE)</span>                         <span><input type="checkbox" name="" value="" className="mr-2" />Medical Oxygen</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Medical Oxygen Resuscitators</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Foam Lifejackets</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Immsersion Suits</span>   
                </div>
              </div>
              <div className="col-lg-12 col-sm-12 mb-3">
                <div className="md-font">Rejected Reason:</div>
                <div>Wrong Data</div>  
              </div> 
             
              <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-right">
                <a href="mj-rejected.php" className="continue-btn"><i className="fa fa-arrow-left"></i>&nbsp; Back </a>&nbsp;
              </div>
            </div>
            </div>
            </form>
            </div>
           </section>
        </React.Fragment>
     );
}
 
export default RejectedView;