import React, { useEffect, useState } from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import { getAPIHeaders, getFieldNameFromName, getFieldValueFromJSON, getJobsBlankObject } from '../../helpers/functions';
import config from '../../config.json';
import { useParams } from 'react-router';
import LoadingBar from '../Shared/LoadingBar';
import InputComponent from '../Jobs/Input';
import { Link } from 'react-router-dom';
import SendMailModal from '../Jobs/SendMailModal';
import InfoModal from '../Shared/InfoModal';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import cross from '../../assets/img/icons8-cancel.svg';
import commonDoc from '../../assets/img/common-document.png';
import path from 'path';
import { useToasts } from 'react-toast-notifications';
import moment from "moment";

const Detail = (props) => {
  const { addToast } = useToasts();
  const [jobDetail, setJobDetail] = useState({ forms: [] });
  const [jobTemplateFields, setJobTemplateFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [jobJSON, setJobJSON] = useState("{}");
  const [jobfields, setJobFields] = useState(getJobsBlankObject());
  const { job_id } = useParams();
  const { form_id } = useParams();
  const [flags, setFlags] = useState([]);
  const [classField, setClassfield] = useState([]);
  const [users, setUsers] = useState([]);
  const [modalObject, setModalObject] = useState({ modalVisible: false });
  const [infoModal, setInfoModal] = useState({ infoModalVisible: false });
  const [rejectionData, setRejectionData] = useState({ comment: null, user: null });
  const [thumbnails, setThumbnails] = useState([]);

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/details";
  let fieldURL = config.BaseURL + "/v1/job-fields?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let userURL = config.BaseURL + "/v1/company-users";
  let PDFURL = config.PdfURL + "/write-pdf/" + job_id;
  useEffect(() => {
    loadJobDetail(url);
    loadClasses(classesURL);
    loadFlags(flagURL);
    loadUsers(userURL);
  }, [])

  const setTemplateFieldValue = (key, value) => {
    const jobFieldsObject = { ...jobfields, [key]: value };
    setJobFields(jobFieldsObject);
  }

  const loadJobDetail = (url) => {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders() }).then(function (response) {
      // response.data.service_date = moment(response.data.service_date).format('YYYY-DD-MM')
      setJobDetail({ ...jobDetail, ...response.data });
      setThumbnails(response.data.job_media);
      console.log('data', response.data)
      setJobJSON(response.data.job_json);
      loadJobTemplateFields(fieldURL);
      setIsLoading(false);
    })
  }

  const loadJobTemplateFields = (fieldURL) => {
    axios.get(fieldURL, { headers: getAPIHeaders() }).then(function (response) {
      setJobTemplateFields(response.data);
    })
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      setClassfield(response.data);
    });
  }

  function loadFlags() {
    axios.get(flagURL, { headers: getAPIHeaders() }).then(function (response) {
      setFlags(response.data);
    });
  }

  const loadUsers = (userURL) => {
    axios.get(userURL, { headers: getAPIHeaders() }).then(function (response) {
      // console.log(response);
      setUsers(response.data.data);
    })
  }

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible,
    });
  }

  const infoModalHandler = (event, infoModalVisible, comment = null, user = null) => {
    event.preventDefault();
    setRejectionData({ comment: comment, user: user });
    setInfoModal({
      infoModalVisible: infoModalVisible,
    });
  }

  const getUploadParams = ({ meta }) => {
    return {
      url: config.BaseURL + '/v1/jobs/' + job_id + '/file-upload',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
      }
    }
  }
  const handleChangeStatus = ({ meta, file, xhr }, status) => {
    if (status === 'done') {
      const obj = JSON.parse(xhr.response);
      const _thumbnails = [...thumbnails];
      _thumbnails.push(obj.path);
      setThumbnails(_thumbnails);
    }
  }

  const removeThumbnail = (id, event) => {
    event.preventDefault();
    axios.delete(config.BaseURL + '/v1/jobs-delete-file/' + id, { headers: getAPIHeaders() }).then(function (response) {
      loadJobDetail(url)
      addToast("File Deleted successfully!", { appearance: 'error', autoDismiss: true });
      // getThumbnail();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      {ReactDOM.createPortal(<SendMailModal isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} loadJobDetail={loadJobDetail} url={url} />, document.getElementById('modal-root'))}
      {ReactDOM.createPortal(<InfoModal isModalVisible={infoModal.infoModalVisible} infoModalHandler={infoModalHandler} rejectionData={rejectionData} />, document.getElementById('modal-root'))}
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>I JO/Ref No</label>
                    <input type="text" name="" value={jobDetail['ref_no']} className="form-control" placeholder="IJO/Ref No" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Certificate Number / Job ID</label>
                    <input type="text" name="" value={jobDetail['certificate_no']} className="form-control" placeholder="Certificate Number / Job Id" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" value={jobDetail['customer_name']} className="form-control" placeholder="Customer Name" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" value={jobDetail['customer_pic']} className="form-control" placeholder="Customer Pic" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Email ID</label>
                    <input type="email" value={jobDetail['customer_email']} className="form-control" placeholder="Customer Email Id" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port Of Service</label>
                    <input type="text" value={jobDetail['service_port']} className="form-control" placeholder="Port of service" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date Of Service</label>
                    <input type="date" name="" value={moment(jobDetail['service_date'], 'DD/MM/YYYY').format('YYYY-MM-DD')} className="form-control" placeholder="Date of service" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" name="" value={jobDetail['vessel_name']} className="form-control" placeholder="Vessel Name" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="text" name="" value={jobDetail['imo_number']} className="form-control" placeholder="IMO number" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className={jobDetail['disable_fields'] === true ? 'select-disable styled-select' : 'styled-select'}>
                      <select name="class_id" value={jobDetail['class_id']} disabled={jobDetail['disable_fields']}>
                        <option value=""> Select Class </option>
                        {
                          classField.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className={jobDetail['disable_fields'] === true ? 'select-disable styled-select' : 'styled-select'}>
                      <select name="flag_id" value={jobDetail['flag_id']} disabled={jobDetail['disable_fields']} >
                        <option value="">Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Email ID</label>
                    <input type="email" name="" value={jobDetail['vessel_email']} className="form-control" placeholder="Vessel Email Id" autoComplete="off" disabled={jobDetail['disable_fields']} />
                  </div>
                </div>
                {
                  jobTemplateFields.map((field, index) => {
                    return <InputComponent readOnly={true} key={index} jobTemplateValue={getFieldValueFromJSON(jobJSON, getFieldNameFromName(field))} field={field} changeHandler={setTemplateFieldValue} />
                  })
                }
                <div className="col-lg-12 col-sm-12"><hr /></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Assigned To</label>
                    <div className={jobDetail['disable_fields'] === true ? 'select-disable styled-select' : 'styled-select'}>
                      <select id="" name="assigned_to" value={jobDetail['assigned_to']} disabled={true}>
                        <option value=""> Select </option>
                        {
                          users.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Reviewed By</label>
                    <div className={jobDetail['disable_fields'] === true ? 'select-disable styled-select' : 'styled-select'}>
                      <select id="" name="reviewed_by" value={jobDetail['reviewed_by']} disabled={true}>
                        <option value="" > Select </option>
                        {
                          users.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className={jobDetail['disable_fields'] === true ? 'select-disable styled-select' : 'styled-select'}>
                      <select id="" name="approved_by" value={jobDetail['approved_by']} disabled={true}>
                        <option value=""> Select </option>
                        {
                          users.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-6 col-sm-6 text-right">
                  <a onClick={(event) => modalHandler(event, true)} href="manage-jobs.php" className={jobDetail['job_status'] == 'Approved' ? "continue-btn" : "continue-btn send-mail-disable"} data-toggle="modal" data-target="#sendmail"><i className="fa fa-envelope"></i>&nbsp; Send Mail </a>
                  {
                    jobDetail['job_status'] == 'Approved' && jobDetail['is_email_send'] == 'Y' ?
                      (
                        <p className="text-success mt-1">Email sent to customer</p>
                      )
                      :
                      [
                        (
                          jobDetail['job_status'] == 'Approved' ? (<p className="text-danger mt-1">Email not sent to customer yet</p>) : ''
                        )
                      ]
                  }
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Date Submited</th>
                        <th>Assigned to</th>
                        <th>Form Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobDetail.forms.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{moment(item.date_submitted, 'DD-MM-YYYY').format('DD/MM/YYYY')}</td>
                              <td>{jobDetail.assignee_name}</td>
                              <td>{item.form_status} {item.form_status == 'Rejected' ? <i onClick={(event) => { infoModalHandler(event, true, item.comment, item.rejected_by_user) }} className="fa fa-info-circle ml-1"></i> : ''}</td>
                              <td>
                                <Link to={`/manage-jobs/${jobDetail.id}/detail/forms/${item.form_id}`} className="view-btn mr-1" >View</Link>
                                {item.id === 66 || item.name === 'Catalog' ? (<a href={config.PdfURL + '/pdf/catalog.pdf'} target="_blank" className="download-btn mr-1"><i className="fa fa-download"></i></a>) : (<a href={PDFURL + `/${item.form_id}`} target="_blank" className="download-btn mr-1"><i className="fa fa-download"></i></a>)}

                                {/* <a href="#" className="share-alt"><i className="fa fa-share-alt"></i></a> */}
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                {
                  jobDetail.user_role == 'assignee' && (jobDetail.job_status == 'Pending' || jobDetail.job_status == 'Rejected') ?
                    <div className="col-md-12 custom-dropzone w-25" style={{ justifyContent: 'center' }}>
                      <Dropzone getUploadParams={getUploadParams} onChangeStatus={handleChangeStatus} accept="image/*,.doc/*,.docx,.pdf" PreviewComponent={null} />
                    </div>
                    : ''}
                {
                  thumbnails.map(item => {
                    let crossIcon = jobDetail.user_role == 'assignee' && (jobDetail.job_status == 'Pending' || jobDetail.job_status == 'Rejected') && item.deletable ?
                      (<img className="cross" src={cross} onClick={(event) => removeThumbnail(item.id, event)} />) : "";
                    console.log("item", item)
                    return (
                      <div className="thumbnail-item mt-4 ml-3">
                        {path.extname(item.file) === '.pdf' || path.extname(item.file) === '.doc' || path.extname(item.file) === '.docx' ?
                          <>
                            <a href={item.file} target="_blank" ><img className="thumbnail-img" src={commonDoc} /></a>
                            <p className="mb-0">{item.original_file_name}</p>
                            {crossIcon}

                          </>
                          :
                          <>
                            <a href={item.file} target="_blank" ><img className="thumbnail-img" src={item.file} /></a>
                            <p className="mb-0">{item.original_file_name}</p>
                            {crossIcon}
                          </>
                        }
                      </div>
                    )
                  })
                }
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-right">
                  <Link to='/manage-jobs' className="continue-btn"><i className="fa fa-arrow-left"></i>&nbsp; Back </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Detail;