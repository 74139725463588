import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import InnerHeader from '../Shared/InnerHeader';
import axios from 'axios';
import config from '../../config.json';
import LoadingBar from '../Shared/LoadingBar';
import Pagination from '../Shared/Pagination';
import { useToasts } from 'react-toast-notifications';

const List = (props) => {
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [companiesLinks, setCompanyLinks] = useState([]);
  const searchTermRef = useRef(null);
  const [sortByCols, setSortByCols] = useState([ 'none', 'asc' ])
  const [searchTerm, setSearchTerm] = useState('');
  let companiesURL = config.BaseURL + "/v1/companies";
  const { addToast } = useToasts();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchCompanies();
  }

  const searchCompanies = () => {
    setSearchTerm(searchTermRef.current.value);
  }

  useEffect(() => {
    fetchCompanies(companiesURL);
  }, [searchTerm]);

  function fetchCompanies(url) {
    setIsLoading(true);
    const sortParams = sortByCols.join(",");
    axios.get(url + "?sort_by=" + sortParams, { headers: headers, params: { squery: searchTerm } }).then(function (response) {
      let companiesArray = [];
      response.data.data.forEach(element => {
        companiesArray.push(element);
      });
      setCompanies(companiesArray);
      setCompanyLinks(response.data.links);
      setIsLoading(false);
    });
  }

  const removeData = (id, event) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(companiesURL + "/" + id, { headers: headers }).then(function (response) {
        addToast("Company Deleted Successfully!", { appearance: 'success', autoDismiss: true });
        fetchCompanies(companiesURL);
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    }
  }

  const doSort = (columnName) => {
    const newSortObject = [columnName, sortByCols[1] === 'asc' ? 'desc' : 'asc']
    setSortByCols(newSortObject);
  }

  useEffect(() => {
    fetchCompanies(companiesURL);
  }, [sortByCols])

  const style = {
    background: "#2a78e4",
    color: "#fff",
  }
  const style1 = {
    background: "#cf2a2d",
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      <InnerHeader title="Manage Companies" buttonTitle="Add" icon="fa fa-plus" path="/manage-companies/add" hasShow="true" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchTermRef} type="text" placeholder="Search here"  className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchCompanies} type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover mb-0 table-sorting">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th onClick={(e) => doSort('companies.name')}>Company Name <span className="pull-right">{ sortByCols[0] === 'companies.name' && sortByCols[1] == 'asc' && <i className='fa fa-sort-up'></i> }{ sortByCols[0] === 'companies.name' && sortByCols[1] == 'desc' && <i className='fa fa-sort-down'></i> }{ sortByCols[0] !== 'companies.name' && (<><i className='fa fa-sort-up'></i> <i className='fa fa-sort-down'></i></>) }</span></th>
                        <th onClick={(e) => doSort('companies.contact_person')}>Contact Person <span className="pull-right">{ sortByCols[0] === 'companies.contact_person' && sortByCols[1] == 'asc' && <i className='fa fa-sort-up'></i> }{ sortByCols[0] === 'companies.contact_person' && sortByCols[1] == 'desc' && <i className='fa fa-sort-down'></i> }{ sortByCols[0] !== 'companies.contact_person' && (<><i className='fa fa-sort-up'></i> <i className='fa fa-sort-down'></i></>) }</span></th>
                        <th onClick={(e) => doSort('users.email')}>Email <span className="pull-right">{ sortByCols[0] === 'users.email' && sortByCols[1] == 'asc' && <i className='fa fa-sort-up'></i> }{ sortByCols[0] === 'users.email' && sortByCols[1] == 'desc' && <i className='fa fa-sort-down'></i> }{ sortByCols[0] !== 'users.email' && (<><i className='fa fa-sort-up'></i> <i className='fa fa-sort-down'></i></>) }</span></th>
                        <th onClick={(e) => doSort('users.contact_no')}>Contact Number <span className="pull-right">{ sortByCols[0] === 'users.contact_no' && sortByCols[1] == 'asc' && <i className='fa fa-sort-up'></i> }{ sortByCols[0] === 'users.contact_no' && sortByCols[1] == 'desc' && <i className='fa fa-sort-down'></i> }{ sortByCols[0] !== 'users.contact_no' && (<><i className='fa fa-sort-up'></i> <i className='fa fa-sort-down'></i></>) }</span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        companies.map((companyObject, index) => {
                          return <tr key={companyObject.id}>
                            <td>{index + 1}</td>
                            <td>{companyObject.name}</td>
                            <td>{companyObject.contact_person}</td>
                            <td>{companyObject.email}</td>
                            <td>{companyObject.contact_no}</td>
                            <td>
                              <Link to={`/manage-companies/${companyObject.id}/edit`}  className="download-btn" style={style}><i className="fa fa-edit"></i></Link>&nbsp;
                              <Link to='/' onClick={(event) => removeData(companyObject.id, event)} className="share-alt" style={style1}><i className="fa fa-trash"></i></Link>
                            </td>
                          </tr>
                        })
                      }
                      { companies.length === 0 ? <tr><td colSpan='6' className='text-center'>No Record Found</td></tr> : null }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
              <Pagination links={companiesLinks} onPageClick={fetchCompanies}/>
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default List;