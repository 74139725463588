import React, { useEffect, useState } from 'react';
import Avatar from '../../assets/img/avatar-1.jpg';
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders, getAPIHeadersForFile, getProfilePicture } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import ChangePassword from './ChangePassword';
import ReactDOM from 'react-dom';

const Profile = () => {

  const [formFields, setFormFields] = useState({ name: '', email: '', contact_no: '', password: '', password_confirmation: '' });
  const [profileImage, setProfileImage] = useState('');
  const { addToast } = useToasts();

  function loadProfile() {
    axios.get(config.BaseURL + "/v1/current-user", { headers: getAPIHeaders() }).then(function (response) {
      setFormFields({
        ...formFields,
        name: response.data.name,
        email: response.data.email,
        contact_no: response.data.contact_no,
      });
      if (response.data.profile_image !== '') {
        document.getElementById("preview").src = response.data.profile_image;
      }
    }).catch(function (error) { });
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const fileChosenHandler = (event) => {
    setProfileImage(event.target.files[0]);
    var reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById("preview").src = e.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    let formData = new FormData();
    if (profileImage !== '') formData.append('profile_image', profileImage, profileImage.name);
    formData.append('name', formFields['name']);
    formData.append('email', formFields['email']);
    formData.append('contact_no', formFields['contact_no']);
    formData.append('password', formFields['password']);
    formData.append('password_confirmation', formFields['password_confirmation']);
    axios.post(config.BaseURL + "/v1/current-user", formData, { headers: getAPIHeadersForFile() }).then(response => {
      if (response.data.profile_image !== '') {
        localStorage.setItem('profile_image', response.data.profile_image);
      }
      addToast("Profile Updated Successfully!", { appearance: 'success', autoDismiss: true });
    }).catch(err => console.log(err));
  }

  const openFileManager = (event) => {
    document.getElementById('profile_image').click();
  }

  useEffect(() => {
    loadProfile();
  }, []);

  const [modalObject, setModalObject] = useState({ modalVisible: false });

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible
    });
  }



  return (
    <React.Fragment>

      {ReactDOM.createPortal(<ChangePassword isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}

      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-6 col-sm-6 floatNone">
              <form name="" action="" method="">
                <div className="form-group text-center">
                  <img src={getProfilePicture() === '' ? Avatar : getProfilePicture()} id="preview" className="imgRadius" alt="" />
                  <div id="msg"></div>
                  <div id="image-form">
                    <input onChange={fileChosenHandler} type="file" id="profile_image" name="profile_image" className="file display-n" accept="image/*" />
                    <div className="my-3">
                      <input type="text" className="form-control display-n" disabled placeholder="Upload File" id="file" />
                      <div onClick={openFileManager} className="browse">Change Picture</div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Name</label>
                  <input onChange={handleChange} value={formFields['name']} type="text" name="name" className="form-control" placeholder="Company Name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input onChange={handleChange} value={formFields['email']} type="text" name="email" className="form-control" placeholder="Eamil" />
                </div>
                <div className="form-group">
                  <label>Contact No.</label>
                  <input onChange={handleChange} value={formFields['contact_no']} type="text" name="contact_no" className="form-control" placeholder="Contact No." />
                </div>
                <div className="my-3">
                  <a href="#ex" onClick={(event) => modalHandler(event, true)} to='/' data-toggle="modal" className="browse">Change Password</a>
                </div>
                <div className="text-center">
                  <button onClick={submitHandler} type="submit" name="submit" value="Submit" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Profile;