import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from 'axios';
import { getComUserBlankObject, getAPIHeaders } from '../../helpers/functions';
import InnerHeader from "../Shared/InnerHeader";
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';

const Add = (props) => {

  const [userFields, setUserFields] = useState(getComUserBlankObject());
  const [roles, setRoles] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    axios.get(config.BaseURL + '/v1/roles', { headers: getAPIHeaders() }).then(function (response) {
      setRoles(response.data);
    });
  },[]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const userFieldsObject = {
      ...userFields,
      [name]: value
    }
    setUserFields(userFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var userData = { ...userFields };
      axios.post(config.BaseURL + "/v1/company/user", userData, { headers: getAPIHeaders() }).then((response) => {
       addToast("User Added Successfully!", { appearance: 'success', autoDismiss: true });
       props.history.push("/manage-users");
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      }); 
 }

  return (
    <React.Fragment>
      <InnerHeader title="ADD USER" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-6 col-sm-6 floatNone">
              <form name="" action="" method="">
                <div className="form-group">
                  <label>Name</label>
                  <input onChange={handleChange} type="text" name="name" value={userFields['name']} className="form-control" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input onChange={handleChange} type="text" name="email" value={userFields['email']} className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label>Contact No.</label>
                  <input onChange={handleChange} type="text" name="contact_no" value={userFields['contact_no']} className="form-control" placeholder="Contact No." />
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input onChange={handleChange} type="password" name="password" value={userFields['password']} className="form-control" placeholder="Password" />
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input onChange={handleChange} type="password" name="password_confirmation" value={userFields['password_confirmation']} className="form-control" placeholder="Confirm Password" />
                </div>
                 <div className="form-group">
                  <label>Role</label>
                  <div className="styled-select">
                  <select onChange={handleChange} name="user_role" value={userFields['user_role']}>
                      <option value=""> Select Role </option>
                      <option value="admin"> Admin </option>
                      <option value="user"> User </option>
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div>
                
                {/* <div className="form-group">
                  <label>Role</label>
                  <div className="styled-select">
                  <select onChange={handleChange} name="role_id" value={userFields['role_id']}>
                      <option value=""> Select Role </option>
                      {
                       roles.map((role, index) => {
                         return(
                          <option key={index} value={role.id}>{role.name}</option>
                         )
                       })
                      }
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div> */}
                <div className="form-group text-center">
                  <button onClick={submitHandler} type="button" name="submit" value="Submit" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save </button>&nbsp;
                  <Link  to="/manage-users/"type="button" className="btn btn-secondary color-868e96" data-dismiss="modal"><i className="fa fa-close">&nbsp; </i>Cancel</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Add;