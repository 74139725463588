import React, { useEffect, useState, Component } from 'react';
import Select from 'react-select';

const Input = (props) => {

  const field_options = props.field.field_options;
  const field_option = field_options != null ? field_options.split(',') : [];
  const [fieldOptions, setFieldOptions] = useState(field_option);
  const input_name = props.field.field_name.trim().replace(/ /g, '_').toLowerCase();
  const [fieldValue, setFieldValue] = useState('');
  const[selectedOptions, setSelectedOptions] = useState(null);

  var options = [];

  useEffect(() => {
    if (props.field.field_type === 'multiselect') {
      setSelectedOptions(props.jobTemplateValue);
    } else if (props.field.field_type === 'checkbox') {
      if (props.jobTemplateValue == null) {
        var checkboxOptions = fieldOptions.map((option, index) => { return { name: option, isChecked: false } });
        setFieldOptions(checkboxOptions);
      } else {
        setFieldOptions(props.jobTemplateValue);
      }
    } else {
      setFieldValue(props.jobTemplateValue);
    }
  }, []);

  if (props.field.field_type === 'multiselect') {
    options = field_option.map((option, index) => { return { value: option, label: option } });
  }

  const onChange = (event) => {
    if (event.target.type === 'text' || event.target.type === 'number' || event.target.type === 'date' || event.target.type === 'time' || event.target.type === 'select-one') {
      event.preventDefault();
      setFieldValue(event.target.value);
      props.changeHandler(event.target.name, event.target.value);
    } else if (event.target.type === 'checkbox') {
      var checkboxName = event.target.name;
      var checkboxes = document.getElementsByName(checkboxName);
      var checkBoxArray = [];
      checkboxes.forEach(function(element) {
        checkBoxArray.push({ name: [element.value], isChecked: element.checked });
      });
      props.changeHandler(event.target.name, checkBoxArray);
      setFieldOptions(checkBoxArray);
    } else if (event.target.type === 'radio') {
      setFieldValue(event.target.value);
      props.changeHandler(event.target.name, event.target.value);
    }
  }

  function multiSelectChange(val, name) {
    setSelectedOptions(val);
    props.changeHandler(name, val);
  }

  return (
    <React.Fragment>
      <div className="col-lg-6 col-sm-6">
        <div className="form-group">
          <label>{props.field.field_name}</label>
          {
            (props.field.field_type === 'text' || props.field.field_type === 'number' || props.field.field_type === 'date' || props.field.field_type === 'time') && (
              <input onChange={onChange} readOnly={props.readOnly} value={fieldValue} type={props.field.field_type} name={input_name} className="form-control" placeholder={props.field.field_name} />
            )
          }
          {
            props.field.field_type === 'select' && (
              <div className="styled-select">
                <select name={input_name} onChange={onChange} value={fieldValue} disabled={props.readOnly}>
                  <option value="" selected="selected">Select</option>
                  {
                    fieldOptions.map((field_options, index) => {
                      return (
                        <option value={field_options}>{field_options}</option>
                      )
                    })
                  }
                </select>
                <span className="fa fa-angle-down"></span>
              </div>
            )
          }
          {
            props.field.field_type === 'multiselect' && (
              <Select isDisabled={props.readOnly} name={input_name} value={selectedOptions} className="multiselect-12" isMulti options={options} onChange={(val) => multiSelectChange(val, input_name)} />
            )
          }
          {
            props.field.field_type === 'checkbox' && (
              <div className="form-group">
                {
                  fieldOptions.map((field_option, index) => {
                    return (
                      <span className="mr-2"><input disabled={props.readOnly} checked={field_option.isChecked} type="checkbox" name={input_name} value={field_option.name} onChange={onChange} className="mr-2" />{field_option.name}</span>
                    )
                  })
                }
              </div>
            )
          }
          {
            props.field.field_type === 'radio' && (
              <div className="form-group">
                {
                  fieldOptions.map((field_options, index) => {
                    return (
                      <React.Fragment key={index}>
                        <span className="mr-2">
                          <input disabled={props.readOnly} value={field_options} checked={fieldValue === field_options} onChange={onChange} type="radio" name={input_name} className="mr-2" />{field_options}
                        </span>
                      </React.Fragment>
                    )
                  })
                }
              </div>
            )
          }
        </div>
      </div>
    </React.Fragment>
  );
}

export default Input;