import React, { useState, useEffect } from 'react';
import { getAPIHeaders, getCompanyUserBlankObject } from '../../../helpers/functions';
import config from '../../../config.json';
import axios from "axios";

const Modal = (props) => {

  const [formFields, setFormFields] = useState(getCompanyUserBlankObject());
  const [roles, setRoles] = useState([]);

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  useEffect(() => {
    axios.get(config.BaseURL + '/v1/roles', { headers: getAPIHeaders() }).then(function (response) {
      setRoles(response.data);
    });
  }, []);

  useEffect(() => {
    setFormFields(props.editableObject);
  }, [props.editableObject]);

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var formData = { ...formFields };
    axios.put(config.BaseURL + "/v1/companies/" + formFields.company_id + "/users/" + formFields.id, formData, { headers: getAPIHeaders() }).then((response) => {
      resetForm(event);
      props.refreshList();
    }).catch((error) => {
      console.log(error);
    });
  }

  const onModalClose = (event) => {
    resetForm(event);
  }
  return (
    <React.Fragment>
      <div className="modal" id="editmangeModal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Company User</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="" method="">
                <div className="form-group">
                  <label>Name</label>
                  <input autoComplete="off" onChange={handleChange} type="text" name="name" value={formFields['name']} className="form-control" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input autoComplete="off" onChange={handleChange} type="email" name="email" value={formFields['email']} className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label>Contact Number</label>
                  <input autoComplete="off" onChange={handleChange} type="text" name="contact_no" value={formFields['contact_no']} className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label>Role</label>
                  <div className="styled-select">
                    <select onChange={handleChange} name="role_id" value={formFields['role_id']}>
                      <option value=""> Select Role </option>
                      {
                        roles.map((role, index)=>{
                          return(
                            <option key={index} value={role.id}>{role.name}</option>
                          )
                        })
                      }
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={onModalClose} type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
              <button onClick={submitHandler} type="button" className="btn btn-primary"><i className="fa fa-save"></i>&nbsp; Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default Modal;