import React, { useState, useEffect, useRef } from 'react';
import InnerHeader from "../Shared/InnerHeader";
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import Edit from '../Users/Edit';
import config from '../../config.json';
import { getAPIHeaders, getComUserBlankObject } from '../../helpers/functions';
import axios from 'axios';
import Pagination from '../Shared/Pagination';
import { useToasts } from 'react-toast-notifications';
import LoadingBar from '../Shared/LoadingBar';

const List = (props) => {

  const [modalObject, setModalObject] = useState({ modalVisible: false, editableObject: {} });
  const [users, setUsers] = useState([]);
  const [usersLinks, setUsersLinks] = useState([]);
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const searchTermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  let url = config.BaseURL + "/v1/company-users";

  useEffect(() => {
    // fetchUsers(url);
    loadUsers(url);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchUsers();
  }

  const searchUsers = () => {
    setSearchTerm(searchTermRef.current.value);
  }

  function fetchUsers(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm } }).then(function (response) {
      let classArray = [];
      response.data.data.forEach(element => {
        classArray.push(element);
      });
      setUsers(classArray);
      setIsLoading(false);
      setUsersLinks(response.data.links);
    });
  }


  const removeData = (userObject, event) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(config.BaseURL + "/v1/company/" + userObject.id + "/user", { headers: getAPIHeaders() }).then(function (response) {
        console.log(response);

        addToast("User Deleted Successfully!", { appearance: 'error', autoDismiss: true });
        loadUsers(url);
      });
    }
  }

  function loadUsers(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, type: 'all' } }).then(function (response) {
      let classArray = [];
      response.data.data.forEach(element => {
        classArray.push(element);
      });
      setUsers(classArray);
      setIsLoading(false);
      setUsersLinks(response.data.links);
    });
  }

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible,
      editableObject: getComUserBlankObject()
    });
  }

  const editClassHandler = (event, object) => {
    event.preventDefault();
    setModalObject({
      modalVisible: true,
      editableObject: object
    });
  }

  const updateUser = (event, userObject, action) => {
    event.preventDefault();
    if (action === 'in-active') userObject.status = 'inactive';
    if (action === 'active') userObject.status = 'active';
    axios.put(config.BaseURL + "/v1/company/" + userObject.id + "/user", userObject, { headers: getAPIHeaders() }).then(function (response) {
      if (userObject.status === 'active') {
        addToast('User is' + ' ' + 'Active', { appearance: 'success', autoDismiss: true });
      } else {
        addToast("User is" + " " + 'Inactive', { appearance: 'error', autoDismiss: true });
      }
      loadUsers(url);
    });
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      {ReactDOM.createPortal(<Edit editableObject={modalObject.editableObject} isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} refreshList={() => loadUsers(url)} />, document.getElementById('modal-root'))}
      <InnerHeader title="Manage Users" buttonTitle="Add" icon="fa fa-plus" path="/manage-users/add" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchTermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchUsers} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div class="col-lg-8 col-sm-8 text-right">
                  <Link onClick={(event) => modalHandler(event, true)} to='/' data-toggle="modal" class="news-job-btn"><i class="fa fa-plus"></i>&nbsp; Add</Link>
                </div> */}
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>User Name <span className="pull-right"></span></th>
                        <th>Email <span className="pull-right"></span></th>
                        <th>Contact Number <span className="pull-right"></span></th>
                        {/* <th>Role <span className="pull-right"><i className='fa fa-sort-up'></i><i className='fa fa-sort-down'></i></span></th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        users.map((userObject, index) => {
                          return <tr key={userObject.id}>
                            <td>{index + 1}</td>
                            <td>{userObject.name}</td>
                            <td>{userObject.email}</td>
                            <td>{userObject.contact_no}</td>
                            {/* <td>{userObject.role}</td> */}
                            <td>
                              <Link to='/' onClick={(event) => editClassHandler(event, userObject)} data-toggle="modal" className="download-btn bg-text-color-2a78e4-fff"><i className="fa fa-edit"></i></Link>&nbsp;
                              <Link to='/' onClick={(event) => removeData(userObject, event)} className="share-alt bg-color-cf2a2d"><i className="fa fa-trash"></i></Link>
                              {userObject.status === 'active' && <Link to='/' title="Active" onClick={(event) => updateUser(event, userObject, 'in-active')} className="share-alt" style={{ background: '#000', color: '#fff' }}><i className="fa fa-close"></i></Link>}
                              {userObject.status === 'inactive' && <Link to='/' title="Inactive" onClick={(event) => updateUser(event, userObject, 'active')} className="share-alt bg-color-text-2aa562-fff"><i className="fa fa-check"></i></Link>}
                            </td>
                          </tr>
                        })
                      }
                      {users.length === 0 ? <tr><td colSpan='6' className='text-center'>No Record Found</td></tr> : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
              {users.length === 0 ? '' : <Pagination links={usersLinks} onPageClick={loadUsers} />}
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default List;