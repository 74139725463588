import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from "../../../config.json";
import { getAPIHeaders, getFormsObject } from '../../../helpers/functions';
import { useToasts } from 'react-toast-notifications'

const AddEditForm = (props) => {

  const [formFields, setFormFields] = useState(getFormsObject());
  const [documentType, setDocumentType] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const { addToast } = useToasts();

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  useEffect(() => {
    setFormFields(props.editableObject);
    axios.get(config.BaseURL + '/v1/document-types?all=true', { headers: getAPIHeaders() }).then(function (response) {
      setDocumentType(response.data);
    });
    axios.get(config.BaseURL + '/v1/document-categories?all=true', { headers: getAPIHeaders() }).then(function (response) {
      setCategoryType(response.data);
    });
  }, []);

  useEffect(() => {
    setFormFields(props.editableObject);
  }, [props.editableObject]);

  const submitHandler = (event) => {
    event.preventDefault();
    const formData = { ...formFields };
    if (!formData.hasOwnProperty("id")) {
      axios.post(config.BaseURL + "/v1/companies/" + props.companyId + "/forms", formData, { headers: getAPIHeaders() }).then(function (response) {
        resetForm(event);
        addToast("Form Added Successfully!", { appearance: 'success', autoDismiss: true });
        props.refreshList();
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      })
    } else {
      axios.put(config.BaseURL + "/v1/companies/" + props.companyId + "/forms/" + formData.id, formData, { headers: getAPIHeaders() }).then(function (response) {
        resetForm(event);
        addToast("Form Updated Successfully!", { appearance: 'success', autoDismiss: true });
        props.refreshList();
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      })
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function resetForm(event) {
    props.modalCloseHandler(event, false);
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div className="modal" id="jobtempaddModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Form</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="">
                <div className="fieldset-form">
                  <div className="row p-0">
                    <div className="col-lg-12 col-sm-12 floatNone">
                      <div className="form-group">
                        <label>Form Name</label>
                        <input type="text" name="name" onChange={handleChange} value={formFields['name']} className="form-control" placeholder="Form Name" />
                      </div>
                      <div className="row form-group">
                        <div className="col-md-6">
                          <label>Form Type</label>
                          <div className="styled-select">
                            <select onChange={handleChange} name="type_id" value={formFields['type_id']}>
                              <option value="">Select</option>
                              {
                                documentType.map((Dtype, index) => {
                                  return (
                                    <option key={index} value={Dtype.id}>{Dtype.name}</option>
                                  )
                                })
                              }
                            </select>
                            <span className="fa fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>Form Category</label>
                          <div className="styled-select">
                            <select onChange={handleChange} name="category_id" value={formFields['category_id']}>
                              <option value=""> Select </option>
                              {
                                categoryType.map((Ctype, index) => {
                                  return (
                                    <option key={index} value={Ctype.id}>{Ctype.name}</option>
                                  )
                                })
                              }
                            </select>
                            <span className="fa fa-angle-down"></span>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Code</label>
                        <input type="text" name="code" onChange={handleChange} value={formFields['code']} className="form-control" placeholder="Code" />
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <textarea onChange={handleChange} name="description" value={formFields['description']} className="form-control height-100" placeholder="Description"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={submitHandler} type="button" className="btn btn-primary"><i className="fa fa-save"></i>&nbsp; Save</button>
              <button onClick={onModalClose} type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default AddEditForm;