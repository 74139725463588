import React, {useState} from 'react';
import ForgotPassword from '../Auth/ForgotPassword';
import config from '../../config.json';
import axios from "axios";
import logo from '../../assets/img/logo.png';
import { useToasts } from 'react-toast-notifications';

const Login = (props) => {

  const [formFields, setFormFields] = useState({ email: '', password: '' });
  const [validationError] = useState(''); 
  const { addToast } = useToasts();
  
  const handleChange = (event) => {
     event.preventDefault();
     const { name, value } = event.target;
     const formFieldsObject = {
       ...formFields,
       [name]: value
     }
     setFormFields(formFieldsObject);
  }

  const [forgotObject, setForgotObject] = useState({
    modalVisible: false
  });

  const modalHandler = (event) => {
    event.preventDefault();
    setForgotObject({
      modalVisible: !forgotObject.modalVisible
    });
  }

  const forgotModalHandler = (event) => {
    event.preventDefault();
    setForgotObject({
      modalVisible: true
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/authenticate", formFields).then(function (response) {
      console.log(response);
      localStorage.setItem('token', response.data.api_token);
      localStorage.setItem('user_type', response.data.user_type);
      localStorage.setItem('profile_image', response.data.profile_image);
      localStorage.setItem('name', response.data.name);
      props.signInSuccess();
    }).catch(function(error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  return (
    <React.Fragment>
      <ForgotPassword isModalVisible={forgotObject.modalVisible} setForgotObject={setForgotObject} onModalClose={modalHandler} />
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              <div className="col-lg-6">
                <div className="info d-flex align-items-center">
                  <div className="content">
                    <div className="logo text-center">
                      <img className="img-fluid" src={logo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center">
                  <div className="content">
                    <h1>Login</h1>
                    {validationError && 
                    <div className="alert alert-danger" role="alert">
                      {validationError}
                    </div>
                    }
                    <form id="login-form" method="post" >
                      <div className="form-group">
                        <input placeholder="Email" onChange={handleChange}  value={formFields['email']} id="email" type="text" name="email" required="" className="input-material"  />
                        {/* <label for="email" className ="label-material">Email</label> */}
                      </div>
                      <div className="form-group">
                        <input placeholder="Password" onChange={handleChange} value={formFields['password']}  id="login-password" type="password" name="password" required="" className="input-material"  />
                        {/* <label for="login-password" className ="label-material">Password</label> */}
                      </div>
                      <a href="/" id="login" onClick={submitHandler} className="btn btn-primary"><i className="fa fa-sign-in"></i>&nbsp; Login</a>
                    </form>
                    <a href="/" onClick={forgotModalHandler}  className="forgot-pass">Forgot Password?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default Login;