import React from 'react';
import { getUserType } from '../../helpers/functions';
import ManageJobs from './List'; // Company Admin
import ManageJob from '../ManageJob/List'; // Company User

const ManageJobMaster = () => {
  const userType = getUserType();
  return (
    userType === 'company_admin' ? <ManageJobs /> : <ManageJob />
  )
}

export default ManageJobMaster;