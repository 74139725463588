import axios from 'axios';
import React, { useEffect, useState } from 'react';
import InnerHeader from '../Shared/InnerHeader';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';

const Setting = () => {

  const [settings, updateSettings] = useState([]);
  const { addToast } = useToasts();

  useEffect(() => {
    loadSettings();
  }, []);

  function loadSettings() {
    axios.get(config.BaseURL + "/v1/settings", { headers: getAPIHeaders() }).then(function (response) {
      updateSettings(response.data);
    }).catch((error) => {
    });
  }

  function handleChange(event, id) {
    const { name, checked } = event.target;
    const settingsArray = [...settings];
    settingsArray.forEach(element => {
      if (element.id == id) {
        element.meta_value = checked ? 'true' : 'false';
      }
    });
    updateSettingsOnServer(settingsArray);
    updateSettings(settingsArray);
  }

  function updateSettingsOnServer(settingsArray) {
    axios.post(config.BaseURL + "/v1/settings/bulk-update", settingsArray, { headers: getAPIHeaders() }).then(function (response) {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
    }).catch((error) => {
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Settings" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="md-font">Job Template Settings</div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <tbody>
                      {
                        settings.map((setting, index) => {
                          return (
                            <tr key={index}>
                              <td><input onChange={(event) => handleChange(event, setting.id)} type="checkbox" name={setting.meta_key} checked={setting.meta_value == 'true'} /></td>
                              <td>{setting.meta_key}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default Setting;