import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const LifeBoatAirSystemCylinder = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', technical_detail_make_type: '', technical_detail_pressure_test_date: '', technical_detail: '', service_engineer_name: '', service_engineer_sign: '', form_list: [] });
  // const [inputFields, setInputFields] = useState([{ type_of_gas_cylinders: '', cylinder_serial_no: '', cylinder_volume: '', tare_weight_kg: '', gross_weight_kg: '', check_weight_kg: '', testing_pressure_bar: '', working_pressure_ba: '', last_pressure_test_date: '', temp: '', working_codes: ''}]);
  const [inputFields, setInputFields] = useState([]);
  // console.log(inputFields);
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const handleChangeInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    // console.log(value);
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.form_list[index][name] = event.target.value;
    setFormFields(_formFields);
  }

  const addRow = () => {
    const _formFields = { ...formFields };
    _formFields.form_list.push({ type_of_gas_cylinders: '', cylinder_serial_no: '', cylinder_volume: '', tare_weight_kg: '', gross_weight_kg: '', check_weight_kg: '', testing_pressure_bar: '', working_pressure_ba: '', last_pressure_test_date: '', temp: '', working_codes: '' });
    setFormFields(_formFields);
  }
  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.form_list.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Lifeboat Air Support System" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label>Technical Details</label>
                  <div className="row p-0">
                    <table className="table table-responsive table-striped table-hover">
                      <tr>
                        <td>Make/Type</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_make_type" value={formFields['technical_detail_make_type']} className="form-control" placeholder="Make/Type" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Last Pressure Test Date</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_pressure_test_date" value={formFields['technical_detail_pressure_test_date']} className="form-control" placeholder="Last Pressure Test Date" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>No Of Air Cylinders</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_air_cylinders" value={formFields['technical_detail_air_cylinders']} className="form-control" placeholder="No Of Air Cylinders" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Capacity Of Lifeboat</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_capacity_lifeboat" value={formFields['technical_detail_capacity_lifeboat']} className="form-control" placeholder="Capacity Of Lifeboat" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Capacity Of Air Cylinders</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_capacity_air_cylinders" value={formFields['technical_detail_capacity_air_cylinders']} className="form-control" placeholder="Capacity Of Air Cylinders" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Make Of Lifeboat</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_make_lifeboat" value={formFields['technical_detail_make_lifeboat']} className="form-control" placeholder="Make Of Lifeboat" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Working Pressure</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_working_pressure" value={formFields['technical_detail_working_pressure']} className="form-control" placeholder="Working Pressure" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial Number Of Lifeboat</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_lifeboat_serial_number" value={formFields['technical_detail_lifeboat_serial_number']} className="form-control" placeholder="Serial Number Of Lifeboat" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type Of Inspection</td>
                        <td><input type="checkbox" name="inspection_type_annual" onClick={checkboxHandleChange} checked={formFields['inspection_type_annual']} className="mr-2" disabled={props.can_edit_form} /> Annual</td>
                        <td><input type="checkbox" name="inspection_type_five" onClick={checkboxHandleChange} checked={formFields['inspection_type_five']} className="mr-2" disabled={props.can_edit_form} /> 5 Yearly</td>
                        <td><input type="checkbox" name="inspection_type_ten" onClick={checkboxHandleChange} checked={formFields['inspection_type_ten']} className="mr-2" disabled={props.can_edit_form} /> 10 Yearly</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: "15%" }} />
                      <col style={{ width: "29%" }} />
                      <col style={{ width: "13%" }} />
                      <col style={{ width: "13%" }} />
                      <col style={{ width: "10%" }} />
                      <col style={{ width: "20%" }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Description of Inspection / Test</th>
                        <th className="text-center">Carried Out</th>
                        <th className="text-center">Not Carried Out</th>
                        <th className="text-center">Not Applicable</th>
                        <th>Comments</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Air cylinders <br /> inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_inspected']} name="air_cylinders_inspected" disabled={props.can_edit_form} /> tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_tested']} name="air_cylinders_tested" disabled={props.can_edit_form} /> Refilled <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_refilled']} name="air_cylinders_refilled" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_carried_out']} name="air_cylinders_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_not_carried_out']} name="air_cylinders_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_cylinders_not_applicable']} name="air_cylinders_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="air_cylinders_comment" value={formFields['air_cylinders_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Air Regulators & hose connection <br />inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_regulators_hose_connection_inspected']} name="air_regulators_hose_connection_inspected" disabled={props.can_edit_form} /> tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_regulators_hose_connection_tested']} name="air_regulators_hose_connection_tested" disabled={props.can_edit_form} /> </td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_regulators_hose_connection_carried_out']} name="air_regulators_hose_connection_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_regulators_hose_connection_not_carried_out']} name="air_regulators_hose_connection_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['air_regulators_hose_connection_not_applicable']} name="air_regulators_hose_connection_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="air_regulators_hose_connection_comment" value={formFields['air_regulators_hose_connection_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Cylinders securing inside compartment Checked</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['securing_inside_compartment_checked_carried_out']} name="securing_inside_compartment_checked_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['securing_inside_compartment_checked_not_carried_out']} name="securing_inside_compartment_checked_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['securing_inside_compartment_checked_not_applicable']} name="securing_inside_compartment_checked_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="securing_inside_compartment_checked_comment" value={formFields['securing_inside_compartment_checked_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Pipes and fittings <br />inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pipes_fittings_inspected']} name="pipes_fittings_inspected" disabled={props.can_edit_form} /> tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pipes_fittings_tested']} name="pipes_fittings_tested" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pipes_fittings_carried_out']} name="pipes_fittings_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pipes_fittings_not_carried_out']} name="pipes_fittings_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pipes_fittings_not_applicable']} name="pipes_fittings_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="pipes_fittings_comment" value={formFields['pipes_fittings_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Working Pressure Tested </td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['working_pressure_tested_carried_out']} name="working_pressure_tested_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['working_pressure_tested_not_carried_out']} name="working_pressure_tested_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['working_pressure_tested_not_applicable']} name="working_pressure_tested_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="working_pressure_tested_comment" value={formFields['working_pressure_tested_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td>Pressure Gauges <br />inspected <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pressure_gauges_inspected']} name="pressure_gauges_inspected" disabled={props.can_edit_form} /> tested <input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pressure_gauges_tested']} name="pressure_gauges_tested" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pressure_gauges_carried_out']} name="pressure_gauges_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pressure_gauges_not_carried_out']} name="pressure_gauges_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['pressure_gauges_not_applicable']} name="pressure_gauges_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="pressure_gauges_comment" value={formFields['pressure_gauges_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td>Rubber Hoses tested for operation; no leakage observed</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['rubber_hoses_tested_carried_out']} name="rubber_hoses_tested_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['rubber_hoses_tested_not_carried_out']} name="rubber_hoses_tested_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['rubber_hoses_tested_not_applicable']} name="rubber_hoses_tested_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="rubber_hoses_tested_comment" value={formFields['rubber_hoses_tested_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>8</td>
                        <td>Operating Instructions available and checked. </td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['operating_instructions_carried_out']} name="operating_instructions_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['operating_instructions_not_carried_out']} name="operating_instructions_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['operating_instructions_not_applicable']} name="operating_instructions_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="operating_instructions_comment" value={formFields['operating_instructions_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>9</td>
                        <td>Air cylinder valve tested for operation</td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['cylinder_valve_tested_operation_carried_out']} name="cylinder_valve_tested_operation_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['cylinder_valve_tested_operation_not_carried_out']} name="cylinder_valve_tested_operation_not_carried_out" disabled={props.can_edit_form} /></td>
                        <td className="text-center"><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['cylinder_valve_tested_operation_not_applicable']} name="cylinder_valve_tested_operation_not_applicable" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="cylinder_valve_tested_operation_comment" value={formFields['cylinder_valve_tested_operation_comment']} className="form-control" placeholder="" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Working Codes For Cylinder Test Records</label>
                    <div className="row p-0">
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>External inspection</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Contents checked by weight</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Contents checked by pressure </td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Contents checked by level </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Recharging</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>6</td>
                            <td>Hydrostatic test</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Valve renewal</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Valve repair</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>External maintenance</td>
                          </tr>
                          <tr>
                            <td>10</td>
                            <td>New cylinder</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type of Gas Cylinder</th>
                        <th>Cylinder Serial Number</th>
                        <th>Cylinder Volume </th>
                        <th>Tare Weight (Kg)</th>
                        <th>Gross Weight (Kg)</th>
                        <th>Check Weight(Kg)</th>
                        <th>Testing Pressure (Bar)</th>
                        <th>Working Pressure (Bar)</th>
                        <th>Last Pressure test Date </th>
                        <th>Temp °C / °F</th>
                        <th>Working Codes</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.form_list.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td><input type="text" name="type_of_gas_cylinders" onChange={(event) => handleChangeRows(event, 'type_of_gas_cylinders', index)} value={item['type_of_gas_cylinders']} className="form-control" autocomplete="off" placeholder="CO2" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="cylinder_serial_no" onChange={(event) => handleChangeRows(event, 'cylinder_serial_no', index)} value={item['cylinder_serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="cylinder_volume" onChange={(event) => handleChangeRows(event, 'cylinder_volume', index)} value={item['cylinder_volume']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="tare_weight_kg" onChange={(event) => handleChangeRows(event, 'tare_weight_kg', index)} value={item['tare_weight_kg']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="gross_weight_kg" onChange={(event) => handleChangeRows(event, 'gross_weight_kg', index)} value={item['gross_weight_kg']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="check_weight_kg" onChange={(event) => handleChangeRows(event, 'check_weight_kg', index)} value={item['check_weight_kg']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="testing_pressure_bar" onChange={(event) => handleChangeRows(event, 'testing_pressure_bar', index)} value={item['testing_pressure_bar']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="working_pressure_ba" onChange={(event) => handleChangeRows(event, 'working_pressure_ba', index)} value={item['working_pressure_ba']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="date" name="last_pressure_test_date" onChange={(event) => handleChangeRows(event, 'last_pressure_test_date', index)} value={item['last_pressure_test_date']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="temp" onChange={(event) => handleChangeRows(event, 'temp', index)} value={item['temp']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input type="text" name="working_codes" onChange={(event) => handleChangeRows(event, 'working_codes', index)} value={item['working_codes']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default LifeBoatAirSystemCylinder;