import React, { useState, useEffect } from "react";
import config from '../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../helpers/functions";
import { useToasts } from 'react-toast-notifications';

const RejectDocumentModal = (props) => {
    
    const style = {
        display: props.isModalVisible ? 'block' : 'none'
    };
    
   
    
    const submitHandler = (event) => {
        event.preventDefault();
        
    }
    
    function onModalClose(event) {
        props.RejectmodalHandler(event, false)
    }
    
    return (
    <React.Fragment>
        <div class="modal" id="rejectedModal" aria-hidden="true" style={style}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="exampleModalLongTitle">Are you sure reject?</h3>
                        <button onClick={onModalClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-left">
                        <div class="form-group">
                            <label>Rejected Reason</label>
                            <textarea onChange={props.handleChange} name="comment" value={props.comment} class="form-control height-100" placeholder="Rejected reason here"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button onClick={props.jobRejectHandler} type="button" class="btn btn-primary">Yes, Reject</button>
                        <button onClick={onModalClose} type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        {
            props.isModalVisible && (
            <div className="modal-backdrop fade show"></div>
            )
        }
    </React.Fragment>
    );
}

export default RejectDocumentModal;