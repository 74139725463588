import React, { useState, useEffect } from "react";
import InnerHeader from '../Shared/InnerHeader';
import Jobs from "../../assets/img/jobs.png";
import pendingJob from "../../assets/img/pending-job.png";
import reviewedJob from "../../assets/img/reviewed-jobs.png";
import approvedJob from "../../assets/img/approved-job.png";
import rejected from "../../assets/img/rejected.png";
import TotalJobsChart from "./TotalJobsChart";
import NoOfJobsChart from "./NoOfJobsChart";
import FlagJobsChart from "./FlagJobsChart";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import config from '../../config.json';
import { getAPIHeaders } from "../../helpers/functions";

const Dashboard = (props) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [jobsCount, setJobsCount] = useState([]);
  const [classes, setClass] = useState([]);
  const [flags, setFlags] = useState([]);
  const [monthChartData, setMonthChartData] = useState([]);
  const [monthChartValues, setMonthChartValues] = useState([]);
  const [flagChartData, setFlagChartData] = useState([]);
  const [chartFlags, setChartFlags] = useState([]);
  const [searchFlag, setSearchFlag] = useState('');
  // const [searchClass, setSearchClass] = useState('');
  const [classChartData, setClassChartData] = useState([]);
  const [chartClass, setChartClass] = useState([]);

  let classURL = config.BaseURL + "/v1/classes";
  let flagURL = config.BaseURL + "/v1/flags";

  useEffect(() => {
    const date = new Date();
    const date_ = date.toJSON().slice(0, 10);
    //date 1st date of current month
    const currentMonthFirstDate =  date_.slice(0, 4) + '-' + date_.slice(5, 7) + '-' + '01';
    //Today's Date
    const today = date_.slice(0, 4) + '-' + date_.slice(5, 7)  + '-' + date_.slice(8, 10);
    setStartDate(currentMonthFirstDate);
    setEndDate(today)
    loadJobCounts();
    loadFlags(flagURL);
    loadClass(classURL);
    classChart();
  }, []);

  useEffect(() => {
    console.log('ola99');
    monthChart();
  }, [startDate, endDate]);

  useEffect(() => {
    flagChart();
  }, [searchFlag]);
  
  const monthChart = () => {
    axios.get(config.BaseURL + '/v1/chart-details?chart=month&date_from='+ startDate + '&date_to=' + endDate , { headers: getAPIHeaders() }).then(function (response) {
      setMonthChartData(response.data.keys?? []);
      setMonthChartValues(response.data.values?? []);
    })
  }

  const flagChart = () => {
    axios.get(config.BaseURL + '/v1/chart-details?chart=flag&flag_id='+searchFlag , { headers: getAPIHeaders() }).then(function (response) {
      setFlagChartData(response.data.values);
      setChartFlags(response.data.flags);
    })
  }

  const classChart = (searchClass = '') => {
    axios.get(config.BaseURL + '/v1/chart-details?chart=class&class_id='+searchClass , { headers: getAPIHeaders() }).then(function (response) {
      setClassChartData(response.data.values);
      setChartClass(response.data.classes);
    })
  }

  function loadJobCounts() {
    axios.get(config.BaseURL + "/v1/jobs-count", { headers: getAPIHeaders() }).then(function (response) {
      setJobsCount(response.data);
    });
  }

  const loadFlags = (flagURL) => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then(function (response) {
      setFlags(response.data.data);
    })
  }
  const loadClass = (classURL) => {
    axios.get(classURL, { headers: getAPIHeaders() }).then(function (response) {
      setClass(response.data.data);
    })
  }

  return (
    <React.Fragment>
      <InnerHeader title="Dashboard" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row p-0 m-l-r">
            <div className="col-lg-12 col-sm-12">
              <h3>Job Status</h3>
            </div>
            </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 totaljobBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={Jobs} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Total Job</div>
                      <div className="lg-fonts">{jobsCount.total_jobs}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 pendingBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={pendingJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Pending</div>
                      <div className="lg-fonts">{jobsCount.pending_jobs}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 reviewedBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={reviewedJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Reviewed</div>
                      <div className="lg-fonts">{jobsCount.reviewed_jobs}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 approvedBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={approvedJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Approved</div>
                      <div className="lg-fonts">{jobsCount.approved_jobs}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mr-0 mb-4">
              <div className="card shadow mb-0 rejectedBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={rejected} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Rejected</div>
                      <div className="lg-fonts">{jobsCount.rejected_jobs}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          <div class="clearfix">&nbsp;</div>
          <div className="row p-0 m-l-r">
            <div className="col-lg-12 col-sm-12">
              <h3>Form Status</h3>
            </div>
             </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 pendingBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={pendingJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Pending</div>
                      <div className="lg-fonts">{jobsCount.pending_forms}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mb-4">
              <div className="card shadow mb-0 approvedBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={approvedJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Approved</div>
                      <div className="lg-fonts">{jobsCount.approved_forms}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-wid1 mb-4 mr-0">
              <div className="card shadow mb-0 reviewedBox">
                <div className="card-body">
                  <div className="no-gutters align-items-center">
                    <div className="col">
                      <img src={reviewedJob} alt="" className="pull-left mr-3" width="55" height="55" />
                      <div className="mb-1 box-titles">Reviewed</div>
                      <div className="lg-fonts">{jobsCount.reviewed_forms}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         <div class="clearfix">&nbsp;</div>
          <div className="row p-0 m-l-r">
            <div className="col-lg-6 col-sm-6">
              <div className="bar-chart-example card">
                <div className="row card-header align-items-center px-0">
                  <h3 className="h4 col-lg-3 col-sm-4 col-12 mb-3 font-size-16">Month Vs Total Jobs</h3>
                  <div className="col-lg-9 col-sm-8 col-12">
                    <div className="input-group input-daterange custom-input-daterange">
                      <input className="form-control text-left mr-2 width-100" style={{width: '49% !important'}} type="date" name="start_date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      {/* <span className="fa fa-calendar" id="fa-1"></span> */}
                      <input className="form-control text-left mr-2 width-100" style={{width: '49% !important'}} type="date" name="end_date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      {/* <span className="fa fa-calendar" id="fa-2"></span> */}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <TotalJobsChart  monthChartData={monthChartData} monthChartValues={monthChartValues} />
                  {/* <canvas id="barMonthjobs"></canvas> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="bar-chart-example card">
                <div className="row card-header align-items-center px-0">
                  <h3 className="h4 col-lg-6 col-sm-6 col-6 font-size-16">Class Vs No of Jobs</h3>
                  <div className="col-lg-6 col-sm-6 col-6">
                    <div className="styled-select">
                      <select  name="" onChange={(e) => {classChart(e.target.value) }} >
                        <option value="" > Select Class </option>
                        {
                          classes.map((item, index) => {
                            return (
                              <option value={item.id}>{item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <NoOfJobsChart  classChartData={classChartData} classes={chartClass} />
                  {/* <canvas id="barClassjobs"></canvas> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="bar-chart-example card">
                <div className="row card-header align-items-center px-0">
                  <h3 className="h4 col-lg-6 col-sm-6 col-6 font-size-16">Flag Vs No of jobs</h3>
                  <div className="col-lg-6 col-sm-6 col-6">
                    <div className="styled-select">
                      <select id="" name="" onChange={(e) => setSearchFlag(e.target.value)} >
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <FlagJobsChart flagChartData={flagChartData} flags={chartFlags} />
                  {/* <canvas id="barFlagjobs"></canvas> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
export default Dashboard;