import React from 'react';
import verifiedIcon from '../../assets/img/verified-icon.png';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import config from '../../config.json';

const ThankYou = () => {
    let history = useHistory();
    const { token } = useParams();
    return (
        <div className="page login-page">
            <div className="container d-flex align-items-center">
                <div className="form-holder">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 float-none bg-white text-center" style={{margin: '0 auto'}}>
                            <div className="form d-flex align-items-center">
                                <div className="content">
                                    <img src={verifiedIcon} alt="" className="img-fluid" />
                                    <h1 style={{ fontSize: '44px'}} className="pt-2">Thank you!</h1>
                                    <h2 style={{ fontWeight: '50'}} className="pt-2">Thank you for submitting your feedback.</h2>
                                    <div className="pt-4"><a href={`/#/document-information/${token}`} className="btn btn-primary" ><i className="fa fa-angle-left"></i>&nbsp;&nbsp; Back</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ThankYou