import React, { useState, useEffect } from "react";
import InnerHeader from "../Shared/InnerHeader";
import FormButton from "../Shared/FormButton";
import { getAPIHeaders } from "../../helpers/functions";
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';

import logo1 from '../../assets/img/catalog-img/logo1.jpg';
import logo2 from '../../assets/img/catalog-img/logo2.jpg';
import logo3 from '../../assets/img/catalog-img/logo3.jpg';
import logo4 from '../../assets/img/catalog-img/logo4.jpg';
import logo5 from '../../assets/img/catalog-img/logo5.jpg';
import logo6 from '../../assets/img/catalog-img/logo6.jpg';
import logo7 from '../../assets/img/catalog-img/logo7.jpg';
import logo8 from '../../assets/img/catalog-img/logo8.jpg';
import logo9 from '../../assets/img/catalog-img/logo9.jpg';
import logo10 from '../../assets/img/catalog-img/logo10.jpg';
import logo11 from '../../assets/img/catalog-img/logo11.jpg';
import logo12 from '../../assets/img/catalog-img/logo12.jpg';
import logo13 from '../../assets/img/catalog-img/logo13.jpg';
import logo14 from '../../assets/img/catalog-img/logo14.jpg';
import logo15 from '../../assets/img/catalog-img/logo15.jpg';
import logo16 from '../../assets/img/catalog-img/logo16.jpg';
import logo17 from '../../assets/img/catalog-img/logo17.jpg';
import logo18 from '../../assets/img/catalog-img/logo18.jpg';
import logo19 from '../../assets/img/catalog-img/logo19.jpg';
import logo20 from '../../assets/img/catalog-img/logo20.jpg';
import logo21 from '../../assets/img/catalog-img/logo21.jpg';
import logo22 from '../../assets/img/catalog-img/logo22.jpg';
import logo23 from '../../assets/img/catalog-img/logo23.jpg';
import logo24 from '../../assets/img/catalog-img/logo24.jpg';
import logo25 from '../../assets/img/catalog-img/logo25.jpg';
import logo26 from '../../assets/img/catalog-img/logo26.jpg';
import logo27 from '../../assets/img/catalog-img/logo27.jpg';
import logo28 from '../../assets/img/catalog-img/logo28.jpg';
import logo29 from '../../assets/img/catalog-img/logo29.jpg';
import logo30 from '../../assets/img/catalog-img/logo30.jpg';
import logo31 from '../../assets/img/catalog-img/logo31.jpg';
import logo32 from '../../assets/img/catalog-img/logo32.jpg';
import logo33 from '../../assets/img/catalog-img/logo33.jpg';
import logo34 from '../../assets/img/catalog-img/logo34.jpg';
import logo35 from '../../assets/img/catalog-img/logo35.jpg';
import logo37 from '../../assets/img/catalog-img/logo37.jpg';
import logo38 from '../../assets/img/catalog-img/logo38.jpg';
import logo39 from '../../assets/img/catalog-img/logo39.jpg';
import logo40 from '../../assets/img/catalog-img/logo40.jpg';
import logo42 from '../../assets/img/catalog-img/logo42.jpg';
import logo43 from '../../assets/img/catalog-img/logo43.jpg';
import logo44 from '../../assets/img/catalog-img/logo44.jpg';
import logo45 from '../../assets/img/catalog-img/logo45.jpg';
import logo46 from '../../assets/img/catalog-img/logo46.jpg';
import logo47 from '../../assets/img/catalog-img/logo47.jpg';
import logo48 from '../../assets/img/catalog-img/logo48.jpg';
import logo49 from '../../assets/img/catalog-img/logo49.jpg';
import logo50 from '../../assets/img/catalog-img/logo50.jpg';
import logo51 from '../../assets/img/catalog-img/logo51.jpg';
import logo52 from '../../assets/img/catalog-img/logo52.jpg';
import logo53 from '../../assets/img/catalog-img/logo53.jpg';
import logo54 from '../../assets/img/catalog-img/logo54.jpg';
import logo55 from '../../assets/img/catalog-img/logo55.jpg';
import logo56 from '../../assets/img/catalog-img/logo56.jpg';
import logo57 from '../../assets/img/catalog-img/logo57.jpg';
import logo58 from '../../assets/img/catalog-img/logo58.jpg';


const RescueboatEquipmentChecklist = (props) => {
    let history = useHistory();
    const { addToast } = useToasts();
    const { job_id, form_id } = useParams();
    const [formFields, setFormFields] = useState({control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', rescue_type:'', rescue_serial_mo:'', rescue_manufacture:'', release_type:'', release_serial_no:'', release_manufacture:'', rescue_date_of_manufacture:'', release_date_of_manufacture:'', liferaft_type:'', liferaft_serial_no:'', liferaft_manufacture:'', liferaft_date_of_manufacture:'',  davit_type:'', davit_serial_no:'', davit_manufacture:'', davit_date_of_manufacture:'', winch_type:'', winch_serial_no:'', winch_manufacture:'', winch_date_of_manufacture:'', remark:'',   service_engineer_name:'', service_engineer_sign:'', approved_by:'', winch_kind_of_servicing:false, winch_repair:false, winch_annual_servicing:false, winch_5_yearly_servicing:false });

    let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;


    const actionHandler = (event, action) => {
        event.preventDefault();
        axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
          addToast(response.data.message, { appearance: 'success', autoDismiss: true });
          history.goBack();
        }).catch(function (error) {
          addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
        });
      }

      const submitHandler = (event) => {
        event.preventDefault();
        axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
          addToast(response.data.message, { appearance: 'success', autoDismiss: true });
          history.goBack();
        }).catch(function (error) {
          addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
        });
      }

    return (
    <React.Fragment>
        <InnerHeader title="Rescue Boat Equipment Check List" />
        <header className="page-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <h2 className="no-margin-bottom">ENABLING YOUR FREEDOM TO OPERATE AT SEA</h2>
                    </div>
                </div>
            </div>
        </header>
        <section className="dashboard-counts no-padding-bottom">
            <div className="container-fluid catalog-page px-0">
                <div className="row p-0">
                    <div className="col-lg-4 col-sm-4">
                        <div className="has-shadow p-3 h-100" style={{background: '#f9f9f9'}}>
                            <h2 className="title-h2">Products</h2>
                            <ul className="list">
                                <li>Lifeboats,Rescue Boats,and Davit Systems </li>
                                <li>Survival Craft Accessories</li>
                                <li>Chemicals and Welding Equipment</li>
                                <li>Marine Distress Signals</li>
                                <li>GMDSS Equipment</li>
                                <li>Marine Safety Equipment</li>
                                <li>Personnel Transfer Basket Nets</li>
                                <li>Fixed Fire Fighting Equipment and Systems</li>
                                <li>Portable Fire Fighting Equipment </li>
                                <li>Helicopter Safety Equipment</li>
                                <li>SafetyShowers,Eyewashand Medical Equipment</li>
                                <li>OEM Spares</li>
                                <li>Deck / Engine / Saloon / Electrical Ship Supplies and Provisions</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                        <div className="has-shadow p-3 h-100" style={{background: '#f9f9f9'}}>
                            <h2 className="title-h2">Services</h2>
                            <ul className="list">
                                <li>Charter Hire of FRP Boats </li>
                                <li>Lifeboats and Davit Servicing</li>
                                <li>Inflatable Life Raft Servicing</li>
                                <li>Life jackets and Immersion Suits Servicing </li>
                                <li>Fire Fighting Appliances Servicing</li>
                                <li>Pyrotechnics Disposal Facility </li>
                                <li>Communication and Navigational Equipment Servicing</li>
                                <li>Cargo Gear Testing</li>
                                <li>Fleet Service Agreements</li>
                                <li>FRP Boat Repairs</li>
                                <li>Gangway and Accommodation Ladder Testing </li>
                                <li>Calibration of Precision Instruments and Detectors </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4">
                        <div className="has-shadow p-3 h-100" style={{background: '#f9f9f9;'}}>
                            <h2 className="title-h2">Manufacture</h2>
                            <ul className="list">
                                <li>Inflatable Rescue Boats and Davit Systems</li>
                                <li>Inflatable Life Rafts</li>
                                <li>High Speed FRP Boats and Yachts</li>
                                <li>Fire Extinguishers and Gunmetal Fittings </li>
                                <li>Cargo Nets, Pilot Ladders, etc</li>
                            </ul>
                            <h2 className="title-h2 mt-2">Repairs</h2>
                            <ul className="list">
                                <li>Main & Aux. Machinery Repairs </li>
                                <li>Accommodation Refurbishment</li>
                                <li>Dynamic Balancing of Rotors,Impellers, Fans, Etc.</li>
                                <li>Electrical repairs and rewinding of alternators and Motors</li>
                                <li>Steel renewal and pipe fabrication</li>
                                <li>Dry Docking and Painting</li>
                                <li>Winch / Crane Windlass Repairs</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="row p-0 mt-5">
                    <div className="col-lg-12 col-sm-12">
                        <div className="has-shadow p-2">      
                            <img src="assets/img/our-location.jpg" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="p-0 mt-5">
                    <div className="col-lg-12 col-sm-12">
                        <div className="has-shadow p-2">
                            <h2 className="title-h2">Lifeboat Authorization</h2>
                            <div className="service__grid">
                                <div className="service__item">
                                    <a href="#"><img src={logo1} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo2} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo3} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo4} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo5} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo6} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo7} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo8} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"> <img src={logo9} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo10} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo11} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo12} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo13} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo14} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo15} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo16} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo17} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo18} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo19} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo20} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo21} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo22} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo23} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo24} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo25} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo26} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo27} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo28} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo29} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo30} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo31} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo32} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo33} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo34} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo35} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo26} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo37} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo38} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo39} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo40} alt="" /></a>
                                </div>
                                <div className="service__item" style={{width: '100%', textAlign: 'left', borderRight: '0'}}>
                                    <h2 className="title-h2">Safety</h2>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo38} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo42} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo43} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo44} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo45} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo46} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo47} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo48} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo49} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo50} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo51} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo52} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo53} alt="" /></a>
                                </div>
                                <div className="service__item">
                                    <a href="#"><img src={logo54} alt="" /></a>
                                </div>
                            </div>
                            <div className="row px-0">
                                <div className="col-lg-4 col-sm-4">
                                    <div className="service__grid pad-none">
                                        <div className="service__item border-btm" style={{width: '100%', textAlign: 'left', borderRight: '0'}}>
                                            <h2 className="title-h2">Electronics</h2>
                                        </div>
                                        <div className="service__item" style={{ width: '50%', borderBottom: '0'}}>
                                            <a href="#"><img src={logo55} alt="" /></a>
                                        </div>
                                        <div className="service__item" style={{ width: '50%', borderBottom: '0'}}>
                                            <a href="#"><img src={logo56} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <div className="service__grid pad-none">
                                        <div className="service__item border-btm" style={{width: '100%', textAlign: 'left', borderRight: '0'}}>
                                            <h2 className="title-h2">Fire Fightning</h2>
                                        </div>
                                        <div className="service__item" style={{ width: '50%', borderBottom: '0'}}>
                                            <a href="#"><img src={logo57} alt="" /></a>
                                        </div>
                                        <div className="service__item" style={{ width: '50%', borderBottom: '0'}}>
                                            <a href="#"><img src={logo58} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-4">
                                    <div className="service__grid pad-none">
                                        <div className="service__item border-btm" style={{width: '100%', textAlign: 'left', borderRight: '0'}}>
                                            <h2 className="title-h2">Marine Chemicals</h2>
                                        </div>
                                        <div className="service__item" style={{ width: '50%', borderBottom: '0'}}>
                                            <a href="#"><img src={require('../../assets/img/pdf.png')} alt="" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
    );
}

export default RescueboatEquipmentChecklist;