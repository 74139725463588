import React from "react";

const PendingViewReject = (props) => {

    const style = {
        display: props.isModalVisible ? 'block' : 'none'
      };
    
      function resetForm(event) {
        props.modalCloseHandler(event, false)
      }
    
      const onModalClose = (event) => {
        resetForm(event);
      }

    return ( 
        <React.Fragment>

<div className="modal " id="reasonModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLongTitle">Reason</h3>
        <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
          <form name="" action="">
              <div className="form-group">
                  <label>Reason</label>
                  <textarea name="" id="" className="form-control" placeholder="Reason"></textarea>
              </div>
          </form>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-primary"><i className="fa fa-save"></i>&nbsp; Save</button>
      </div>
    </div>
  </div>
</div>
        </React.Fragment>
     );
}
 
export default PendingViewReject;