import React from 'react';
import logo from '../../assets/img/logo.png';
import { Link } from "react-router-dom";

const Header = (props) => {
  return (
    <React.Fragment>
      <header className="header">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="navbar-holder d-flex align-items-center justify-content-between">
              <div className="navbar-header">
                <Link to="/" className="navbar-brand">
                  <div className="brand-text brand-big"><img src={logo} alt="" /></div>
                  <div className="brand-text brand-small"><strong>SHM</strong></div>
                </Link>
                {
          props.toShow === true ?
            (
                <Link id="toggle-btn" onClick={props.sideBarHandler} className="menu-btn active"><span></span><span></span><span></span></Link>
                )
                :
                (
                  ''
                  )
                }
              </div>
              {
          props.toShow === true ?
            (
              <ul className="nav-menu list-unstyled d-flex flex-md-row align-items-md-center">
              <li className="nav-item"><Link to="/" onClick={props.logoutHandler} className="nav-link logout">Logout<i className="fa fa-sign-out"></i></Link></li>
            </ul>
                )
                :
                (
                  ''
                  )
                }
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  )
}
export default Header;