import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const FitnessFreefailLifeboat = (props) => {



  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', frefail_type: '', frefail_serial_mo: '', frefail_manufacture: '', frefail_date_of_manufacture: '', release_type: '', release_serial_no: '', release_manufacture: '', release_date_of_manufacture: '', davit_type: '', davit_serial_no: '', davit_manufacture: '', davit_date_of_manufacture: '', winch_type: '', winch_serial_no: '', winch_manufacture: '', winch_date_of_manufacture: '', remark: '', approver_name: '', service_engineer_sign: '', approved_by: '', winch_repair: false, winch_annual_servicing: false, winch_5_yearly_servicing: false });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }
  return (
    <React.Fragment>
      <InnerHeader title="Statement Of Fitness" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="mb-3">
            <h4 className="mb-0">Statement of Fitness - Freefall Lifeboats and Davit</h4>
            <h5>Instruction LBD001 version 1.0</h5>
          </div>
          <div className="mb-3">
            This is to confirm that the undersigned licensed service engineer has carried out periodic servicing and maintenance of the below mentioned equipment in accordance with SOLAS Chapter III Regulation 20 and MSC.402 (96) and that the said equipment has been found fit for its purpose.
          </div>
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>className</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Freefall Lifeboat</h4>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Type</label>
                    <input onChange={handleChange} type="text" name="frefail_type" value={formFields['frefail_type']} className="form-control" placeholder="Type" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Serial No.</label>
                    <input onChange={handleChange} type="text" name="frefail_serial_mo" value={formFields['frefail_serial_mo']} className="form-control" placeholder="Serial No." autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input onChange={handleChange} type="text" name="frefail_manufacture" value={formFields['frefail_manufacture']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Date of Manufacture</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="frefail_date_of_manufacture" value={formFields['frefail_date_of_manufacture']} id="start" className="form-control text-left mr-2" placeholder="Date of Manufacture" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(85% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Release Gear</h4>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Type</label>
                    <input onChange={handleChange} type="text" name="release_type" value={formFields['release_type']} className="form-control" placeholder="Type" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Serial No.</label>
                    <input onChange={handleChange} type="text" name="release_serial_no" value={formFields['release_serial_no']} className="form-control" placeholder="Serial No." autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input onChange={handleChange} type="text" name="release_manufacture" value={formFields['release_manufacture']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Date of Manufacture</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="release_date_of_manufacture" value={formFields['release_date_of_manufacture']} id="start" className="form-control text-left mr-2" placeholder="Date of Manufacture" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(85% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Davit</h4>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Type</label>
                    <input onChange={handleChange} type="text" name="davit_type" value={formFields['davit_type']} className="form-control" placeholder="Type" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Serial No.</label>
                    <input onChange={handleChange} type="text" name="davit_serial_no" value={formFields['davit_serial_no']} className="form-control" placeholder="Serial No." autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input onChange={handleChange} type="text" name="davit_manufacture" value={formFields['davit_manufacture']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Date of Manufacture</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="davit_date_of_manufacture" value={formFields['davit_date_of_manufacture']} id="start" className="form-control text-left mr-2" placeholder="Date of Manufacture" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(85% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Winch</h4>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Type</label>
                    <input onChange={handleChange} type="text" name="winch_type" value={formFields['winch_type']} className="form-control" placeholder="Type" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Serial No.</label>
                    <input onChange={handleChange} type="text" name="winch_serial_no" value={formFields['winch_serial_no']} className="form-control" placeholder="Serial No." autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Manufacturer</label>
                    <input onChange={handleChange} type="text" name="winch_manufacture" value={formFields['winch_manufacture']} className="form-control" placeholder="Manufacturer" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Date of Manufacture</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="winch_date_of_manufacture" value={formFields['winch_date_of_manufacture']} id="start" className="form-control text-left mr-2" placeholder="Date of Manufacture" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(85% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                <div className="col-lg-3 col-sm-3 checkBoxWid">
                  <div className="form-group">
                    <span className="w100">Kind of Servicing</span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 checkBoxWid">
                  <div className="form-group">
                    <span className="w100"><input type="checkbox" name="winch_repair" onClick={checkboxHandleChange} checked={formFields['winch_repair']} className="mr-2" disabled={props.can_edit_form} />Repair</span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 checkBoxWid">
                  <div className="form-group">
                    <span className="w100"><input type="checkbox" name="winch_annual_servicing" onClick={checkboxHandleChange} checked={formFields['winch_annual_servicing']} className="mr-2" disabled={props.can_edit_form} />Annual Servicing</span>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3 checkBoxWid">
                  <div className="form-group">
                    <span className="w100"><input type="checkbox" name="winch_5_yearly_servicing" onClick={checkboxHandleChange} checked={formFields['winch_5_yearly_servicing']} className="mr-2" disabled={props.can_edit_form} />5-Yearly Servicing</span>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label>Remarks</label>
                  <textarea onChange={handleChange} type="text" name="remark" value={formFields['remark']} id="" className="form-control" placeholder="Remarks" style={{ left: "calc(85% - 0px)" }} autocomplete="off" disabled={props.can_edit_form}></textarea>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                {/* <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Company Address </h4>
                </div>
                <div className="col-lg-12 col-sm-12"><b>SHM SHIPCARE SDN BHD</b></div>
                <div className="col-lg-12 col-sm-12">No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                <div className="col-lg-12 col-sm-12"><b>Master/Chief Officer Endorsement</b></div>
                <div className="col-lg-12 col-sm-12"><b>Service Engineer</b></div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approver Name</label>
                    <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} className="form-control" placeholder="Name" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Sign</label>
                    <input onChange={handleChange} type="text" name="service_engineer_sign" value={formFields['service_engineer_sign']} className="form-control" placeholder="Sign" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="approved_by" value={formFields['approved_by']}>
                        <option value="" selected="selected"> Select </option>
                        <option value="1"> Select</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} disabled={props.can_edit_form} />
                </div>
              </div>
            </div>
            <div class="row px-0 border mt-4">
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div class="mb-2"><strong>Company Address </strong></div>
                <div><b>SHM SHIPCARE SDN BHD</b></div>
                <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
              </div>
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div><b>Master/Chief Officer<br />Endorsement</b></div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div><b>Service Engineer</b></div>
                <div>
                  {/* <label class="mb-0">Name</label> */}
                  <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
                </div>
                <div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default FitnessFreefailLifeboat;