import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const DrinkingWaterTest = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', technical_description_commodity: '', technical_description_sampling_site: '', technical_description_sampling_date: '', technical_description_receipt_date: '', technical_description_sampling_method: '', technical_description_purpose: '', taste_test_values: '', taste_results: '', odor_test_values: '', odor_results: '', colony_counts_test_values: '', colony_counts_results: '', total_coliforms_test_values: '', total_coliforms_results: '', fecal_coliforms_test_values: '', fecal_coliforms_results: '', flouride_test_values: '', flouride_results: '', ammonia_nitrogen_test_values: '', ammonia_nitrogen_results: '', nitrate_nitrogen_test_values: '', nitrate_nitrogen_results: '', colour_test_values: '', colour_results: '', manganese_test_values: '', manganese_results: '', turbidity_test_values: '', turbidity_results: '', aluminum_test_values: '', aluminum_results: '', residual_chlorine_test_values: '', residual_chlorine_results: '', sediments_test_values: '', sediments_results: '', arsenic_test_values: '', arsenic_results: '', barium_test_values: '', barium_results: '', boron_test_values: '', boron_results: '', test_result_fit_drinking: false, test_result_unit_drinking: false, exceed_criteria: '', service_engineer_name: '', service_engineer_sign: '' });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }


  return (
    <React.Fragment>
      <InnerHeader title="Drinking Water Test" />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid">
          <form name="" action="">
            <div class="bg-white has-shadow">
              <div class="row px-0 pb-0">
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} class="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} class="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} class="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} class="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} class="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} class="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Class</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Flag</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} class="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span class="fa fa-calendar left-side"></span> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '70%' }} />
                    </colgroup>
                    <tr>
                      <th colspan="2" class="text-center">TECHNICAL DESCRIPTION OF SAMPLE</th>
                    </tr>
                    <tr>
                      <td>Commodity</td>
                      <td><input onChange={handleChange} type="text" name="technical_description_commodity" value={formFields['technical_description_commodity']} class="form-control" placeholder="Commodity" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Sampling Site</td>
                      <td><input onChange={handleChange} type="text" name="technical_description_sampling_site" value={formFields['technical_description_sampling_site']} class="form-control" placeholder="Sampling Site" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Sampling Date</td>
                      <td><input onChange={handleChange} type="date" name="technical_description_sampling_date" value={formFields['technical_description_sampling_date']} class="form-control pointer-cursor-class" placeholder="Sampling Date" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Date Of Receipt</td>
                      <td><input onChange={handleChange} type="date" name="technical_description_receipt_date" value={formFields['technical_description_receipt_date']} class="form-control pointer-cursor-class" placeholder="Date Of Receipt" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Sampling Method</td>
                      <td><input onChange={handleChange} type="text" name="technical_description_sampling_method" value={formFields['technical_description_sampling_method']} class="form-control" placeholder="Sampling Method" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                    <tr>
                      <td>Purpose</td>
                      <td><input onChange={handleChange} type="text" name="technical_description_purpose" value={formFields['technical_description_purpose']} class="form-control" placeholder="Purpose" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                  </table>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '25%' }} />
                      <col style={{ width: '25%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="4" class="text-center">TESTS PERFORMED</th>
                      </tr>
                      <tr>
                        <th class="text-left">Type of Tests</th>
                        <th class="text-center">Test Values</th>
                        <th class="text-center">Acceptance Criteria</th>
                        <th class="text-center">Results</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Taste</td>
                        <td><input onChange={handleChange} type="text" name="taste_test_values" value={formFields['taste_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Not Detected</td>
                        <td><input onChange={handleChange} type="text" name="taste_results" value={formFields['taste_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Odor</td>
                        <td><input onChange={handleChange} type="text" name="odor_test_values" value={formFields['odor_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Not Detected</td>
                        <td><input onChange={handleChange} type="text" name="odor_results" value={formFields['odor_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Total Colony Counts</td>
                        <td><input onChange={handleChange} type="text" name="colony_counts_test_values" value={formFields['colony_counts_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 100/ ml</td>
                        <td><input onChange={handleChange} type="text" name="colony_counts_results" value={formFields['colony_counts_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Total Coliforms</td>
                        <td><input onChange={handleChange} type="text" name="total_coliforms_test_values" value={formFields['total_coliforms_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Not Detected / 100 ml</td>
                        <td><input onChange={handleChange} type="text" name="total_coliforms_results" value={formFields['total_coliforms_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>E.coli/Fecal Coliforms</td>
                        <td><input onChange={handleChange} type="text" name="fecal_coliforms_test_values" value={formFields['fecal_coliforms_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Not Detected / 100 ml</td>
                        <td><input onChange={handleChange} type="text" name="fecal_coliforms_results" value={formFields['fecal_coliforms_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Flouride</td>
                        <td><input onChange={handleChange} type="text" name="flouride_test_values" value={formFields['flouride_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 1.5mg/L</td>
                        <td><input onChange={handleChange} type="text" name="flouride_results" value={formFields['flouride_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Ammonia Nitrogen</td>
                        <td><input onChange={handleChange} type="text" name="ammonia_nitrogen_test_values" value={formFields['ammonia_nitrogen_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 0.5mg/L</td>
                        <td><input onChange={handleChange} type="text" name="ammonia_nitrogen_results" value={formFields['ammonia_nitrogen_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Nitrate Nitrogen</td>
                        <td><input onChange={handleChange} type="text" name="nitrate_nitrogen_test_values" value={formFields['nitrate_nitrogen_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 10mg/L</td>
                        <td><input onChange={handleChange} type="text" name="nitrate_nitrogen_results" value={formFields['nitrate_nitrogen_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Colour</td>
                        <td><input onChange={handleChange} type="text" name="colour_test_values" value={formFields['colour_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below  5 units</td>
                        <td><input onChange={handleChange} type="text" name="colour_results" value={formFields['colour_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Manganese</td>
                        <td><input onChange={handleChange} type="text" name="manganese_test_values" value={formFields['manganese_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 0.3mg/L</td>
                        <td><input onChange={handleChange} type="text" name="manganese_results" value={formFields['manganese_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Turbidity</td>
                        <td><input onChange={handleChange} type="text" name="turbidity_test_values" value={formFields['turbidity_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 1 NTU</td>
                        <td><input onChange={handleChange} type="text" name="turbidity_results" value={formFields['turbidity_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Aluminum</td>
                        <td><input onChange={handleChange} type="text" name="aluminum_test_values" value={formFields['aluminum_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 0.2mg/L</td>
                        <td><input onChange={handleChange} type="text" name="aluminum_results" value={formFields['aluminum_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Residual Chlorine</td>
                        <td><input onChange={handleChange} type="text" name="residual_chlorine_test_values" value={formFields['residual_chlorine_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 4mg/L</td>
                        <td><input onChange={handleChange} type="text" name="residual_chlorine_results" value={formFields['residual_chlorine_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Sediments % V/v</td>
                        <td><input onChange={handleChange} type="text" name="sediments_test_values" value={formFields['sediments_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Less then 0.25%</td>
                        <td><input onChange={handleChange} type="text" name="sediments_results" value={formFields['sediments_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Arsenic</td>
                        <td><input onChange={handleChange} type="text" name="arsenic_test_values" value={formFields['arsenic_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 10μg/L</td>
                        <td><input onChange={handleChange} type="text" name="arsenic_results" value={formFields['arsenic_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Barium</td>
                        <td><input onChange={handleChange} type="text" name="barium_test_values" value={formFields['barium_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 10μg/L</td>
                        <td><input onChange={handleChange} type="text" name="barium_results" value={formFields['barium_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Boron</td>
                        <td><input onChange={handleChange} type="text" name="boron_test_values" value={formFields['boron_test_values']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td class="text-center">Below 2400μg/L</td>
                        <td><input onChange={handleChange} type="text" name="boron_results" value={formFields['boron_results']} class="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '30%' }} />
                      <col style={{ width: '70%' }} />
                    </colgroup>
                    <tr>
                      <td>Test Result</td>
                      <td>
                        <input type="checkbox" name="test_result_fit_drinking" onClick={checkboxHandleChange} checked={formFields['test_result_fit_drinking']} class="mr-2" autocomplete="off" disabled={props.can_edit_form} />Fit for Drinking
                        <input type="checkbox" name="test_result_unit_drinking" onClick={checkboxHandleChange} checked={formFields['test_result_unit_drinking']} class="mr-2" autocomplete="off" disabled={props.can_edit_form} />Unfit for Drinking
                      </td>
                    </tr>
                    <tr>
                      <td>Item of Exceed Criteria (if unfit)</td>
                      <td><input onChange={handleChange} type="text" name="exceed_criteria" value={formFields['exceed_criteria']} class="form-control" placeholder="Sampling Site" autocomplete="off" disabled={props.can_edit_form} /></td>
                    </tr>
                  </table>
                </div>
                <div class="col-lg-12 col-sm-12">Test are conducted in accordance with Guidelines for Drinking-water Quality as laid down by WHO and accepted by ILO. </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default DrinkingWaterTest;