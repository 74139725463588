import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const VECSCalibration = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', type: '', make: '', model: '', serial_no: '', last_cal_date: '', next_due_date: '', location: '', description_of_inspection_tests: '', test_pressure_1: '', test_pressure_2: '', test_pressure_3: '', test_pressure_4: '', test_pressure_5: '', indicated_pressure_1: '', indicated_pressure_2: '', indicated_pressure_3: '', indicated_pressure_4: '', indicated_pressure_5: '', deviation_1: '', deviation_2: '', deviation_3: '', deviation_3: '', deviation_4: '', deviation_5: '', ok1: false, ok2: false, offset1: false, offset2: false, test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '', calibration_results: [] });
  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="VEC-System Calibration" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" onChange={handleChange} id="start" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mt-1">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>Equipment</td>
                        <td><input type="text" name="equipment" onChange={handleChange} value={formFields['equipment']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial Number</td>
                        <td><input type="text" name="serial_no" onChange={handleChange} value={formFields['serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td><input type="text" name="type" onChange={handleChange} value={formFields['type']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Last Cal Date</td>
                        <td><input type="date" name="last_cal_date" onChange={handleChange} value={formFields['last_cal_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td><input type="text" name="make" onChange={handleChange} value={formFields['make']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Next Due Date</td>
                        <td><input type="date" name="next_due_date" onChange={handleChange} value={formFields['next_due_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td><input type="text" name="model" onChange={handleChange} value={formFields['model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Location</td>
                        <td><input type="text" name="location" onChange={handleChange} value={formFields['location']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input type="text" onChange={handleChange} name="description_of_inspection_tests" value={formFields['description_of_inspection_tests']} placeholder="This instrument was calibrated using the standard inspection procedures and thequality is fully conformed with the specification’sstandards." className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Calibration Results</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '60%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="5" className="text-center">VAPOUR PRESSUREAT VOCON VALVE</th>
                      </tr>
                      <tr>
                        <th className="text-center">TEST PRESSURE(BAR)</th>
                        <th className="text-center">INDICATED PRESSURE(BAR)</th>
                        <th className="text-center">DEVIATION (BAR)</th>
                        <th className="text-center">HIGH ALARM</th>
                        <th className="text-center">LOW ALARM</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="text" name="test_pressure_1" onChange={handleChange} value={formFields['test_pressure_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input type="text" name="indicated_pressure_1" onChange={handleChange} value={formFields['indicated_pressure_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td><input type="text" name="deviation_1" onChange={handleChange} value={formFields['deviation_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>SET PR: 0.126 Bar</td>
                        <td>SET PR: 0.011 Bar </td>
                      </tr>
                      <tr>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="test_pressure_2" onChange={handleChange} value={formFields['test_pressure_2']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="test_pressure_3" onChange={handleChange} value={formFields['test_pressure_3']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="indicated_pressure_2" onChange={handleChange} value={formFields['indicated_pressure_2']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="indicated_pressure_3" onChange={handleChange} value={formFields['indicated_pressure_3']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="deviation_2" onChange={handleChange} value={formFields['deviation_2']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="deviation_3" onChange={handleChange} value={formFields['deviation_3']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td><input type="checkbox" name="ok1" onChange={checkboxHandleChange} checked={formFields['ok1']} disabled={props.can_edit_form} />&nbsp; OK</td>
                        <td><input type="checkbox" name="ok2" onChange={checkboxHandleChange} checked={formFields['ok2']} disabled={props.can_edit_form} />&nbsp; OK</td>
                      </tr>
                      <tr>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="test_pressure_4" onChange={handleChange} value={formFields['test_pressure_4']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="test_pressure_5" onChange={handleChange} value={formFields['test_pressure_5']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="indicated_pressure_4" onChange={handleChange} value={formFields['indicated_pressure_4']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="indicated_pressure_5" onChange={handleChange} value={formFields['indicated_pressure_5']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td className="pd-0">
                          <table width="100%" cellspacing="0" cellpadding="0">
                            <tr>
                              <td className="pd-0"><input type="text" name="deviation_4" onChange={handleChange} value={formFields['deviation_4']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                            <tr>
                              <td className="pd-0"><input type="text" name="deviation_5" onChange={handleChange} value={formFields['deviation_5']} className="form-control" style={{ height: '30px' }} autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          </table>
                        </td>
                        <td><input type="checkbox" name="offset1" onChange={checkboxHandleChange} checked={formFields['offset1']} className="" disabled={props.can_edit_form} />&nbsp; OFFSET</td>
                        <td><input type="checkbox" name="offset2" onChange={checkboxHandleChange} checked={formFields['offset2']} className="" disabled={props.can_edit_form} />&nbsp; OFFSET</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Test Result</label>
                  <textarea onChange={handleChange} name="test_result" value={formFields['test_result']} placeholder="This is to certify that the vapour emission control unit, comprising the equipment listed above, has been examined and tested in accordance with the requirements of the specified guidelines contained in IMO." className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea name="tracebility" onChange={handleChange} value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea name="remarks" onChange={handleChange} value={formFields['remarks']} placeholder="Remarks" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>

          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
export default VECSCalibration;