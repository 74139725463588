import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const EebdAirCylinder = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', list_1: [], list_2: [] });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no =formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }


  const checkboxHandleChange = (event, index) => {
    const { name, checked } = event.target;
    const _formFields = { ...formFields };
    _formFields.list_1[index][name] = checked;
    setFormFields(_formFields);
  }
  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.list_1[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.list_1.push({ no_1: '', set_no_1: '', maker_1: '', type_1: '', Air_capacity_ltr_1: '', face_hood_1: false, breathing_value_1: false, reguler_unit_hoses_1: false, bag_1: false, function_test_1: false });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.fire_extinguisher_list.splice(index, 1);
    setFormFields(values);
  }

  const handleChangeRows1 = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.list_2[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow1 = () => {
    const _formFields = { ...formFields };
    _formFields.list_2.push({ sec_no_1: '', type_1: '', serial_1: '', capacity_1: '', working_pr_1: '', test_pr_1: '', last_test_date_1: '', tare_weight_1: '', gross_weight_1: '', code_1: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields1 = (event, index) => {
    const values = { ...formFields };
    values.fire_extinguisher_list.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="EEBD Device" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Working Codes</label>
                    <div className="row p-0">
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>Inspection</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Hydro Pressure test</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Refilling</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Pr. Check</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>5</td>
                            <td>Weight Check</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Level Check</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Repair</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>New Supply</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Set No.</th>
                        <th>Maker</th>
                        <th>Type</th>
                        <th>Air Capacity (Ltrs)</th>
                        <th>Face Hood</th>
                        <th>Breathing Valve</th>
                        <th>Regulator Unit & Hoses</th>
                        <th>Bag</th>
                        <th>Function Test</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.list_1.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows(event, 'no_1', index)} type="text" name="no_1" value={item['no_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'set_no_1', index)} type="text" name="set_no_1" value={item['set_no_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'maker_1', index)} type="text" name="maker_1" value={item['maker_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'type_1', index)} type="text" name="type_1" value={item['type_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'Air_capacity_ltr_1', index)} type="text" name="Air_capacity_ltr_1" value={item['Air_capacity_ltr_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="face_hood_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['face_hood_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="breathing_value_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['breathing_value_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="reguler_unit_hoses_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['reguler_unit_hoses_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="bag_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['bag_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="function_test_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['function_test_1']} disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type</th>
                        <th>Serial</th>
                        <th>Capacity (l)</th>
                        <th>Working Pr</th>
                        <th>Test Pr</th>
                        <th>Last Test Date</th>
                        <th>Tare Wt</th>
                        <th>Gross Wt</th>
                        <th>Code</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.list_2.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows1(event, 'sec_no_1', index)} type="text" name="sec_no_1" value={item['sec_no_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'type_1', index)} type="text" name="type_1" value={item['type_1']} className="form-control" placeholder="CO2" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'serial_1', index)} type="text" name="serial_1" value={item['serial_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'capacity_1', index)} type="text" name="capacity_1" value={item['capacity_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'working_pr_1', index)} type="text" name="working_pr_1" value={item['working_pr_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'test_pr_1', index)} type="text" name="test_pr_1" value={item['test_pr_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'last_test_date_1', index)} type="date" name="last_test_date_1" value={item['last_test_date_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'tare_weight_1', index)} type="text" name="tare_weight_1" value={item['tare_weight_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'gross_weight_1', index)} type="text" name="gross_weight_1" value={item['gross_weight_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows1(event, 'code_1', index)} type="text" name="code_1" value={item['code_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields1(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow1()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default EebdAirCylinder;