import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getCertificateBlankObject , getAPIHeaders } from "../../../helpers/functions";
import config from '../../../config.json';
import { useParams } from 'react-router';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const CertificateOfInseption = (props) => {
 
  const [certificateFields, setCertificateFields] = useState(getCertificateBlankObject());
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  const [classField, setClassfield] = useState([]);
  const [Flags, SetFlags] = useState([]);
  const [users, setUsers] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...certificateFields, ...props.formFields };
      setCertificateFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
    loadCompanyUsers();
  }, [props.formFields]);

  
  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const certificateFieldsObject = {
      ...certificateFields,
      [name]: value
    }
    setCertificateFields(certificateFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: certificateFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      setClassfield(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }


  function loadCompanyUsers() {
    axios.get(config.BaseURL + '/v1/company-users?all=true', { headers: getAPIHeaders() }).then((response) => {
      setUsers(response.data);
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  return (
    <React.Fragment>
      <InnerHeader title="Certificate Of Inspection" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Certificate Number / Job Id</label>
                    <input onChange={handleChange} type="text" name="certificate_no" value={certificateFields['certificate_no']} disabled={props.can_edit_form} className="form-control" placeholder="Certificate Number / Job Id" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={certificateFields['customer_name']} disabled={props.can_edit_form} className="form-control" placeholder="Customer Name" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Email Id</label>
                    <input onChange={handleChange} type="text" name="customer_email" value={certificateFields['customer_email']} disabled={props.can_edit_form} className="form-control" placeholder="Customer Email Id" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port Of Service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={certificateFields['service_port']} disabled={props.can_edit_form} className="form-control" placeholder="Port Of Service" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date Of Service</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="service_date" value={certificateFields['service_date']} disabled={props.can_edit_form} id="start" className="form-control text-left mr-2" placeholder="From Date" readOnly />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={certificateFields['job_report_date']} disabled={props.can_edit_form} id="start" className="form-control text-left mr-2" placeholder="DD/MM/YYYY" readOnly />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name"  value={certificateFields['vessel_name']} disabled={props.can_edit_form} className="form-control" placeholder="Vessel Name" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={certificateFields['imo_number']} disabled={props.can_edit_form} className="form-control" placeholder="IMO Number" readOnly />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select  name="class_id" value={certificateFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          classField.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={certificateFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Email ID</label>
                    <input onChange={handleChange} type="text" name="vessel_email" value={certificateFields['vessel_email']} disabled={props.can_edit_form} className="form-control" placeholder="Vessel Email ID" readOnly />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                {/* <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className="styled-select">
                      <select id="" name="approved_by" value={certificateFields['approved_by']} onChange={handleChange}>
                        <option value="">Select</option>
                        {
                          users.map((user, index) => {
                            return (
                              <option key={index} value={user.id}>{user.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Verification QR</label>
                    <input onChange={handleChange} type="text" name="verification_qr" value={certificateFields['verification_qr']} disabled={props.can_edit_form} className="form-control" placeholder="Verification QR" />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler}  job_id={job_id} disabled={props.can_edit_form} disabled={props.can_edit_form} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

    </React.Fragment>
  );
}

export default CertificateOfInseption;