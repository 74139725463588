import React from "react";
import InnerHeader from '../Shared/InnerHeader';

const New = () => {

  return (
    <React.Fragment>
      <InnerHeader title="New Job" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>I JO/Ref No</label>
                    <input type="text" name="" value="" className="form-control" placeholder="IJO/Ref No" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Certificate Number / Job ID Customer Name</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Certificate Number / Job Id Customer Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Customer Pic" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Email ID</label>
                    <input type="email" name="" value="" className="form-control" placeholder="Customer Email Id" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port Of Service</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Port of service" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date Of Service</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Date of service" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Vessel Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="text" name="" value="" className="form-control" placeholder="IMO number" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>className</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> className </option>
                        <option value="1"> className</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Flag </option>
                        <option value="1"> Flag</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Email ID</label>
                    <input type="email" name="" value="" className="form-control" placeholder="Vessel Email Id" />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12"><hr /></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Assigned To</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Assigned To </option>
                        <option value="1"> Assigned To </option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Reviewed By</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Select </option>
                        <option value="1"> Select </option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Select </option>
                        <option value="1"> Select</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-right">
                  <a className="continue-btn" onClick="nextForm();"> Continue &nbsp;<i className="fa fa-arrow-right"></i></a>
                </div>
              </div>
              <div id="saveForm" className="row px-0" style={{ display: "none" }}>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="md-font">Select Form</div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>

                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">

                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Document Category </option>
                        <option value="1"> Category </option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">

                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Document Type </option>
                        <option value="1"> Type</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-2">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Select</th>
                        <th>Form Title</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input type="checkbox" name="" value="" /></td>
                        <td>Certificate of inspection</td>
                      </tr>
                      <tr>
                        <td><input type="checkbox" name="" value="" /></td>
                        <td>Form Applicator</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 text-right">
                  <a href="manage-jobs.php" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save</a>
                </div>
              </div>

            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default New;