import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import config from '../../../config.json';
import LoadingBar from '../../Shared/LoadingBar';
import AddEditJob from './AddEditForm';
import Pagination from '../../Shared/Pagination';
import { getEditableJobObject, getJobBlankObject } from '../../../helpers/functions';
import { useToasts } from 'react-toast-notifications';


const JobTemplates = (props) => {

  const [jobFields, setJobFields] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalObject, setModalObject] = useState({ modalVisible: false });
  const [editableObject, setEditableObject] = useState(getJobBlankObject());
  const { addToast } = useToasts();
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  let url = config.BaseURL + "/v1/companies/" + props.companyId + "/job-fields";

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchJobTemplate();
  }

  const searchJobTemplate = () => {
    setSearchTerm(searchtermRef.current.value);
  }

  function fetchJobTemplates(url) {
    setIsLoading(true);
    axios.get(url, { headers: headers, params: { squery: searchTerm } }).then(function (response) {
      setJobFields(response.data.data);
      setPagination(response.data.links);
      setIsLoading(false);
    }).catch(function (error) {
      setIsLoading(false);
    });
  }
  
  const removeData = (event, id) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(config.BaseURL + "/v1/companies/" + props.companyId + "/job-fields/" + id, { headers: headers }).then(function (response) {
        addToast("Template deleted successfully!", { appearance: 'success', autoDismiss: true });
        fetchJobTemplates(url);
      });
    }
  }

  useEffect(() => {
    fetchJobTemplates(url);
  }, [searchTerm]);

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setEditableObject(getJobBlankObject());
    setModalObject({
      modalVisible: modalVisible
    });
  }

  const editJobFieldHandler = (event, object) => {
    event.preventDefault();
    setEditableObject(getEditableJobObject(object));
    setModalObject({
      modalVisible: true
    });
  }

  return (
    <React.Fragment>
      { isLoading ? <LoadingBar /> : null}
      { ReactDOM.createPortal(<AddEditJob refreshList={() => fetchJobTemplates(url)} editableObject={editableObject} companyId={props.companyId} isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}
      <div className="col-lg-12 col-sm-12 px-0 mt-4">
        <div className="row px-0 m-l-r py-0">
          <div className="col-lg-4 col-sm-4 btmSpace">
            <form className="w-100">
              <div className="input-group border rounded-pill">
                <input onKeyDown={handleKeyDown} type="search" ref={searchtermRef} placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                <div className="input-group-append border-0">
                  <button id="button-addon3" onClick={searchJobTemplate} type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-lg-8 col-sm-8 text-right">
            <a onClick={(event) => modalHandler(event, true)} href="#jobtempaddModal" data-toggle="modal" className="news-job-btn"><i className="fa fa-plus"></i>&nbsp; Add</a>
          </div>
          <div className="col-lg-12 col-sm-12 mt-3">
            <table className="table table-responsive table-striped table-hover mb-0">
              <thead>
                <tr>
                  <th>No. <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                  <th>Field Name <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                  <th>Type <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                  <th>Sequence <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  jobFields.map((object, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{object.field_name}</td>
                        <td>{object.field_type}</td>
                        <td>{object.sequence}</td>
                        <td>
                          <a href="#" onClick={(event) => editJobFieldHandler(event, object)} className="download-btn bg-text-color-2a78e4-fff"><i className="fa fa-edit"></i></a>&nbsp;
                          <a href="#" onClick={(event) => removeData(event, object.id)} className="share-alt bg-color-cf2a2d"><i className="fa fa-trash"></i></a>
                        </td>
                      </tr>
                    )
                  })
                }
                { jobFields.length === 0 ? <tr><td colSpan='5' className='text-center'>No Record Found</td></tr> : null }
              </tbody>
            </table>
          </div>
        </div>
        <div className="page-bottom-pagination text-right mt-4">
          <nav aria-label="Page navigation example" className="d-inline-block">
            <Pagination links={pagination} onPageClick={fetchJobTemplates} />
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

export default JobTemplates;