import React, { useState, useEffect, useRef } from 'react';
import AddEditFields from '../Forms/AddEditFields';
import ReactDOM from 'react-dom';
import { getAPIHeaders, getEditableJobObject, getJobBlankObject } from '../../../helpers/functions';
import axios from 'axios';
import config from '../../../config.json';
import Pagination from '../../Shared/Pagination';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

const Fields = () => {

  const [jobFields, setJobFields] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [modalObject, setModalObject] = useState({ modalVisible: false });
  const [editableObject, setEditableObject] = useState(getJobBlankObject());
  const { company_id, form_id } = useParams();
  const { addToast } = useToasts();
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  let url = config.BaseURL + "/v1/companies/" + company_id + "/forms/" + form_id + "/form-fields";

  function fetchRecords(url) {
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm } }).then(function (response) {
      setJobFields(response.data.data);
      setPagination(response.data.links);
    }).catch(function (error) {
    });
  }

  const removeData = (event, id) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
    axios.delete(url + "/" + id, { headers: getAPIHeaders() }).then(function (response) {
      addToast("Field Deleted Successfully!", { appearance: 'success', autoDismiss: true });
      fetchRecords(url);
    });
  }
  }

  useEffect(() => {
    fetchRecords(url);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchField();
  }

  const searchField = () => {
    setSearchTerm(searchtermRef.current.value);
   }

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setEditableObject(getJobBlankObject());
    setModalObject({
      modalVisible: modalVisible
    });
  }

  const editJobFieldHandler = (event, object) => {
    event.preventDefault();
    setEditableObject(getEditableJobObject(object));
    setModalObject({
      modalVisible: true
    });
  }

  return (
    <React.Fragment>
      { ReactDOM.createPortal(<AddEditFields refreshList={() => fetchRecords(url)} companyId={company_id} formId={form_id} isModalVisible={modalObject.modalVisible} editableObject={editableObject} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-4 col-sm-4 btmSpace px-0">
              <form className="w-100">
                <div className="input-group border rounded-pill">
                  <input onKeyDown={handleKeyDown} type="search" ref={searchtermRef} placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                  <div className="input-group-append border-0">
                    <button id="button-addon3" onClick={searchField} type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-8 col-sm-8 text-right px-0">
              <a onClick={(event) => modalHandler(event, true)} href="#jobtempaddModal" data-toggle="modal" className="news-job-btn"><i className="fa fa-plus"></i>&nbsp; Add</a>
            </div>
            <div className="col-lg-12 col-sm-12 px-0">
              <form name="" action="">
                <div className="fieldset-form">
                  <div className="row p-0">
                    <div className="col-lg-12 col-sm-12 my-3 px-0">
                      <table className="table table-responsive table-striped table-hover mb-0">
                        <thead>
                          <tr>
                            <th>No. <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                            <th>Field Name <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                            <th>Type <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            jobFields.map((object, index) => {
                              return (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{object.field_name}</td>
                                  <td>{object.field_type}</td>
                                  <td>
                                    <a href="#" onClick={(event) => editJobFieldHandler(event, object)} className="download-btn bg-text-color-2a78e4-fff"><i className="fa fa-edit"></i></a>&nbsp;
                                    <a href="#" onClick={(event) => removeData(event, object.id)} className="share-alt bg-color-cf2a2d"><i className="fa fa-trash"></i></a>
                                  </td>
                                </tr>
                              )
                            })
                          }
                          { jobFields.length === 0 ? <tr><td colSpan='4' className='text-center'>No Record Found</td></tr> : null }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="page-bottom-pagination text-right mt-4">
              <nav aria-label="Page navigation example" className="d-inline-block">
                <Pagination links={pagination} onPageClick={fetchRecords} />
              </nav>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Fields;