import React, { useEffect, useState } from 'react';
import config from '../../config.json';
import axios from "axios";
import logo from '../../assets/img/logo.png';

const ResetPassword = (props) => {

  const [formFields, setFormFields] = useState({ forgot_password_token: '', password: '', password_confirmation: '' });
  const [validationError, setValidationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  useEffect(() => {
    const formFieldsObject = {
      ...formFields,
      forgot_password_token: props.match.params.token
    }
    setFormFields(formFieldsObject);
  }, []);

  const submitHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/reset-password", formFields).then(function (response) {
      setSuccessMessage(response.data.message);
      setTimeout(() => {
        props.history.push("/login");
      }, 4000);
    }).catch(function (error) {
      setValidationError(error.response.data.errors[0]);
    });
  }

  return (
    <React.Fragment>
      <div className="page login-page">
        <div className="container d-flex align-items-center">
          <div className="form-holder has-shadow">
            <div className="row">
              <div className="col-lg-6">
                <div className="info d-flex align-items-center">
                  <div className="content">
                    <div className="logo text-center">
                      <img className="img-fluid" src={logo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 bg-white">
                <div className="form d-flex align-items-center">
                  <div className="content">
                    <h1>Reset Password</h1>
                    {
                      validationError &&
                      <div className="alert alert-danger" role="alert">
                        {validationError}
                      </div>
                    }
                    {
                      successMessage &&
                      <div className="alert alert-success" role="alert">
                        {successMessage}
                      </div>
                    }
                    <form id="login-form" method="post" >
                      <div className="form-group">
                        <input autoComplete="pass_new" placeholder="Password" onChange={handleChange} value={formFields['password']} type="password" name="password" className="input-material"  />
                      </div>
                      <div className="form-group">
                        <input placeholder="Confirm Password" onChange={handleChange} value={formFields['password_confirmation']} type="password" name="password_confirmation" className="input-material" autoComplete="pass_new"  />
                      </div>
                      <a id="login" onClick={submitHandler} href="/" className="btn btn-primary"><i className="fa fa-sign-in"></i>&nbsp; Continue</a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
export default ResetPassword;