import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";


const LifeJacketsFoamType = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', custom_list: [], custom_list_1: [] });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no =formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.custom_list[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addMakeRow = () => {
    const _formFields = { ...formFields };
    _formFields.custom_list.push({ make_type_1: '', serial_no_1: '', manufacture_date_1: '', light_expiry_date_1: '', buoyancy_chember_1: '', working_code_1: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.custom_list.splice(index, 1);
    setFormFields(values);
  }

  const handleChangeRows1 = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.custom_list_1[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const checkboxHandleChange = (event, index) => {
    const { name, checked } = event.target;
    const _formFields = { ...formFields };
    _formFields.custom_list_1[index][name] = checked;
    setFormFields(_formFields);
  }

  const addMakeRow1 = () => {
    const _formFields = { ...formFields };
    _formFields.custom_list_1.push({ op_heads_pill_pin_1: false, bobbins_1: false, co2_catridge_33_grm_1: false, light_1: false, reflective_tape_1: false, whistel_1: false, velcro_1: false, interior_exterior_of_material_1: false, self_inflatable_tube_1: false, straps_hook_clip_1: false, comments_1: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields1 = (event, index) => {
    const values = { ...formFields };
    values.custom_list_1.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  return (
    <React.Fragment>
      <InnerHeader title="Foam Type Lifejackets" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form}/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}> 
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Working Codes</label>
                    <div className="row p-0">
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>External maintenance  and Inspection  </td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Air Pressure Test, APT </td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Repair</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>4</td>
                            <td>Parts replacement</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>New Supply</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Condemned</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Make & Type</th>
                        <th>Serial No</th>
                        <th>Manufacture Date</th>
                        <th>Light Expiry Date</th>
                        <th>Buoyancy chamber (Single/Double)</th>
                        <th>Working Code</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.custom_list.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows(event, 'no_1', index)} type="text" name="no_1" value={item['no_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'make_type_1', index)} type="text" name="make_type_1" value={item['make_type_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'serial_no_1', index)} type="text" name="serial_no_1" value={item['serial_no_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'manufacture_date_1', index)} type="date" name="manufacture_date_1" value={item['manufacture_date_1']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'light_expiry_date_1', index)} type="date" name="light_expiry_date_1" value={item['light_expiry_date_1']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'buoyancy_chember_1', index)} type="text" name="buoyancy_chember_1" value={item['buoyancy_chember_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_code_1', index)} type="text" name="working_code_1" value={item['working_code_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        {/*<th>Op Heads Pill Pin</th>
                        <th>Bobbin</th>
                        <th>Co2 Catridge 33 Grm</th>*/}
                        <th>Light</th>
                        <th>Reflective Tape</th>
                        <th>Whistle</th>
                        <th>Velcro</th>
                        <th>Interior& Exterior Condition</th>
                        <th>Self Inflation Tube</th>
                        <th>Straps & Hook Clip</th>
                        <th>Comments</th>
                        <th>&nbsp; </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.custom_list_1.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              {/*<td className="text-center"><input type="checkbox" name="op_heads_pill_pin_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['op_heads_pill_pin_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="bobbins_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['bobbins_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="co2_catridge_33_grm_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['co2_catridge_33_grm_1']} autocomplete="off" disabled={props.can_edit_form}/></td>*/}
                              <td className="text-center"><input type="checkbox" name="light_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['light_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="reflective_tape_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['reflective_tape_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="whistel_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['whistel_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="velcro_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['velcro_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="interior_exterior_of_material_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['interior_exterior_of_material_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="self_inflatable_tube_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['self_inflatable_tube_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="checkbox" name="straps_hook_clip_1" onClick={(event) => checkboxHandleChange(event, index)} checked={item['straps_hook_clip_1']} autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td className="text-center"><input type="text" onChange={(event) => handleChangeRows1(event, 'comments_1', index)} name="comments_1" value={item['comments_1']} className="form-control" autocomplete="off" disabled={props.can_edit_form}/></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields1(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow1()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div className="row px-0 border my-4">
                        <div className="col-lg-4 col-sm-4 brd-rht">
                            <div className="mb-2"><strong>Company Address </strong></div>
                            <div><b>SHM SHIPCARE SDN BHD</b></div>
                            <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                        </div>
                        <div className="col-lg-4 col-sm-4 brd-rht">
                            <div><b>Master/Chief Officer<br />Endorsement</b></div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div><b>Service Engineer</b></div>
                            <div>
                                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true}/>
                            </div>
                            <div>
                           </div>
                        </div>
                    </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default LifeJacketsFoamType;