import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import config from '../../../config.json';
import axios from "axios";
import { getAPIHeaders, getCompanyUserBlankObject } from '../../../helpers/functions';
import Pagination from '../../Shared/Pagination';
import EditCompanyUser from './EditCompanyUser';
import { useToasts } from 'react-toast-notifications';

const CompanyUsers = (props) => {
  
  const [modalObject, setModalObject] = useState({ modalVisible: false, editableObject: {} });
  const [companyUsers, setCompanyUsers] = useState([]);
  const [pagination, setPagination] = useState([]);
  const searchTermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { addToast } = useToasts();
  
  let url = config.BaseURL + "/v1/companies/" + props.companyId + "/users";
  
  const handleKeyDown = (event) => {
    if (event.key === "Enter") searchUsers();
  }
  
  const searchUsers = () => {
    setSearchTerm(searchTermRef.current.value);
  }
  
  useEffect(() => {
    loadCompanyUsers(url);
  }, [searchTerm]);
  
  function loadCompanyUsers(url) {
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm } }).then(function (response) {
      setCompanyUsers(response.data.data);
      setPagination(response.data.links);
    });
  }
  
  function updateCompanyUser(event, userId, action) {
    event.preventDefault();
    
    // companyUser.role_id = 1;
    axios.put(url + "/" + userId + '/update', { status: action}, { headers: getAPIHeaders() }).then(function (response) {
      if (response.data.user_status == 'active') {
        addToast("Company User is Active", { appearance: 'success', autoDismiss: true });
      } else {
        addToast("Company User is In-Active", { appearance: 'error', autoDismiss: true });
      }
      loadCompanyUsers(url);
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  
  function deleteUser(event, companyUser) {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(url + "/" + companyUser.id, { headers: getAPIHeaders() }).then(function (response) {
        loadCompanyUsers(url);
      });
    }
  }
  
  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible,
      editableObject: getCompanyUserBlankObject()
    });
  }
  
  const editCompanyUserHandler = (event, object) => {
    event.preventDefault();
    setModalObject({
      modalVisible: true,
      editableObject: object
    });
  }
  
  return (
    <React.Fragment>
    {ReactDOM.createPortal(<EditCompanyUser editableObject={modalObject.editableObject} isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} refreshList={() => loadCompanyUsers(url)} />, document.getElementById('modal-root'))}
    <div className="col-lg-12 col-sm-12 px-0 mt-4">
    <div className="row px-0 m-l-r py-0">
    <div className="col-lg-4 col-sm-4 btmSpace">
    <form className="w-100">
    <div className="input-group border rounded-pill">
    <input onKeyDown={handleKeyDown} ref={searchTermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
    <div className="input-group-append border-0">
    <button onClick={searchUsers} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
    </div>
    </div>
    </form>
    </div>
    <div className="col-lg-12 col-sm-12 mt-3">
    <table className="table table-responsive table-striped table-hover mb-0">
    <thead>
    <tr>
    <th>No. <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
    <th>Name <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
    <th>Email <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
    <th>Contact Number <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th>
    {/* <th>Role <span className="pull-right"><i className="fa fa-sort-up"></i><i className="fa fa-sort-down"></i></span></th> */}
    <th>Action</th>
    </tr>
    </thead>
    <tbody>
    {
      companyUsers.map((companyUser, index) => {
        return (
          <tr key={index}>
          <td>{index + 1}</td>
          <td>{companyUser.name}</td>
          <td>{companyUser.email}</td>
          <td>{companyUser.contact_no}</td>
          {/* <td>{companyUser.role == null ? 'Admin' : companyUser.role}</td> */}
          <td>
          {companyUser.role != null && <a href="#" onClick={(event) => editCompanyUserHandler(event, companyUser)} className="download-btn bg-text-color-2a78e4-fff"><i className="fa fa-edit"></i></a>}&nbsp;
          { companyUser.role != null && <a href="#" onClick={(event) => deleteUser(event, companyUser)} style={{ background: '#cf2a2d' }} className="share-alt bg-color-cf2a2d"><i className="fa fa-trash"></i></a> }&nbsp;
          {companyUser.status === 'active' && <a href="#" title="Active" onClick={(event) => updateCompanyUser(event, companyUser.id, 'inactive')}  className="share-alt" style={{ background: '#000', color: '#fff' }}><i className="fa fa-close"></i></a>}
          {companyUser.status === 'inactive' && <a href="#" title="Inactive" onClick={(event) => updateCompanyUser(event, companyUser.id, 'active')} className="share-alt bg-color-text-2aa562-fff"><i className="fa fa-check"></i></a>}
          </td>
          </tr>
          )
        })
      }
      { companyUsers.length === 0 ? <tr><td colSpan='6' className='text-center'>No Record Found</td></tr> : null }
      </tbody>
      </table>
      </div>
      </div>
      <div className="page-bottom-pagination text-right mt-4">
      <nav aria-label="Page navigation example" className="d-inline-block">
      <Pagination links={pagination} onPageClick={loadCompanyUsers} />
      </nav>
      </div>
      </div>
      </React.Fragment>
      )
    }
    
    export default CompanyUsers;