import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const FlowMeterCalibration = (props) => {

    const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', maker: '', sensor_model_no: '', sensor_serial_no: '', flow_range: '', pipe_size: '', transmitter_model: '', transmitter_sr_no: '', meter_k: '', flow_cal_factor: '', description_of_inspection_tests: '', calibrating_fluid: '', calibration_results: [], test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '' });
    const { addToast } = useToasts();
    const { job_id, form_id } = useParams();
    const [Classes, SetClasses] = useState([]);
    const [Flags, SetFlags] = useState([]);
    let history = useHistory();

    let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
    let classesURL = config.BaseURL + "/v1/classes?all=true";
    let flagURL = config.BaseURL + "/v1/flags?all=true";

    useEffect(() => {
        if (props.formFields != null) {
            const formObject = { ...formFields, ...props.formFields };
            formObject.control_no = formObject.ref_no
            setFormFields(formObject);
        }
        loadFlags(flagURL);
        loadClasses(classesURL);
    }, [props.formFields]);

    const handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        const formFieldsObject = {
            ...formFields,
            [name]: value
        }
        setFormFields(formFieldsObject);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
            addToast(response.data.message, { appearance: 'success', autoDismiss: true });
            history.goBack();
        }).catch(function (error) {
            addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
        });
    }

    const loadClasses = () => {
        axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
            SetClasses(response.data);
        })
    }

    const loadFlags = () => {
        axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
            SetFlags(response.data);
        })
    }
    const handleChangeRows = (event, name, index) => {
        const _formFields = { ...formFields };
        _formFields.calibration_results[index][name] = event.target.value;
        console.log(_formFields.calibration_results);
        setFormFields(_formFields);
    }

    const addCalibrationRow = () => {
        const _formFields = { ...formFields };
        _formFields.calibration_results.push({ sr_no: '', flow: '', calibration_error: '', calibration_percentage_error: '', correction: '' });
        setFormFields(_formFields);
    }

    const handleRemoveFields = (event, index) => {
        const values = { ...formFields };
        values.calibration_results.splice(index, 1);
        setFormFields(values);
    }

    const actionHandler = (event, action) => {
        event.preventDefault();
        axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
            addToast(response.data.message, { appearance: 'success', autoDismiss: true });
            history.goBack();
        }).catch(function (error) {
            addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
        });
    }

    return (
        <React.Fragment>
            <InnerHeader title="Flow Meter Calibration" />
            <section className="dashboard-counts no-padding-bottom">
                <div className="container-fluid">
                    <form name="" action="">
                        <div className="bg-white has-shadow">
                            <div className="row px-0 pb-0">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>SHM Control No</label>
                                        <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Vessel Name</label>
                                        <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Customer Name</label>
                                        <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>IMO Number</label>
                                        <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Customer PIC</label>
                                        <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Port of service</label>
                                        <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Class</label>
                                        <div className="styled-select">
                                            <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                                                <option value="" selected="selected"> Class </option>
                                                {
                                                    Classes.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="fa fa-angle-down"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Flag</label>
                                        <div className="styled-select">
                                            <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                                                <option value=""> Select Flag </option>
                                                {
                                                    Flags.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.id}> {item.name} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <span className="fa fa-angle-down"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-6">
                                    <div className="form-group">
                                        <label>Date</label>
                                        <div className="input-group input-daterange">
                                            <input type="date" onChange={handleChange} id="start" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                                            {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <label>Description Of Inspection / Tests</label>
                                    <input type="text" onChange={handleChange} name="description_of_inspection_tests" value={formFields['description_of_inspection_tests']} placeholder="This instrument has been calibrated in accordance with our process and conforms to the quality and calibration standards laid down in our detailed procedures." className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                                </div>
                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <label>Calibrating Fluid</label>
                                    <input type="text" onChange={handleChange} name="calibrating_fluid" value={formFields['calibrating_fluid']} placeholder="Calibrating Fluid" className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                                </div>
                                <div className="col-lg-12 col-sm-12 mt-1">
                                    <table className="table table-responsive table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td>Equipment</td>
                                                <td><input type="text" name="equipment" onChange={handleChange} value={formFields['equipment']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                <td>Pipe Size</td>
                                                <td><input type="text" name="pipe_size" onChange={handleChange} value={formFields['pipe_size']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                            </tr>
                                            <tr>
                                                <td>Maker</td>
                                                <td><input type="text" name="maker" onChange={handleChange} value={formFields['maker']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                <td>Transmitter Model</td>
                                                <td><input type="text" name="transmitter_model" onChange={handleChange} value={formFields['transmitter_model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                            </tr>
                                            <tr>
                                                <td>Sensor Model No.</td>
                                                <td><input type="text" name="sensor_model_no" onChange={handleChange} value={formFields['sensor_model_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                <td>Transmitter Sr. No</td>
                                                <td><input type="text" name="transmitter_sr_no" onChange={handleChange} value={formFields['transmitter_sr_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                            </tr>
                                            <tr>
                                                <td>Sensor Serial No.</td>
                                                <td><input type="text" name="sensor_serial_no" onChange={handleChange} value={formFields['sensor_serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                <td>Meter K(Pulse/Kg)</td>
                                                <td><input type="text" name="meter_k" onChange={handleChange} value={formFields['meter_k']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                            </tr>
                                            <tr>
                                                <td>Flow Range</td>
                                                <td><input type="text" name="flow_range" onChange={handleChange} value={formFields['flow_range']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                <td>Flow Cal Factor</td>
                                                <td><input type="text" name="flow_cal_factor" onChange={handleChange} value={formFields['flow_cal_factor']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <h4 className="mb-0">Calibration Results</h4>
                                </div>
                                <div className="col-lg-12 col-sm-12">
                                    <table className="table table-responsive table-striped table-hover">
                                        <tbody>
                                            <tr>
                                                <td className="text-center">SR. NO.</td>
                                                <td className="text-center">FLOW ( TON/HR)</td>
                                                <td className="text-center">ERROR</td>
                                                <td className="text-center">% ERROR</td>
                                                <td className="text-center">CORRECTION</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                            {
                                                formFields.calibration_results.map((item, index) => {
                                                    return (
                                                        <tr key={{ index }}>
                                                            <td><input type="text" onChange={(event) => handleChangeRows(event, 'sr_no', index)} value={item['sr_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                            <td><input type="text" onChange={(event) => handleChangeRows(event, 'flow', index)} value={item['flow']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                            <td><input type="text" onChange={(event) => handleChangeRows(event, 'calibration_error', index)} value={item['calibration_error']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                            <td><input type="text" onChange={(event) => handleChangeRows(event, 'calibration_percentage_error', index)} value={item['calibration_percentage_error']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                            <td><input type="text" onChange={(event) => handleChangeRows(event, 'correction', index)} value={item['correction']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                                                            <td className="vertical-mid"><button type="button" onClick={(event) => handleRemoveFields(event, index)} className="removeBtn"><i className="fa fa-remove"></i></button></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className="text-left mb-3 disable-button-custom">
                                        <button onClick={() => addCalibrationRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                                    </div>
                                </div>


                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <label>Test Result</label>
                                    <textarea name="test_result" onChange={handleChange} value={formFields['test_result']} placeholder="We hereby certify that w e have tested the above instrument in accordance to standard inspection procedures and that the quality is in full conformance with the specifications and the standards" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                                </div>

                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <label>Tracebility</label>
                                    <textarea name="tracebility" onChange={handleChange} value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                                </div>

                                <div className="col-lg-12 col-sm-12 mb-3">
                                    <label>Remarks</label>
                                    <textarea name="remarks" onChange={handleChange} value={formFields['remarks']} placeholder="Remarks" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                                </div>

                                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                                    <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className="row px-0 border my-4">
                        <div className="col-lg-4 col-sm-4 brd-rht">
                            <div className="mb-2"><strong>Company Address </strong></div>
                            <div><b>SHM SHIPCARE SDN BHD</b></div>
                            <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                        </div>
                        <div className="col-lg-4 col-sm-4 brd-rht">
                            <div><b>Master/Chief Officer<br />Endorsement</b></div>
                        </div>
                        <div className="col-lg-4 col-sm-4">
                            <div><b>Service Engineer</b></div>
                            <div>
                                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    );
}
export default FlowMeterCalibration;