import axios from "axios";
import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from "react";
import InnerHeader from '../Shared/InnerHeader';
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import Pagination from '../Shared/Pagination';
import LoadingBar from '../Shared/LoadingBar';
import InfoModal from "../Shared/InfoModal";

const List = (props) => {

  const [pendingJob, setPendingJob] = useState([]);
  const [pendingForReview, setpendingForReviewJob] = useState([]);
  const [pendingForApproval, setpendingForApprovalJob] = useState([]);
  const [approvedJobs, setApprovedJobs] = useState([]);
  const [rejectedJobs, setRejectedJobs] = useState([]);
  const [customerJobs, setcustomerJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [JobLinks, setJobLinks] = useState([]);
  const [newJobLinks, setNewJobLinks] = useState([]);
  const [pendingJobLinks, setPendingJobLinks] = useState([]);
  const [reviewedJobLinks, setReviewedJobLinks] = useState([]);
  const [approvedJobLinks, setApprovedJobLinks] = useState([]);
  const [rejectedJobLinks, setRejectedJobLinks] = useState([]);
  const [acceptedJobLinks, setAcceptedJobLinks] = useState([]);

  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [toggleState, setToggleState] = useState(1);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reviewStatus, setReviewStatus] = useState('');
  const [jobsLinks, setJobsLinks] = useState([]);
  const [rejectionData, setRejectionData] = useState({ comment: null, user: null });
  const [infoModal, setInfoModal] = useState({ infoModalVisible: false });


  let assigneeJobs = config.BaseURL + "/v1/jobs?for=assignee";
  let reviewerJobs = config.BaseURL + "/v1/jobs?for=reviewer";
  let approverJobs = config.BaseURL + "/v1/jobs?for=approver";
  let approvedJobsURL = config.BaseURL + "/v1/jobs?jobType=Approved";
  let rejectedJobsURL = config.BaseURL + "/v1/jobs?jobType=Rejected";
  let customerJobsURL = config.BaseURL + "/v1/jobs?jobType=AcceptedByCustomer";

  const items = [
    {
      label: "All",
      value: ""
    },
    {
      label: "Pending",
      value: "Pending"
    },
    {
      label: "Completed",
      value: "Completed"
    }
  ];

  const toggleTab = (index) => {
    setToggleState(index);
  }

  useEffect(() => {
    loadAssigneeJobs(assigneeJobs);
    loadReviewerJobs(reviewerJobs);
    loadApproverJobs(approverJobs);
    loadApprovedJobs(approvedJobsURL);
    loadRejectedJobs(rejectedJobsURL);
    loadcustomerJobs(customerJobsURL);
  }, [searchTerm, startDate, endDate, reviewStatus]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchJob();
  }

  const handleChangeStatus = (event) => {
    var status = event.target.value;
    setReviewStatus(status);
  }

  const handleStartDate = (date, event) => {
    var x = event.target.value;
    setStartDate(x);
  }

  const handleEndDate = (date, event) => {
    var x = event.target.value;
    setEndDate(x);
  }

  const searchJob = () => {
    setSearchTerm(searchtermRef.current.value);
  }

  function loadAssigneeJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate, } }).then(function (response) {
      setPendingJob(response.data.data);
      setNewJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  function loadReviewerJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate } }).then(function (response) {
      setpendingForReviewJob(response.data.data);
      setPendingJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  function loadApproverJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate } }).then(function (response) {
      setpendingForApprovalJob(response.data.data);
      setReviewedJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  function loadApprovedJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate } }).then(function (response) {
      setApprovedJobs(response.data.data);
      setApprovedJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  function loadRejectedJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate } }).then(function (response) {
      setRejectedJobs(response.data.data);
      setRejectedJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  function loadcustomerJobs(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate, date_to: endDate } }).then(function (response) {
      setcustomerJobs(response.data.data);
      setAcceptedJobLinks(response.data.links);
      setIsLoading(false);
    });
  }

  const infoModalHandler = (event, infoModalVisible, comment = null, user, customer) => {
    event.preventDefault();
    setRejectionData({ comment: comment, user: user ?? customer });
    setInfoModal({
      infoModalVisible: infoModalVisible,
    });
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      {ReactDOM.createPortal(<InfoModal isModalVisible={infoModal.infoModalVisible} infoModalHandler={infoModalHandler} rejectionData={rejectionData} />, document.getElementById('modal-root'))}
      <InnerHeader title="Manage Job" buttonTitle="New Job" path="/manage-jobs/add" />
      {/* <InnerHeader title="Manage Job" buttonTitle="New Job" path="/manage-job/new" /> */}
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <nav style={{ display: "flex", borderBottom: "1px solid #ddd" }}>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(1)} className={toggleState === 1 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-newjob-tab" data-toggle="tab" role="tab" aria-controls="nav-newjob" aria-selected="true">New Job</Link>
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(2)} className={toggleState === 2 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-pending-tab" data-toggle="tab" role="tab" aria-controls="nav-pending" aria-selected="true">Pending</Link>
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(3)} className={toggleState === 3 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-reviewed-tab" data-toggle="tab" role="tab" aria-controls="nav-reviewed" aria-selected="false">Reviewed</Link>
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(4)} className={toggleState === 4 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-approved-tab" data-toggle="tab" role="tab" aria-controls="nav-approved" aria-selected="false">Approved</Link>
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(5)} className={toggleState === 5 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-rejected-tab" data-toggle="tab" role="tab" aria-controls="nav-rejected" aria-selected="false">Rejected</Link>
                  <Link style={{ cursor: "pointer" }} onClick={() => toggleTab(6)} className={toggleState === 6 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-rejected-tab" data-toggle="tab" role="tab" aria-controls="nav-rejected" aria-selected="false">Accepted by Customer</Link>
                </div>
              </nav>
              <div className="row px-0 m-l-r">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchtermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchJob} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-sm-5 btmSpace">
                  <form autoComplete="off">
                    <div className="flex-row d-flex justify-content-center">
                      <div className="col-lg-12 col-12 px-1">
                        <div className="input-group input-daterange manage-job-date custom-input-daterange">
                          <input type="date" className="form-control mr-2 width-100" onChange={(date, event) => handleStartDate(event, date)} />
                          {/* <span className="fa fa-calendar" id="fa-1"></span> */}
                          <input type="date" className="form-control ml-2 width-100" onChange={(date, event) => handleEndDate(event, date)} />
                          {/* <span className="fa fa-calendar" id="fa-2"></span> */}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="col-lg-3 col-sm-3 btmSpace">
                  <div className="styled-select">
                    <select onChange={(event) => handleChangeStatus(event)} name="">
                      {
                        items.map((item, index) => {
                          return (
                            <option key={item.value} value={item.value}> {item.label} </option>
                          )
                        })
                      }
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div> */}
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div className={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-newjob" role="tabpanel" aria-labelledby="nav-newjob-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewer<span className="pull-right"></span></th>
                        <th>Approver<span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingJob.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              {item.job_status === 'Pending' && (<td><span className="badge-warning">{item.job_status}  </span></td>)}
                              {item.job_status === 'Pending For Approval' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Pending For Review' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Approved' && (<td><span className="badge-success">{item.job_status}</span></td>)}
                              {item.job_status === 'Rejected' && (<td><span className="badge-danger">{item.job_status}</span></td>)}
                              {item.job_status === 'Accepted By Customer' && (<td><span className="badge-success">{item.job_status}</span></td>)}
                              {/* <td><span className="badge-warning">{item.job_status}</span></td> */}
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={newJobLinks} onPageClick={loadAssigneeJobs} />}
                    </nav>
                  </div>
                </div>
                <div className={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-pending" role="tabpanel" aria-labelledby="nav-pending-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewer<span className="pull-right"></span></th>
                        <th>Approver<span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingForReview.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              {item.job_status === 'Pending' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Pending For Approval' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Pending For Review' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Approved' && (<td><span className="badge-success">{item.job_status}</span></td>)}
                              {item.job_status === 'Rejected' && (<td><span className="badge-danger">{item.job_status}</span></td>)}
                              {/* <td><span className="badge-warning">{item.job_status}</span></td> */}
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={pendingJobLinks} onPageClick={loadReviewerJobs} />}
                    </nav>
                  </div>
                </div>
                <div className={toggleState === 3 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-reviewed" role="tabpanel" aria-labelledby="nav-reviewed-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewed By <span className="pull-right"></span></th>
                        <th>Approver<span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        pendingForApproval.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              {item.job_status === 'Pending' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Pending For Approval' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Pending For Review' && (<td><span className="badge-warning">{item.job_status}</span></td>)}
                              {item.job_status === 'Approved' && (<td><span className="badge-success">{item.job_status}</span></td>)}
                              {item.job_status === 'Rejected' && (<td><span className="badge-danger">{item.job_status}</span></td>)}
                              {/* <td><span className="badge-warning">{item.job_status}</span></td> */}
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>

                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={reviewedJobLinks} onPageClick={loadApproverJobs} />}
                    </nav>
                  </div>
                </div>
                <div className={toggleState === 4 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-approved" role="tabpanel" aria-labelledby="nav-approved-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewed By <span className="pull-right"></span></th>
                        <th>Approved By <span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        approvedJobs.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              <td><span className="badge-success">{item.job_status}</span> {item.is_email_send == 'N' ? (<i title="Email not send to customer" class="fa fa-envelope btn-link ml-2"></i>) : ""} </td>
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={approvedJobLinks} onPageClick={loadApprovedJobs} />}
                    </nav>
                  </div>
                </div>
                <div className={toggleState === 5 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-rejected" role="tabpanel" aria-labelledby="nav-rejected-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewed By <span className="pull-right"></span></th>
                        <th>Approved By <span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        {/* <th>Comment <span className="pull-right"></span></th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        rejectedJobs.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              <td><span>{item.is_rejected_by_customer === 1 && item.job_status === 'Rejected' ? (<span> <span className="badge-danger"> Rejected By Customer </span> <i onClick={(event) => { infoModalHandler(event, true, item.comment, item.rejected_by_user, item.customer_name) }} className="fa fa-info-circle ml-1"></i> </span>) : (<span className="badge-danger"> {item.job_status} </span>)}</span> </td>
                              {/* <td>{item.comment ?? 'NA'}</td> */}
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={rejectedJobLinks} onPageClick={loadRejectedJobs} />}
                    </nav>
                  </div>
                </div>
                <div className={toggleState === 6 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-rejected" role="tabpanel" aria-labelledby="nav-rejected-tab">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Reviewed By <span className="pull-right"></span></th>
                        <th>Approved By <span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Comment <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        customerJobs.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{index + 1}</td>
                              <td>{item.certificate_no}</td>
                              <td>{item.customer_name}</td>
                              <td>{item.vessel_name}</td>
                              <td>{item.service_port}</td>
                              <td>{item.service_date}</td>
                              <td>{item.assignee_name}</td>
                              <td>{item.reviewer_name}</td>
                              <td>{item.approver_name}</td>
                              <td><span className="badge-success">{item.job_status}</span></td>
                              <td>{item.comment ?? 'NA'}</td>
                              <td> <Link to={`/manage-job/${item.id}/detail`} className="view-btn">View </Link> {item.feedback_json !== null ? (<Link to={`/feedback/${item.email_token}?view=readonly`} className="view-btn" style={{ marginTop: '10px' }}>View Feeback</Link>) : ''}  </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      {pendingJob.length === 0 ? '' : <Pagination links={acceptedJobLinks} onPageClick={loadcustomerJobs} />}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default List;