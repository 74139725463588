import React from "react";
import InnerHeader from '../Shared/InnerHeader';
import { Link } from 'react-router-dom';

const Certificate = (props) => {
  return (
    <React.Fragment>
      <InnerHeader title="Certificate Of Inspection" buttonTitle="View PDF" path="/manage-job/pdf" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">List Of Service Certificates</h4>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Year</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Year </option>
                        <option value="1"> Year</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Month</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Month </option>
                        <option value="1"> January</option>
                        <option value="2">February </option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="text" id="start" className="form-control" placeholder="From Date" style={{ fontSize: "15px" }} />
                      <span className="fa fa-calendar" id="fa-2" style={{ marginTop: "-3px" }}></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Vessel Name" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="text" name="" value="" className="form-control" placeholder="IMO Number" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Certificate No.</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Certificate No." />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Place of Service</label>
                    <input type="text" name="" value="" className="form-control" placeholder="Place of Service" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select id="" name="">
                        <option value="" selected="selected"> Flag </option>
                        <option value="1"> Flag</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>classNameification Society</label>
                    <input type="text" name="" value="" className="form-control" placeholder="classNameification Society" />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>No. Of Pages</label>
                    <input type="text" name="" value="" className="form-control" placeholder="No. Of Pages" />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mb-4">
                  We hereby certify that the Fire Fighting Systems & Equipments as specified below and in the Service Chart/s have been inspected and serviced.
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Scope Of Service</h4>
                </div>
                <div className="col-lg-12 col-sm-12 checkBoxWid">
                  <div className="form-group">
                    <span><input type="checkbox" name="" value="" className="mr-2" />Airline Trolley System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Fireman Suits</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Self-contained Breathing Apparatus</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Chemical Suits</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Emergency Escape Breathing Device</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Liferafts</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />SCBA Compressor</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Other Systems & Equipments (OSE)</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Medical Oxygen</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Medical Oxygen Resuscitators</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Foam Lifejackets</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Immsersion Suits</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Air Quality Analysis</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Inflatable Lifejackets</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Co2 High Pressure System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Co2 Low pressure System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Lifeboat Air System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Rescue Boat</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Foam System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Life Boat</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Dry Powder System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Gas Detection Calibration</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Fixed Fire Detection System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Fixed Gas Detection System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Other Systems & Equipments (OSE)</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Water Mist system</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Water Sprinkler System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Fire Extinguishers</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Foam Concentrate Test</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Co2 Galley Hood System</span>
                    <span><input type="checkbox" name="" value="" className="mr-2" />Foam Applicator</span>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <Link to="/manage-job/view" className="continue-btn"><i className="fa fa-arrow-left"></i>&nbsp;<a> Back </a> </Link>&nbsp;
                  <Link to="/manage-job/approved" className="continue-btn" style={{ background: "#28a745" }}><i className="fa fa-send"></i>&nbsp; <a> Submit </a> </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Certificate;