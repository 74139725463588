import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import user from '../../assets/img/avatar-1.jpg';
import config from '../../config.json';
import { getAPIHeaders, getName, getProfilePicture } from "../../helpers/functions";
import ChangePassword from '../Companies/ChangePassword';
import ReactDOM from 'react-dom';


const Sidebar = (props) => {

  const [menus, setMenus] = useState([]);
  const location = useLocation();
  const { pathname } = location;
  const [optionMenuVisible, setOptionMenuVisiblity] = useState(false);
  
  useEffect(() => {
    if (localStorage.getItem('menu') == null) {
      fetchMenus();
    } else {
      setMenus(JSON.parse(localStorage.getItem('menu')));
    }
  }, []);

  const toggleProfileMenu = (event) => {
    event.preventDefault();
    setOptionMenuVisiblity(!optionMenuVisible);
  }

  function fetchMenus() {
    axios.get(config.BaseURL + "/v1/menus", { headers: getAPIHeaders() }).then(function (response) {
      let menusArray = [];
      response.data.forEach(element => {
        menusArray.push(element);
      });
      localStorage.setItem('menu', JSON.stringify(menusArray));
      setMenus(menusArray);
    });
  }

  const [modalObject, setModalObject] = useState({ modalVisible: false });

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible
    });
  }

  return (
    <React.Fragment>
      {ReactDOM.createPortal(<ChangePassword isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}
      <nav className={props.sidebarState}>
        <div className="sidebar-header align-items-center posRel">
          <div className="avatar pull-left mr-2">
            <img src={getProfilePicture() === '' ? user : getProfilePicture()} alt="" className="img-fluid rounded-circle" />
          </div>
          <div className="title">
            <h1 className="h4">{getName()}</h1>
          </div>
          <Link onClick={(event) => toggleProfileMenu(event)}  aria-expanded="false" data-toggle="collapse" className="collapsed posAbs"></Link>
          <div id="profileMenu" className={`list-unstyled ${optionMenuVisible ? '' : 'collapse'} flPos`}>
            <Link to="/profile" className="mb-2"><i className="fa fa-user-o mr-2"></i> My Profile</Link><br />
            <a href="#ex" onClick={(event) => modalHandler(event, true)} className="mb-2"><i className="fa fa-unlock-alt mr-2"></i>Change Password</a><br />
            <Link to="/" onClick={props.logoutHandler} className="mb-2"><i className="fa fa-sign-out mr-2"></i>Logout</Link><br />
          </div>
        </div>
        <ul className="list-unstyled">
          {
            menus.map((menuObject, index) => {
              return <li className={pathname === menuObject.path ? "active" : ""} key={index}>{(menuObject.name !== "Settings") ? <Link to={menuObject.path}><i className={menuObject.icon}></i>{menuObject.name}</Link> : ''}</li>
            })
          }
        </ul>
      </nav>
    </React.Fragment>
  );
}

export default Sidebar;