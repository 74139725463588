import React, { useState, useEffect } from "react";
import DocsSecurity from "../../assets/img/docs-security.png";
import FileView from "../../assets/img/file-view.png";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import Header from '../Shared/Header';


const Verified = (props) => {

  const [emailFields, setEmailFields] = useState({ email: '' });
  const [otpFields, setOtpFields] = useState({ digit1: '', digit2: '', digit3: '', digit4: '' });
  let { token } = useParams();
  const [toggleState, setToggleState] = useState(0);
  const [jobId, setJobId] = useState(null);
  let history = useHistory();
  const { addToast } = useToasts();

  useEffect(() => {
    isRestricted();
    getJobId();
  },[token])

  const isRestricted = () => {
    axios.get(config.BaseURL + "/v1/check-job-status/" + token, { headers: getAPIHeaders() } ).then(function (response) {
      console.log('ola',response.data)
      if(response.data == 'Accepted By Customer') {
        history.push('/document-information/' + token);
      }
   }).catch((error) => {
     addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
   })
  }

  const toggleTab = (index) => {
    setToggleState(index);
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const emailFieldsObject = {
      ...emailFields,
      [name]: value
    }
    setEmailFields(emailFieldsObject);
  }
  const getJobId = () => {
    axios.get(config.BaseURL + "/v1/job-id/" + token, { headers: getAPIHeaders() } ).then(function (response) {
      if(response.data.status == 'success') {
        setJobId(response.data.job_id);
      }
   }).catch((error) => {
     addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
   })
  }

  const handleChangeOne = (event, nextElementId) => {
    event.preventDefault();
    // console.log(event.target);
    const { name, value } = event.target;
    const otpFieldsObject = {
      ...otpFields,
      [name]: value
    }
    console.log(nextElementId);
    document.getElementById(nextElementId).focus();
    setOtpFields(otpFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/send-verification-otp/" + token + '?customer_email=' + emailFields['email'], { headers: getAPIHeaders() } ).then(function (response) {
       if(response.data.status == 'success') {
        setToggleState(1);
       }
    }).catch((error) => {
      setToggleState(0);
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    })
  }

  // const otpEnter = (nextElementId) => {
  //   console.log(nextElementId);
  //   document.getElementById(nextElementId).focus();
  // }

  const otpSubmitHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/verify-otp/" + token + '?otp=' + otpFields['digit1'] + otpFields['digit2'] + otpFields['digit3'] + otpFields['digit4'], { headers: getAPIHeaders() } ).then(function (response) {
      if(response.status == 200) {
        history.push('/document-information/' + token);
       }
    }).catch((error) => {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    })
  }

  

  return (
    <React.Fragment>
      <div className="page home-page">
      <Header toShow={false} />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid catalog-page px-0">
          <div className={toggleState == 0 ? "verified-section gerylightBg" : "verified-section gerylightBg display-n"} id="viewDoc">
            <div class="container">
              <div class="row p-0">
                <div class="col-lg-6 col-sm-6 px-0 float-none">
                  <div class="white-box py-3 pt-4">
                    <div class="col-lg-8 col-sm-8 float-none">
                      <div class="row p-0">
                        <div class="col-lg-4 col-sm-4 col-5">
                          <div class="grey-lgt">IJO/Ref:</div>
                        </div>
                        <div class="col-lg-8 col-sm-8 col-7">
                          <div class="mid-bld">{jobId}</div>
                        </div>
                      </div>
                    </div>
                    <div class="brd"></div>
                    <div class="d-flex py-3">
                      <div class="col-lg-8 col-sm-8 float-none text-center">
                        <div class="text-center"><img src={DocsSecurity} alt="" /></div>
                        <div class="py-2"><b>Document is Secured. <br />Please enter your email id : </b></div>
                        <input type="text" id="txtEmail" onChange={handleChange} name="email" value={emailFields['email']} maxlength="255" class="form-control" placeholder="Email Id" />
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <Link onClick={submitHandler} to="/" class="btns view-doc"><img src={FileView} alt="View Document" />&nbsp; View Document</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={toggleState == 1 ? "otp-section gerylightBg" : "otp-section gerylightBg display-n"} id="viewOTP">
            <div class="container">
              <div class="row p-0">
                <div class="col-lg-6 col-sm-6 float-none">
                  <div class="white-box py-4">
                    <div class="text-center"><img src="assets/img/verifieds.svg" alt="" /></div>
                    <h4 class="text-center p-2">Verify OTP </h4>
                    <div class="confirm-otp">To Confirm Enter the OTP that we sent to.<br />
                      <div id="lblEmail"></div>
                    </div>
                    <form method="get" class="digit-group mt-2" data-group-name="digits" data-autosubmit="false" autocomplete="off">
                      <input type="hidden" id="csrf" value="gS61MzAplkIgngm8oS7axNR0htc5YL17CoTvOQWH" />
                      <input type="hidden" id="document_id" value="425" />
                      <input  type="text" class="inputs"  id="digit-1" onChange={(event) => handleChangeOne(event, 'digit-2')} value={otpFields['digit1']} name="digit1"  maxlength="1" />
                      <input  type="text" class="inputs"  id="digit-2" onChange={(event) => handleChangeOne(event, 'digit-3')} value={otpFields['digit2']} name="digit2"  maxlength="1"  />
                      <input  type="text" class="inputs"  id="digit-3" onChange={(event) => handleChangeOne(event, 'digit-4')} value={otpFields['digit3']} name="digit3"  maxlength="1"  />
                      <input type="text" class="inputs"  id="digit-4" onChange={(event) => handleChangeOne(event, 'digit-4')} value={otpFields['digit4']} name="digit4" maxlength="1"  />
                    </form>
                  </div>
                  <div class="text-center">
                    <Link onClick={otpSubmitHandler} to="/" id="otpSubmit" class="btns view-doc"><i class="fa fa-check"></i>&nbsp; Verify</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </React.Fragment>
  );
}

export default Verified;