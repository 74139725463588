import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

const Modal = (props) => {

  const [emailFields, setEmailFields] = useState({ email: '' });
  let { job_id } = useParams();
  const { addToast } = useToasts();

  useEffect(() => {

  },[emailFields])

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const emailFieldsObject = {
      ...emailFields,
      [name]: value
    }
    setEmailFields(emailFieldsObject);
  }

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.get(config.BaseURL + "/v1/view-document-email/" + job_id + '?customer_email=' + emailFields['email'], { headers: getAPIHeaders() } ).then(function (response) {
       if(response.data.status == 'success') {
        props.modalCloseHandler(event, false);
        addToast("Email sent successfully!", { appearance: 'success', autoDismiss: true });
        props.loadJobDetail(props.url);
       }
    }).catch((error) => {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    })
  }

  return (
    <React.Fragment>
      <div class="modal" id="sendmail"  aria-hidden="true" style={style}>
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLongTitle">Send Email</h3>
              <button onClick={onModalClose} type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form name="" action="">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" onChange={handleChange} name="email" value={emailFields['email']} class="form-control" placeholder="Email address" />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <Link onClick={submitHandler} to="/" class="btn btn-primary">Send &nbsp;<i class="fa fa-paper-plane"></i></Link>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default Modal;