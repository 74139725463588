import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import config from '../../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../../helpers/functions";
import { useToasts } from 'react-toast-notifications';
import FormButton from "../../Shared/FormButton";

const RecomandationReport = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_no: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', recomendation_discription_1: '', action_required_1: '', recomendation_discription_2: '', action_required_2: '', recomendation_discription_3: '', action_required_3: '', recomendation_discription_4: '', action_required_4: '', recomendation_discription_5: '', action_required_5: '', remark: '', approver_name: '', service_engineer_sign: '' });
  const { job_id, form_id } = useParams();
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(setFormFieldsObject);
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }
  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Recommendation Report" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_no" value={formFields['imo_no']} className="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Description of Recommendation Actions </th>
                        <th>Action required by (who and when)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><input onChange={handleChange} type="text" name="recomendation_discription_1" value={formFields['recomendation_discription_1']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="action_required_1" value={formFields['action_required_1']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td><input onChange={handleChange} type="text" name="recomendation_discription_2" value={formFields['recomendation_discription_2']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="action_required_2" value={formFields['action_required_2']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td><input onChange={handleChange} type="text" name="recomendation_discription_3" value={formFields['recomendation_discription_3']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="action_required_3" value={formFields['action_required_3']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td><input onChange={handleChange} type="text" name="recomendation_discription_4" value={formFields['recomendation_discription_4']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="action_required_4" value={formFields['action_required_4']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td><input onChange={handleChange} type="text" name="recomendation_discription_5" value={formFields['recomendation_discription_5']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="action_required_5" value={formFields['action_required_5']} className="form-control" placeholder="" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*<div className="col-lg-12 col-sm-12"> 
                  <label>Load Test Observations And Results</label>
                  <textarea onChange={handleChange} type="text" name="remark" value={formFields['remark']} className="form-control" placeholder="Remarks" style={{ height: "100px" }} disabled={props.can_edit_form}></textarea>
                </div>*/}
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                {/* <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Company Address </h4>
                </div>
                <div className="col-lg-12 col-sm-12"><b>SHM SHIPCARE SDN BHD</b></div>
                <div className="col-lg-12 col-sm-12">No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                <div className="col-lg-12 col-sm-12"><b>Master/Chief Officer Endorsement</b></div>
                <div className="col-lg-12 col-sm-12"><b>Service Engineer</b></div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approver Name</label>
                    <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']}  className="form-control" placeholder="Name" disabled={props.can_edit_form} />
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Sign</label>
                    <input onChange={handleChange} type="text" name="service_engineer_sign" value={formFields['service_engineer_sign']} className="form-control" placeholder="Sign" disabled={props.can_edit_form} />
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className="styled-select">
                      <select onChange={handleChange} type="text" name="approved_by" >
                        <option value="" selected="selected"> Select </option>
                        <option value="1"> Select</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
            <div class="row px-0 border mt-4">
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div class="mb-2"><strong>Company Address </strong></div>
                <div><b>SHM SHIPCARE SDN BHD</b></div>
                <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
              </div>
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div><b>Master/Chief Officer<br />Endorsement</b></div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div><b>Service Engineer</b></div>
                <div>
                  {/* <label class="mb-0">Name</label> */}
                  <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
                </div>
                <div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default RecomandationReport;