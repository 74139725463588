import React, { useState, useEffect, useRef, Suspense } from 'react';
import InnerHeader from "../Shared/InnerHeader";
import LoadingBar from '../Shared/LoadingBar';
import "react-datepicker/dist/react-datepicker.css";
import Pagination from '../Shared/Pagination';
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";
import InfoModal from './../Shared/InfoModal';
const List = (props) => {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reviewStatus, setReviewStatus] = useState('');
  const [jobsLinks, setJobsLinks] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [rejectionData, setRejectionData] = useState({ comment: null, user: null });
  const [infoModal, setInfoModal] = useState({ infoModalVisible: false });
  let url = config.BaseURL + "/v1/jobs";

  useEffect(() => {
    loadJobs(url);
  }, [searchTerm, startDate, endDate, reviewStatus]);

  const items = [
    {
      label: "All",
      value: ""
    },
    {
      label: "Pending",
      value: "Pending"
    },
    {
      label: "Pending For Review",
      value: "Pending For Review"
    },
    {
      label: "Pending For Approval",
      value: "Pending For Approval"
    },
    {
      label: "Approved",
      value: "Approved"
    },
    {
      label: "Rejected",
      value: "Rejected"
    },
    {
      label: 'Accepted By Customer',
      value: "Accepted By Customer"
    },
    {
      label: 'Rejected By Customer',
      value: "Rejected By Customer"
    }
  ];

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchJob();
  }

  const handleChangeStatus = (event) => {
    var status = event.target.value;
    setReviewStatus(status);
  }
  
  const handleStartDate = (date, event) => {
    var x = event.target.value;
    setStartDate(x);
  }
  const handleEndDate = (date, event) => {
    var x = event.target.value;
    setEndDate(x);
  }

  const searchJob = () => {
    setSearchTerm(searchtermRef.current.value);
  }
  
  const loadJobs = (url) => {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, date_from: startDate,  date_to: endDate, job_status: reviewStatus, } }).then(function (response) {
      setJobs(response.data.data);
      setJobsLinks(response.data.links);
      setIsLoading(false);
    });
  }

  const infoModalHandler = (event, infoModalVisible, comment = null, user, customer) => {
    event.preventDefault();
    setRejectionData({ comment: comment, user: user ?? customer });
    setInfoModal({
      infoModalVisible: infoModalVisible,
    });
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      {ReactDOM.createPortal(<InfoModal isModalVisible={infoModal.infoModalVisible} infoModalHandler={infoModalHandler} rejectionData={rejectionData} />, document.getElementById('modal-root'))}
      <InnerHeader title="Manage Jobs" buttonTitle="New Job" path="/manage-jobs/add" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchtermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchJob} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-5 col-sm-5 btmSpace">
                  <form autoComplete="off">
                    <div className="flex-row d-flex justify-content-center">
                      <div className="col-lg-12 col-12 px-1">
                        <div className="input-group input-daterange manage-job-date custom-input-daterange">
                          <input type="date"  className="form-control mr-2 width-100"   onChange={(date, event) => handleStartDate(event, date)} />
                          <input type="date"  className="form-control ml-2 width-100"   onChange={(date, event) => handleEndDate(event, date)} />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-3 col-sm-3 btmSpace">
                  <div className="styled-select">
                    <select onChange={(event) => handleChangeStatus(event)}  name="">
                      {
                        items.map((item, index) => {
                        return (
                          <option key={item.value} value={item.value}> {item.label} </option>
                        )
                        })
                      }
                    </select>
                    <span className="fa fa-angle-down"></span>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Job ID <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Vessel Name <span className="pull-right"></span></th>
                        <th>Port Of Service <span className="pull-right"></span></th>
                        <th>Date Of Service <span className="pull-right"></span></th>
                        <th>Assigned To <span className="pull-right"></span></th>
                        <th>Review Status <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        jobs.map((job, index) => {
                          return (
                            <tr key={job.id}>
                              <td>{index + 1}</td>
                              <td>{job.certificate_no}</td>
                              <td>{job.customer_name}</td>
                              <td>{job.vessel_name}</td>
                              <td>{job.service_port}</td>
                              <td>{job.service_date}</td>
                              <td>{job.assignee_name}</td>
                              {job.job_status === 'Pending' && (<td><span className="badge-warning">{job.job_status}</span></td>)}
                              {job.job_status === 'Pending For Approval' && (<td><span className="badge-warning">{job.job_status}</span></td>)}
                              {job.job_status === 'Pending For Review' && (<td><span className="badge-warning">{job.job_status}</span></td>)}
                              {job.job_status === 'Approved' && (<td><span className="badge-success">{job.job_status}</span></td>)}
                              {job.job_status === 'Rejected' && (<td><span>{job.is_rejected_by_customer === 1 && job.job_status === 'Rejected' ? ( <span> <span className="badge-danger"> Rejected By Customer </span> <i onClick={(event) => infoModalHandler(event, true, job.comment, job.rejected_by_user, job.customer_name) } className="fa fa-info-circle ml-1"></i> </span> ): ( <span className="badge-danger"> { job.job_status } </span>)}</span></td>)}
                              {job.job_status === 'Accepted By Customer' && (<td><span className="badge-success">{job.job_status}</span></td>)}
                              <td>
                                <Link to={`/manage-jobs/${job.id}/detail`} className="view-btn mr-1">View</Link>
                                <Link to={`/manage-jobs/${job.id}/edit`} className="view-btn">Edit</Link>
                                { job.pdf_link !== '' && <a target="_blank" href={job.pdf_link} className="view-btn ml-1"><i className='fa fa-download' style={{color: "#fff"}}></i></a> }
                               <Link to={`/document-information/${job.email_token === null ? job.id : job.email_token}`}>{ job.blockchain_status === 'completed' ? <img src={require('../../assets/img/completed.png').default} style={{marginLeft: 5}}/> : job.blockchain_status === 'in-progress' ? <img src={require('../../assets/img/In_progress.png').default} style={{marginLeft: 5}} /> :  <img src={require('../../assets/img/cross.png').default} style={{marginLeft: 5}} /> }</Link> 
                              </td>
                            </tr>
                          )
                        })
                      }
                    { jobs.length === 0 ? <tr><td colSpan='9' className='text-center'>No Record Found</td></tr> : null }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
            {jobs.length === 0 ? '' : <Pagination links={jobsLinks} onPageClick={loadJobs} />}
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default List;