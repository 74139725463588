import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import config from '../../config.json';
import axios from "axios";
import { getAPIHeaders } from '../../helpers/functions';
import ServiceChart from './JobReportTemplate/ServiceChart';
import RecommendationReport from './JobReportTemplate/RecomndationReport';
import CertificateOfInseption from './JobReportTemplate/CertificateOfInseption';
import ServiceReport from './JobReportTemplate/ServiceReport';
import TimeSheet from './JobReportTemplate/TimeSheet';
import CustomerFeedbackReport from './JobReportTemplate/CustomerFeedbackReport';
import FitnessFreeFailLifeBoat from './Lifeboats/FitnessFreefailLifeboat';
import FitnessConventional from './Lifeboats/FitnessConventional';
import FitnessRescueBoat from './Lifeboats/FitnessRescueBoat';
import ChecklistFreeFailLifeboat from './Lifeboats/ChecklistFreefailLifeboat';
import ChecklistRescueboat from './Lifeboats/ChecklistResceuboat';
import LifeboatEquipmentChecklist from './Lifeboats/LifeboatEquipmentChecklist';
import RescueEquipmentChecklist from './Lifeboats/RescueboatEquipemntChecklist';
import LifeBoatTestReport from './Lifeboats/LifeboatLoadTestReport';
import DavidLoadTestReport from './Lifeboats/DavitLoadTestReport';
import Co2CylinderTestingRecord from './LifeRafts/Co2CylinderTestingRecord';
import LiferaftsInspectionCertificate from './LifeRafts/LiferaftsInspectionCertificate';
import FireExtinguishers from './CertificateTemplates/FireExtinguishers';
import EebdAirCylinder from './CertificateTemplates/EebdAirCylinder';
import ScbasSet from './CertificateTemplates/ScbasSet';
import MedicalOxygenCylinder from './CertificateTemplates/MedicalOxygenCylinder';
import FoamApplicator from './CertificateTemplates/FoamApplicator';
import FiremanSuit from './CertificateTemplates/FiremanSuit';
import ImmersionSuit from './CertificateTemplates/ImmersionSuit';
import ChemicalSuit from './CertificateTemplates/ChemicalSuit';
import InflatableLifeJackets from './CertificateTemplates/InflatableLifeJackets';
import LifeJacketsFoamType from './CertificateTemplates/LifeJacketsFoamType';
import GasTightSuit from './CertificateTemplates/GasTightSuit';
import FixedCo2SystemCylinder from './FixedSystem/FixedCo2SystemCylinder';
import LowPressureCo2System from './FixedSystem/LowPressureCo2System';
import FixedFoamSystem from './FixedSystem/FixedFoamSystem';
import WaterMistLaffsSystem from './FixedSystem/WaterMistLaffsSystem';
import WetChemicalSystem from './FixedSystem/WetChemicalSystem';
import FixedDcpSystemCylinders from './FixedSystem/FixedDcpSystemCylinders';
import FixedFireSystem from './FixedSystem/FixedFireSystem';
import FmFixedSystemCylinder from './FixedSystem/FmFixedSystemCylinder';
import NovecFixedSystemCylinder from './FixedSystem/NovecFixedSystemCylinder';
import FireAlarmDetectionSystem from './FixedSystem/FireAlarmDetectionSystem';
import FixedNSystemMastRiserCylinder from './FixedSystem/FixedNSystemMastRiserCylinder';
import AerosolFireExtinguishingSystem from './FixedSystem/AerosolFireExtinguishingSystem';
import FixedGasDetectionSystem from './FixedSystem/FixedGasDetectionSystem';
import FixedGasWeldingSystemCylinder from './FixedSystem/FixedGasWeldingSystemCylinder';
import LifeBoatAirSystemCylinder from './FixedSystem/LifeBoatAirSystemCylinder';
import PortableGasDetectorCalibration from './Callibration/PortableGasDetectorCalibration';
import PressureVaccumGuageCalibration from './Callibration/PressureVaccumGuageCalibration';
import TemperatureCalibrator from './Callibration/TemperatureCalibrator';
import ThermometerTemperatureGuageCalibration from './Callibration/ThermometerTemperatureGuageCalibration';
import PressureCalibrator from './Callibration/PressureCalibrator';
import UtiGauge from './Callibration/UtiGauge';
import GasPumpLeakTest from './Callibration/GasPumpLeakTest';
import OdmeCalibration from './Callibration/ODME';
import OwsPpmBilgeAlarm from './Callibration/OWS15PPMBilgeAlarm';
import VECSCalibration from './Callibration/VECSCalibration';
import AlcoholMeterCalibration from './Callibration/AlcoholMeterCalibration';
import AneroidBarometer from './Callibration/AneroidBarometer';
import FlowMeterCalibration from './Callibration/FlowMeterCalibration';
import FoamConcentrateTest from './Testing/FoamConcentrateTest';
import DrinkingWaterTest from './Testing/DrinkingWaterTest';
import MoistureContentTest from './Testing/MoistureContentTest';
import AirQualityAnalysis from './Testing/AirQualityAnalysis';
import ScbaAirCylinder from './CertificateTemplates/ScbaAirCylinder';
import MedicalOxygenResuscitator from './CertificateTemplates/MedicalOxygenResuscitator';
import HighPressureCylinderTestingRecord from './CertificateTemplates/HighPressureCylinderTestingRecord';
import OxygenAnalyser from './Callibration/OxygenAnalyser';
import ChecklistConventionalboat from './Lifeboats/ChecklistConventionalboat';
import Catalog from './Catalog';

const FormMaster = (props) => {
    const { job_id, form_id } = useParams();
    const [formFields, setFormFields] = useState(null);
    const [form, setForm] = useState([]);
    const [role, setRole] = useState('');
    const [form_status, setFormStatus] = useState('');
    const [Component, setComponent] = useState(null);
    const [canEditForm, setCanEditForm] = useState(false);
    const [isRejectedByCustomer, setIsRejectedByCustomer] = useState("");

    const components = {
        1: ServiceChart,
        2: RecommendationReport,
        3: ServiceReport,
        4: TimeSheet,
        5: CustomerFeedbackReport,
        6: FitnessFreeFailLifeBoat,
        7: FitnessConventional,
        8: FitnessRescueBoat,
        9: ChecklistFreeFailLifeboat,
        10: ChecklistRescueboat,
        11: LifeboatEquipmentChecklist,
        12: RescueEquipmentChecklist,
        13: LifeBoatTestReport,
        14: DavidLoadTestReport,
        15: Co2CylinderTestingRecord,
        16: FireExtinguishers,
        17: EebdAirCylinder,
        18: ScbasSet,
        19: MedicalOxygenCylinder,
        20: FoamApplicator,
        21: FiremanSuit,
        22: ImmersionSuit,
        23: ChemicalSuit,
        24: InflatableLifeJackets,
        25: LifeJacketsFoamType,
        26: GasTightSuit,
        27: FixedCo2SystemCylinder,
        28: LowPressureCo2System,
        29: FixedFoamSystem,
        30: WaterMistLaffsSystem,
        31: WetChemicalSystem,
        32: FixedDcpSystemCylinders,
        33: FixedFireSystem,
        34: FmFixedSystemCylinder,
        35: NovecFixedSystemCylinder,
        36: FireAlarmDetectionSystem,
        37: FixedNSystemMastRiserCylinder,
        38: AerosolFireExtinguishingSystem,
        39: FixedGasDetectionSystem,
        40: FixedGasWeldingSystemCylinder,
        41: LifeBoatAirSystemCylinder,
        42: PortableGasDetectorCalibration,
        43: PressureVaccumGuageCalibration,
        44: ThermometerTemperatureGuageCalibration,
        45: PressureCalibrator,
        46: TemperatureCalibrator,
        47: UtiGauge,
        48: GasPumpLeakTest,
        // 49: Catalog,
        49: OdmeCalibration,
        50: OwsPpmBilgeAlarm,
        51: VECSCalibration,
        52: AlcoholMeterCalibration,
        53: AneroidBarometer,
        54: FlowMeterCalibration,
        55: FoamConcentrateTest,
        56: DrinkingWaterTest,
        57: MoistureContentTest,
        58: AirQualityAnalysis,
        59: ScbaAirCylinder,
        60: MedicalOxygenResuscitator,
        61: HighPressureCylinderTestingRecord,
        62: CertificateOfInseption,
        63: OxygenAnalyser,
        64: LiferaftsInspectionCertificate,
        65: ChecklistConventionalboat,
        66: Catalog,
    }

    useEffect(() => {
        loadForm();
        fetchForm();
    }, []);

    function fetchForm() {
        axios.get(config.BaseURL + "/v1/forms", { headers: getAPIHeaders() }).then(function (response) {
            setForm(response.data);
        }).catch(function (error) { });
    }

    function loadForm() {
        axios.get(config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id, { headers: getAPIHeaders() }).then(function (response) {
            if (response.data.form_data != null) {
                response.data.form_data.approver_name = response.data.approver_name;
                setFormFields(response.data.form_data);
            }

            setRole(response.data.role);
            setIsRejectedByCustomer(response.data.rejected_by_customer);
            if (response.data.role == 'approver' || response.data.role == 'reviewer') {
                setCanEditForm(true);
            } else if (response.data.role == 'assignee') {
                if (response.data.form_status != 'Pending' && response.data.form_status != 'Rejected') {
                    setCanEditForm(true);
                }
            }


            setFormStatus(response.data.form_status);
        }).catch(function (error) { });
    }

    let ComponentName = components[form_id || 7]

    return (<
        ComponentName formFields={formFields}
        role={role}
        form_status={form_status}
        can_edit_form={canEditForm}
        isRejectedByCustomer={isRejectedByCustomer}
    />
    )
}


export default FormMaster;