import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const DavitLoadTestReport = (props) => {


  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', boat_maker: '', boat_serial_no: '', boat_type: '', davit_maker: '', davit_serial_no: '', davit_type: '', weight_of_boat_including_equipment: '', weight_of_person: '', total_of_weight: '', over_load: '', total_tested_weight: '', load_test_observation_and_result: '', approver_name: '', service_engineer_sign: '', approved_by: '' });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Davit Load Test Report" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0" id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} disabled={props.can_edit_form} className="form-control" placeholder="SHM Control No" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} disabled={props.can_edit_form} className="form-control" placeholder="Vessel Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} disabled={props.can_edit_form} className="form-control" placeholder="Customer Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} disabled={props.can_edit_form} className="form-control" placeholder="Number" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} disabled={props.can_edit_form} className="form-control" placeholder="Customer PIC" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} disabled={props.can_edit_form} className="form-control" placeholder="Port of service" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} disabled={props.can_edit_form} id="start" className="form-control text-left mr-2" placeholder="From Date" />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td><strong>Boat</strong></td>
                        <td><strong>Maker :</strong><input onChange={handleChange} type="text" name="boat_maker" value={formFields['boat_maker']} disabled={props.can_edit_form} className="form-control" placeholder="Maker" /></td>
                        <td><strong>Serial No. :</strong><input onChange={handleChange} type="text" name="boat_serial_no" value={formFields['boat_serial_no']} disabled={props.can_edit_form} className="form-control" placeholder="Serial. No" /></td>
                        <td><strong>Type :</strong><input onChange={handleChange} type="text" name="boat_type" value={formFields['boat_type']} disabled={props.can_edit_form} className="form-control" placeholder="Type" /></td>
                      </tr>
                      <tr>
                        <td><strong>Davit</strong></td>
                        <td><strong>Maker :</strong><input onChange={handleChange} type="text" name="davit_maker" value={formFields['davit_maker']} disabled={props.can_edit_form} className="form-control" placeholder="Maker" /></td>
                        <td><strong>Serial No. :</strong><input onChange={handleChange} type="text" name="davit_serial_no" value={formFields['davit_serial_no']} disabled={props.can_edit_form} className="form-control" placeholder="Serial. No" /></td>
                        <td><strong>Type :</strong><input onChange={handleChange} type="text" name="davit_type" value={formFields['davit_type']} disabled={props.can_edit_form} className="form-control" placeholder="Type" /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">LOAD TEST PROCEDURE AS PER SOLAS Chapter III Regulation 20.11</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">WEIGHT CALCULATIONS</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>A</td>
                        <td>Weight of Boat including equipment </td>
                        <td><input onChange={handleChange} type="text" name="weight_of_boat_including_equipment" value={formFields['weight_of_boat_including_equipment']} disabled={props.can_edit_form} className="form-control" placeholder="KG" /></td>
                      </tr>
                      <tr>
                        <td>B</td>
                        <td>Weight of ____ Person  x 82.5 Kg</td>
                        <td><input onChange={handleChange} type="text" name="weight_of_person" value={formFields['weight_of_person']} disabled={props.can_edit_form} className="form-control" placeholder="KG" /></td>
                      </tr>
                      <tr>
                        <td>C</td>
                        <td>Total of Weight ( A + B )</td>
                        <td><input onChange={handleChange} type="text" name="total_of_weight" value={formFields['total_of_weight']} disabled={props.can_edit_form} className="form-control" placeholder="KG" /></td>
                      </tr>
                      <tr>
                        <td>D</td>
                        <td>10% Over Load ( C x 110%)</td>
                        <td><input onChange={handleChange} type="text" name="over_load" value={formFields['over_load']} disabled={props.can_edit_form} className="form-control" placeholder="KG" /></td>
                      </tr>
                      <tr>
                        <td>E</td>
                        <td>Total Tested Weight </td>
                        <td><input onChange={handleChange} type="text" name="total_tested_weight" value={formFields['total_tested_weight']} disabled={props.can_edit_form} className="form-control" placeholder="KG" /></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label>Load Test Observations And Results</label>
                  <textarea onChange={handleChange} name="load_test_observation_and_result" value={formFields['load_test_observation_and_result']} disabled={props.can_edit_form} id="" className="form-control" placeholder="Remarks" style={{ height: "100px" }}></textarea>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                {/* <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Company Address </h4>
                </div>
                <div className="col-lg-12 col-sm-12"><b>SHM SHIPCARE SDN BHD</b></div>
                <div className="col-lg-12 col-sm-12">No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                <div className="col-lg-12 col-sm-12"><b>Master/Chief Officer Endorsement</b></div>
                <div className="col-lg-12 col-sm-12"><b>Service Engineer</b></div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approver Name</label>
                    <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} disabled={props.can_edit_form} className="form-control" placeholder="Name" />
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approved By</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="approved_by" value={formFields['approved_by']}>
                        <option value="" selected="selected"> Select </option>
                        <option value="1"> Select</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div> */}
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} disabled={props.can_edit_form} />
                </div>
              </div>
            </div>
            <div class="row px-0 border mt-4">
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div class="mb-2"><strong>Company Address </strong></div>
                <div><b>SHM SHIPCARE SDN BHD</b></div>
                <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
              </div>
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div><b>Master/Chief Officer<br />Endorsement</b></div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div><b>Service Engineer</b></div>
                <div>
                  {/* <label class="mb-0">Name</label> */}
                  <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
                </div>
                <div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>

    </React.Fragment>
  );
}

export default DavitLoadTestReport;