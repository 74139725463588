import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';

const ConfirmModal = (props) => {
    
    let { job_id } = useParams();
    const { addToast } = useToasts();
    
    const style = {
        display: props.isModalVisible ? 'block' : 'none'
    };
    
   
    
    return (
        <React.Fragment>
        <div class="modal" id="sendmail"  aria-hidden="true" style={style}>
        <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
        <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLongTitle">Send Email</h3>
        <button onClick={(event) => {props.confirmModalAction(false)}} type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
        </button>
        </div>
        <div class="modal-body">
        <p>Do you want to send email to customer ?</p>
        </div>
        <div class="modal-footer">
        <Link onClick={(event) => {props.confirmModalHandler(event, 'yes')}} to="/" class="btn btn-primary">Yes</Link>
        <Link onClick={(event) => {props.confirmModalHandler(event, 'no')}} to="/" class="btn btn-secondary">No</Link>
        </div>
        </div>
        </div>
        </div>
        {
            props.isModalVisible && (
                <div className="modal-backdrop fade show"></div>
                )
            }
            </React.Fragment>
            );
        }
        
        export default ConfirmModal;