import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import AcceptDocumentModal from "./AcceptDocumentModal";
import RejectDocumentModal from "./RejectDocumentModal";
import { Link, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import Header from '../Shared/Header';
import { useToasts } from 'react-toast-notifications';



const Document = (props) => {
  const [documentInfo, setDocumentInfo] = useState({});
  const [Pdf, setPdf] = useState({});
  const [eventInfo, setEventInfo] = useState([]);
  const [acceptModalObject, setAcceptModalObject] = useState({ modalVisible: false });
  const [rejectModalObject, setRejectModalObject] = useState({ modalVisible: false });
  const [toggleState, setToggleState] = useState(1);
  const [buttonState, setButtonState] = useState(1);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  let { token } = useParams();
  let history = useHistory();
  const { addToast } = useToasts();

  let url = config.BaseURL + "/v1/view-document/" + token;
  let pdfurl = config.PdfURL + "/" + Pdf;
  let frameUrl = `https://docs.google.com/gview?url=${pdfurl}&embedded=true`

  useEffect(() => {
    // isRestricted();
    getDocumentInfo(url);
  }, []);

  // const isRestricted = () => {
  //   axios.get(config.BaseURL + "/v1/check-job-status/" + token, { headers: getAPIHeaders() } ).then(function (response) {
  //     if(response.data != 'Accepted By Customer' || response.data != 'Rejected' || response.data != 'Approved') {
  //       history.push('/view-document/' + token);
  //     }
  //  }).catch((error) => {
  //    addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
  //  })
  // }

  const AcceptmodalHandler = (event, modalVisible) => {
    event.preventDefault();
    setAcceptModalObject({
      modalVisible: modalVisible,
    });
    getDocumentInfo(url);
    setShowDownloadButton(true)
  }

  const RejectmodalHandler = (event, modalVisible) => {
    event.preventDefault();
    setRejectModalObject({
      modalVisible: modalVisible,
    });
    getDocumentInfo(url);
  }

  const toggleTab = (index) => {
    setToggleState(index);
  }

  function getDocumentInfo(url) {
    axios.get(url, { headers: getAPIHeaders() }).then(function (response) {
      console.log('responsedata', response.data);
      if (response.data.data.triggers.length == undefined) {
        var array = [];
        var index = 1;
        if (response.data.data && response.data.data.triggers && response.data.data.triggers[index]) {
          array.push(response.data.data.triggers[index])
        } else {
          return;
        }
        index++
        setEventInfo(array)
      } else {
        setEventInfo(response.data.data.triggers);
      }

      if (!response.data.data.job.document_approved) {
        response.data.data.job.document_approved = 'N'
      }

      setDocumentInfo(response.data.data.job);
      setPdf(response.data.data.pdf);
      if (response.data.data.final_job_status !== null) {
        setButtonState(0);
      }
    });
  }

  return (
    < React.Fragment >
      <div className="page home-page">
        <Header />
        {ReactDOM.createPortal(<AcceptDocumentModal JobId={documentInfo.id} isModalVisible={acceptModalObject.modalVisible} AcceptModalCloseHandler={AcceptmodalHandler} />, document.getElementById('modal-root'))}
        {ReactDOM.createPortal(<RejectDocumentModal JobId={documentInfo.id} isModalVisible={rejectModalObject.modalVisible} RejectModalCloseHandler={RejectmodalHandler} />, document.getElementById('modal-root'))}
        <section class="dashboard-counts no-padding-bottom">
          <div class="container-fluid catalog-page px-0">
            <div class="detail-section gerylightBg" id="vwDocument">
              <div class="container-fluid px-5">

                <div class="pull-right mb-3">
                  <div class="width150">
                    <div class="styled-select">
                      <select id="" name="" disabled>
                        <option selected> {documentInfo.is_block_chained === 1 ? ('Document Blockchained') : ('Pending For Blockchain')} </option>
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="clearfix">&nbsp;</div>

                <div class="row px-0 white-box">
                  <div class="col-lg-6 col-sm-6">
                    <div class="scrollvert">
                      <iframe src={frameUrl} title="description" width="100%" height="440px"></iframe>
                    </div>
                    <div class="text-center">
                      {documentInfo?.document_approved === 'N' ? <><button className={buttonState == 0 ? "blockchain-btn disable" : "blockchain-btn"} onClick={(event) => AcceptmodalHandler(event, true)} type="button" name="" data-target="#acceptModal" data-toggle="modal"><i class="fa fa-thumbs-up" aria-hidden="true"></i>&nbsp;
                        Accept</button>
                      <button className={buttonState == 0 ? "download-btn reject-btn disable" : "download-btn reject-btn"} onClick={(event) => RejectmodalHandler(event, true)} type="button" name="" data-target="#rejectedModal" data-toggle="modal">&nbsp; <i class="fa fa-thumbs-down" aria-hidden="true"></i>&nbsp; Reject </button></> : null}
                      {showDownloadButton === true || documentInfo.document_approved == 'Y' ? <a href={pdfurl} target="_blank" type="button" name="" class="downld-btttn" >&nbsp; <i class="fa fa-download" aria-hidden="true"></i>Download </a> : null}
                      {documentInfo.feedback_json === null && documentInfo.document_approved == 'Y' ? (<Link to={`/feedback/${token}`} target="_blank" name="" class="downld-btttn" >&nbsp; Give Feedback </Link>) : ''}
                    </div>
                  </div>
                  <div class="col-lg-6 col-sm-6">
                    <nav class="tab-box nav-tab-custom">
                      <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <Link onClick={() => toggleTab(1)} className={toggleState === 1 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-documentinfo-tab" data-toggle="tab" href="#nav-documentinfo" role="tab" aria-controls="nav-newjob" aria-selected="true">Document Info</Link>
                        <Link onClick={() => toggleTab(2)} className={toggleState === 2 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-pending-tab" data-toggle="tab" href="#nav-events" role="tab" aria-controls="nav-events" aria-selected="true">Events</Link>
                      </div>
                    </nav>
                    <div class="tab-content" id="nav-tabContent">
                      <div class={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-documentinfo" role="tabpanel" aria-labelledby="nav-documentinfo-tab">
                        <div class="row px-0">

                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">IJO/Ref No</div>
                            <div class="lg-title">{documentInfo.ref_no}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Certificate Number / Job ID - Customer Name</div>
                            <div class="md-title">{documentInfo.certificate_no} - {documentInfo.customer_name}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Customer PIC</div>
                            <div class="md-title">{documentInfo.customer_pic}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Customer Email ID</div>
                            <div class="md-title">{documentInfo.customer_email}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Port Of Service</div>
                            <div class="md-title">{documentInfo.service_port}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Date Of Service</div>
                            <div class="md-title">{documentInfo.service_date}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Vessel Name</div>
                            <div class="md-title">{documentInfo.vessel_name}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">IMO Number</div>
                            <div class="md-title">{documentInfo.imo_number}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Class</div>
                            <div class="md-title">{documentInfo.class_id}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Flag</div>
                            <div class="md-title">{documentInfo.flag_id}</div>
                          </div>
                          <div class="mb-4 col-lg-6 col-sm-6">
                            <div class="sm-title">Vessel Email ID</div>
                            <div class="md-title">{documentInfo.vessel_email}</div>
                          </div>
                        </div>
                      </div>
                      <div class={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-events" role="tabpanel" aria-labelledby="nav-events-tab">
                        <div class="table-responsive table-deck">
                          <table class="table table-striped mb-3 mt-4">
                            <thead>
                              <tr>
                                <th>Event Tracking</th>
                                <th>Date Time</th>
                                <th>Location</th>
                                <th>Email</th>
                                <th>Action</th>
                                <th>Description</th>
                                <th>Verify Link</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                eventInfo?.length == 0 ?
                                  null
                                  :
                                  eventInfo?.map((eventt, index) => {
                                    return (
                                      <tr key={eventt.id}>
                                        <td>{eventt.data_0}</td>
                                        <td>{eventt.blockchaintimestamp}</td>
                                        <td>{eventt.data_2}</td>
                                        <td><a href="#">{eventt.data_1}</a></td>
                                        <td>{eventt.data_0} By : {eventt.data_3}</td>
                                        <td></td>
                                        <td><a href={eventt.blockchainurl} class="view-btn" target="_blank"><i class="fa fa-link"></i> Verify</a></td>
                                      </tr>
                                    )
                                  })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment >
  );
}

export default Document;