import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const FixedGasDetectionSystem = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', technical_detail_make_type: '', technical_detail_no_location: '', technical_detail_modal: '', technical_detail_calibration: false, technical_detail_sr_no: '', technical_detail_inspection: '', calibration_results: [], service_engineer_name: '', service_engineer_sign: '' });
  const [inputFields, setInputFields] = useState([]);
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
    console.log(formFields.calibration_results);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const handleChangeInput = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.calibration_results[index][name] = event.target.value;
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.calibration_results.push({ technical_description_no: '', technical_description_location: '', technical_description_gas_detector: '', technical_description_range: '', technical_description_calibration_gas: '', technical_description_gas_concentration: '', technical_description_reading_before: '', technical_description_reading_after: '', technical_description_result: '' });
    setFormFields(_formFields);
  }
  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.calibration_results.splice(index, 1);
    setFormFields(values);
  }
  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Fixed Gas Detection System" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="email" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <label>Technical Details</label>
                  <div className="row p-0">
                    <table className="table table-responsive table-striped table-hover">
                      <tr>
                        <td>Make/Type</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_make_type" value={formFields['technical_detail_make_type']} className="form-control" placeholder="Make/Type" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>No. Of Locations</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_no_location" value={formFields['technical_detail_no_location']} className="form-control" placeholder="No. Of Locations" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_modal" value={formFields['technical_detail_modal']} className="form-control" placeholder="Model" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Calibration </td>
                        <td><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['technical_detail_calibration']} name="technical_detail_calibration" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Sr. No</td>
                        <td><input onChange={handleChange} type="text" name="technical_detail_sr_no" value={formFields['technical_detail_sr_no']} className="form-control" placeholder="Sr. No" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Inspection</td>
                        <td><input type="checkbox" onClick={checkboxHandleChange} checked={formFields['technical_detail_inspection']} name="technical_detail_inspection" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </table>
                  </div>
                </div>
                {/* <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Technical Description</h4>
                </div> */}
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Location</th>
                        <th>Type of Gas Detector</th>
                        <th>Range</th>
                        <th>Calibration Gas</th>
                        <th>Unit and Gas Concentration</th>
                        <th>Reading Before Adjustment</th>
                        <th>Reading After Adjustment</th>
                        <th>Result</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.calibration_results.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_no', index)} value={item['technical_description_no']} type="text" name="technical_description_no" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_location', index)} value={item['technical_description_location']} type="text" name="technical_description_location" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_gas_detector', index)} value={item['technical_description_gas_detector']} type="text" name="technical_description_gas_detector" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_range', index)} value={item['technical_description_range']} type="text" name="technical_description_range" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_calibration_gas', index)} value={item['technical_description_calibration_gas']} type="text" name="technical_description_calibration_gas" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_gas_concentration', index)} value={item['technical_description_gas_concentration']} type="text" name="technical_description_gas_concentration" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_reading_before', index)} value={item['technical_description_reading_before']} type="text" name="technical_description_reading_before" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_reading_after', index)} value={item['technical_description_reading_after']} type="text" name="technical_description_reading_after" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'technical_description_result', index)} value={item['technical_description_result']} type="text" name="technical_description_result" className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default FixedGasDetectionSystem;