import React, { useState, useEffect } from 'react';
import InnerHeader from '../Shared/InnerHeader';
import axios from 'axios';
import config from '../../config.json';
import { getCustomerBlankObject, getAPIHeaders, getEditProcessedObject } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';

const View = (props) => {

  const [toggleState, setToggleState] = useState(1);
  const [customerFields, setCustomerFields] = useState(getCustomerBlankObject());
  const { company_id } = useParams();
  const { addToast } = useToasts();
  

  useEffect(() => {
    loadCustomer();
  },[])

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const customerFieldsObject = {
      ...customerFields,
      [name]: value
    }
    setCustomerFields(customerFieldsObject);
  }

  const loadCustomer = () => {
    if (typeof company_id !== 'undefined') {
      axios.get(config.BaseURL + "/v1/customers/" + company_id, { headers: getAPIHeaders() }).then(function (response) {
        var response_ = getEditProcessedObject(response.data);
        setCustomerFields({ ...customerFields, ...response_ });
      });
    }
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var customerData = {...customerFields};
      axios.put(config.BaseURL + "/v1/customers/" + company_id, customerData, {
        headers: getAPIHeaders()
      }).then(function (response) {
        addToast("Customer Updated Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-customers");
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
  }

  const toggleTab = (index) => {
     setToggleState(index);
  }

  return (
    <React.Fragment>
      <InnerHeader title="Manage Customers" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <nav className="nav-tab-custom">
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a  onClick={() => toggleTab(1)} className={toggleState === 1 ? "nav-item nav-link active" : "nav-item nav-link"}  role="tab" aria-controls="nav-details" aria-selected="true">Details</a>
                  <a  onClick={() => toggleTab(2)} className={toggleState === 2 ? "nav-item nav-link active" : "nav-item nav-link"}  role="tab" aria-controls="nav-jobs" aria-selected="false">Jobs</a>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-details-tab">
                  <div className="col-lg-6 col-sm-6 floatNone">
                    <form name="" action="" method="" className="mt-4">
                      <div className="form-group">
                        <label>Name</label>
                        <input onChange={handleChange} type="text" name="name" value={customerFields['name']} className="form-control" placeholder="Name" />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input onChange={handleChange} type="text" name="email" value={customerFields['email']} className="form-control" placeholder="Email" />
                      </div>
                      <div className="form-group">
                        <label>Contact No.</label>
                        <input onChange={handleChange} type="text" name="contact_no" value={customerFields['contact_no']} className="form-control" placeholder="Contact No." />
                      </div>
                      <div className="form-group text-center mt-4">
                        <button onClick={submitHandler} type="submit" name="submit" value="Submit" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save </button>&nbsp;
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" aria-labelledby="nav-jobs-tab">
                  <div className="row px-0 m-l-r">
                    <div className="col-lg-4 col-sm-4 btmSpace">
                      <form className="w-100">
                        <div className="input-group border rounded-pill">
                          <input type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                          <div className="input-group-append border-0">
                            <button id="button-addon3" type="button" className="btn btn-link" onClick={loadCustomer} ><i className="fa fa-search"></i></button>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-5 col-sm-5 btmSpace">
                      <form autoComplete="off">
                        <div className="flex-row d-flex justify-content-center">
                          <div className="col-lg-12 col-12 px-1">
                            <div className="input-group input-daterange">
                              <input type="text" id="start" className="form-control text-left mr-2" placeholder="From Date" />
                              <span className="fa fa-calendar" id="fa-1"></span>
                              <input type="text" id="end" className="form-control text-left ml-2" placeholder="To Date" />
                              <span className="fa fa-calendar" id="fa-2"></span>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Job ID</th>
                        <th>Customer Name</th>
                        <th>Vessel Name</th>
                        <th>Port Of Service</th>
                        <th>Date Of Service</th>
                        <th>Service Engineer</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>2345</td>
                        <td>Alex Russio</td>
                        <td>Ship 678</td>
                        <td>Kochi</td>
                        <td>12/12/2021</td>
                        <td>James Smith</td>
                        <td><span className="badge-warning">Pending</span></td>
                        <td> <a href="com-approved.php" className="view-btn">View</a></td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="page-bottom-pagination text-right mt-4">
                    <nav aria-label="Page navigation example" className="d-inline-block">
                      <ul className="pagination">
                        <li className="page-item">
                          <a className="page-link" href="#ex" aria-label="Previous">
                            <span aria-hidden="true" className="mr-2">«</span>
                            <span className="sr-only ">Previous</span>
                            Previous
                          </a>
                        </li>
                        <li className="page-item "><a className="page-link active" href="#ex">1</a></li>
                        <li className="page-item"><a className="page-link" href="#ex">2</a></li>
                        <li className="page-item"><a className="page-link" href="#ex">3</a></li>
                        <li className="page-item">
                          <a className="page-link" href="#ex" aria-label="Next">
                            Next
                            <span aria-hidden="true" className="ml-2">»</span>
                            <span className="sr-only">Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default View;