import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from "react-toast-notifications";
import config from '../../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../../helpers/functions";
import FormButton from "../../Shared/FormButton";

const ServiceReport = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', work_scope: '', service_engineer_name: '', service_engineer_sign: '' });
  const { job_id, form_id } = useParams();
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(setFormFieldsObject);
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }
  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }


  return (
    <React.Fragment>
      <InnerHeader title="Service Report" />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid">
          <form name="" action="" autoComplete="off">
            <div class="bg-white has-shadow">
              <div class="row px-0 pb-0" id="newForm">
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} class="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} class="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} class="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} class="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} class="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} class="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Class</label>
                    <div class="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Flag</label>
                    <div class="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} class="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span class="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Work Scope</label>
                    <input onChange={handleChange} type="text" name="work_scope" value={formFields['work_scope']} class="form-control" placeholder="Annual Inspection of Lifeboat, davit &amp; winch" disabled={props.can_edit_form} />
                  </div>
                </div>
                <textarea id="txtEditor" style={{ display: "none" }}></textarea>
                <div class="row-fluid Editor-container">
                  <div id="menuBarDiv_txtEditor" class="row-fluid line-control-menu-bar">
                    <div class="btn-group">
                      <div class="btn-group" title="Fonts" style={{ cursor: "pointer" }}>
                        <a class="btn btn-default dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" title="Fonts">
                          Font
                          <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Sans serif</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Serif</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Wide</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Narrow</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Comic Sans MS</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Courier New</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Garamond</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Georgia</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Tahoma</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Trebuchet MS</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Verdana</a>
                          </li>
                        </ul>
                      </div>
                      <div class="btn-group" title="Paragraph Format" style={{ cursor: "pointer" }}>
                        <a class="btn btn-default dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" title="Paragraph Format">
                          Formatting
                          <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 1</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 2</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 3</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 4</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 5</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Heading 6</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Paragraph</a>
                          </li>
                        </ul>
                      </div>
                      <div class="btn-group" title="Font Size" style={{ cursor: "pointer" }}>
                        <a class="btn btn-default dropdown-toggle" data-toggle="dropdown" href="javascript:void(0)" title="Font Size">
                          Font size
                          <span class="caret"></span>
                        </a>
                        <ul class="dropdown-menu">
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Small</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Normal</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Medium</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Large</a>
                          </li>
                          <li>
                            <a tabindex="-1" href="javascript:void(0)">Huge</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Bold" style={{ cursor: "pointer" }}>
                        <i class="fa fa-bold"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Italics" style={{ cursor: "pointer" }}>
                        <i class="fa fa-italic"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Underline" style={{ cursor: "pointer" }}>
                        <i class="fa fa-underline"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Text/Background Color" style={{ cursor: "pointer" }}>
                        <i class="fa fa-font"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Align Left" style={{ cursor: "pointer" }}>
                        <i class="fa fa-align-left"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Align Center" style={{ cursor: "pointer" }}>
                        <i class="fa fa-align-center"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Align Right" style={{ cursor: "pointer" }}>
                        <i class="fa fa-align-right"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Justify" style={{ cursor: "pointer" }}>
                        <i class="fa fa-align-justify"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Increase Indent" style={{ cursor: "pointer" }}>
                        <i class="fa fa-indent"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Decrease Indent" style={{ cursor: "pointer" }}>
                        <i class="fa fa-outdent"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Block Quote" style={{ cursor: "pointer" }}>
                        <i class="fa fa-quote-right"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Insert/Remove Numbered List" style={{ cursor: "pointer" }}>
                        <i class="fa fa-list-ol"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Insert/Remove Bulleted List" style={{ cursor: "pointer" }}>
                        <i class="fa fa-list-ul"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Undo" style={{ cursor: "pointer" }}>
                        <i class="fa fa-undo"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Redo" style={{ cursor: "pointer" }}>
                        <i class="fa fa-repeat"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="#InsertLink_txtEditor" role="button" class="btn btn-default" data-toggle="modal" title="Insert Link">
                        <i class="fa fa-link"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Unlink" style={{ cursor: "pointer" }}>
                        <i class="fa fa-unlink"></i>
                      </a>
                      <a href="#InsertImage_txtEditor" role="button" class="btn btn-default" data-toggle="modal" title="Insert Image">
                        <i class="fa fa-picture-o"></i>
                      </a>
                      <a href="#InsertTable_txtEditor" role="button" class="btn btn-default" data-toggle="modal" title="Insert Table">
                        <i class="fa fa-table"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Strike Through" style={{ cursor: "pointer" }}>
                        <i class="fa fa-strikethrough"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Horizontal Rule" style={{ cursor: "pointer" }}>
                        <i class="fa fa-minus"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Insert Special Character" style={{ cursor: "pointer" }}>
                        <i class="fa fa-asterisk"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Print" style={{ cursor: "pointer" }}>
                        <i class="fa fa-print"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Remove Formatting" style={{ cursor: "pointer" }}>
                        <i class="fa fa-eraser"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Select All" style={{ cursor: "pointer" }}>
                        <i class="fa fa-file-text"></i>
                      </a>
                      <a href="javascript:void(0)" class="btn btn-default" title="Source" style={{ cursor: "pointer" }}>
                        <i class="fa fa-code"></i>
                      </a>
                    </div>
                    <div class="btn-group">
                      <a href="javascript:void(0)" class="btn btn-default" title="Toggle Screen" style={{ cursor: "pointer" }}>
                        <i class="fa fa-arrows-alt"></i>
                      </a>
                    </div>
                  </div>
                  <div class="Editor-editor" contenteditable="true" style={{ overflow: "auto" }}></div>
                  <div id="statusbar_txtEditor" class="row-fluid line-control-status-bar" unselectable="on">
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ServiceReport;