import React, { useEffect, useState } from "react";
import { getAPIHeaders } from '../../helpers/functions';
import config from '../../config.json';
import axios from "axios";
import { useToasts } from 'react-toast-notifications';

const ChangePassword = (props) => {

  const [formFields, setFormFields] = useState({ old_password: '', password: '', password_confirmation: '' })
  const { addToast } = useToasts();

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  useEffect(() => {
    setFormFields({ old_password: '', password: '', password_confirmation: '' });
  }, [props.isModalVisible]);

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/update-password", formFields, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      resetForm(event);
    }).catch((error) => {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div className="modal " id="reasonModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Change Password</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="">
                <div className="form-group">
                  <label>Current Password</label>
                  <input onChange={handleChange} autoComplete="off" type="password" value={ formFields.old_password } name="old_password" className="form-control" placeholder="Current Password" />
                </div>
                <div className="form-group">
                  <label>New Password</label>
                  <input onChange={handleChange} autoComplete="off" type="password" value={ formFields.password } name="password" className="form-control" placeholder="New Password" />
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  <input onChange={handleChange} autoComplete="off" type="password" value={ formFields.password_confirmation } name="password_confirmation" className="form-control" placeholder="Confirm Password" />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={submitHandler} type="button" className="btn btn-primary"><i className="fa fa-save"></i>&nbsp; Save</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default ChangePassword;