import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from '../../Shared/FormButton';

const FoamApplicator = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', performed_1: false, not_performed_1: '', performed_2: false, not_performed_2: '', performed_3: false, not_performed_3: '', performed_4: false, not_performed_4: '', performed_5: false, not_performed_5: '', make: '', model: '', type: '', serial_no: '', service_engineer_name: '', service_engineer_sign: '', foam_drum_list: [] });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.foam_drum_list[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.foam_drum_list.push({ maker_1: '', size_1: '', manufacture_date_1: '', expiry_date_1: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.foam_drum_list.splice(index, 1);
    setFormFields(values);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: checked
    }
    setFormFields(setFormFieldsObject);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Foam Applicator" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form} >
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>Description of Inspection / Tests:</th>
                        <th>Checked</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Box</td>
                        <td><input type="checkbox" name="performed_1" onClick={checkboxHandleChange} checked={formFields['performed_1']} className="mr-2" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="not_performed_1" value={formFields['not_performed_1']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Hose</td>
                        <td><input type="checkbox" name="performed_2" onClick={checkboxHandleChange} checked={formFields['performed_2']} className="mr-2" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="not_performed_2" value={formFields['not_performed_2']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>Connector</td>
                        <td><input type="checkbox" name="performed_3" onClick={checkboxHandleChange} checked={formFields['performed_3']} className="mr-2" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="not_performed_3" value={formFields['not_performed_3']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>Discharge Nozzle</td>
                        <td><input type="checkbox" name="performed_4" onClick={checkboxHandleChange} checked={formFields['performed_4']} className="mr-2" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="not_performed_4" value={formFields['not_performed_4']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>Foam Tanks</td>
                        <td><input type="checkbox" name="performed_5" onClick={checkboxHandleChange} checked={formFields['performed_5']} className="mr-2" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="not_performed_5" value={formFields['not_performed_5']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Foam Applicator Details</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Make</th>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Serial Numbers</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><input onChange={handleChange} type="text" name="make" value={formFields['make']} className="form-control" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="model" value={formFields['model']} className="form-control" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="type" value={formFields['type']} className="form-control" disabled={props.can_edit_form} /></td>
                        <td><input onChange={handleChange} type="text" name="serial_no" value={formFields['serial_no']} className="form-control" disabled={props.can_edit_form} /></td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Foam Drum Details</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Maker</th>
                        <th>Size</th>
                        <th>Manufacture Date</th>
                        <th>Expiry Date</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.foam_drum_list.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows(event, 'maker_1', index)} type="text" name="maker_1" value={item['maker_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'size_1', index)} type="text" name="size_1" value={item['size_1']} className="form-control" disabled={props.can_edit_form} /></td>
                              <td><div className="input-group input-daterange">
                                <input onChange={(event) => handleChangeRows(event, 'manufacture_date_1', index)} type="date" name="manufacture_date_1" value={item['manufacture_date_1']} id="md" className="form-control" placeholder="Manufacture Date" style={{ fontSize: "15px" }} disabled={props.can_edit_form} />
                                {/* <span className="fa fa-calendar" id="fa-2" style={{ marginTop: "-3px" }}></span> */}
                              </div></td>
                              <td><div className="input-group input-daterange">
                                <input onChange={(event) => handleChangeRows(event, 'expiry_date_1', index)} type="date" name="expiry_date_1" value={item['expiry_date_1']} id="ed" className="form-control" placeholder="Expiry Date" style={{ fontSize: "15px" }} disabled={props.can_edit_form} />
                                {/* <span className="fa fa-calendar" id="fa-2" style={{ marginTop: "-3px", left: "calc(100% - 50px)" }}></span> */}
                              </div></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default FoamApplicator;