import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const HighPressureCylinderTestingRecord = (props) => {

  const [formFields, setFormFields] = useState({ discription_system: '', service_engineer_name: '', service_engineer_sign: '', all_forms: [] });

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      setFormFields(formObject);
    }
  }, [props.formFields]);

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.all_forms[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addMakeRow = () => {
    const _formFields = { ...formFields };
    _formFields.all_forms.push({ type_of_gas_cylinder_1: '', cylinder_serial_no_1: '', last_test_date_1: '', date_tested_1: '', cylinder_volume_kg_1: '', tare_weight_kg_1: '', gross_weight_kg_1: '', check_weight_1: '', testing_pressure_bar_1: '', working_pressure_bar_1: '', temp_1: '', working_code_1: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.all_forms.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="High Pressure Cylinder Testing Record" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Description/ System</label>
                    <input onChange={handleChange} type="text" name="discription_system" value={formFields['discription_system']} className="form-control" placeholder="Description/ System" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Working Codes</label>
                    <div class="row p-0">
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tbody><tr>
                            <td>1</td>
                            <td>External inspection and Maintenance</td>
                          </tr>
                            <tr>
                              <td>2</td>
                              <td>Contents checked by weight</td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>Contents checked by pressure </td>
                            </tr>
                            <tr>
                              <td>4</td>
                              <td>Contents checked by level </td>
                            </tr>
                            <tr>
                              <td>5</td>
                              <td>Recharging</td>
                            </tr>

                          </tbody></table>
                      </div>
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tbody><tr>
                            <td>6</td>
                            <td>Hydrostatic test</td>
                          </tr>
                            <tr>
                              <td>7</td>
                              <td>Valve renewal</td>
                            </tr>
                            <tr>
                              <td>8</td>
                              <td>Valve repair</td>
                            </tr>
                            <tr>
                              <td>9</td>
                              <td>Condemned</td>
                            </tr>
                            <tr>
                              <td>10</td>
                              <td>New cylinder</td>
                            </tr>
                          </tbody></table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Forms</h4>
                </div>
                <div className="col-lg-12 col-sm-12" style={{ overflowX: 'auto' }}>
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type of Gas Cylinder</th>
                        <th>Cylinder Serial Number</th>
                        <th>Last Test Date</th>
                        <th>Date Tested</th>
                        <th>Cylinder Volume (KG)</th>
                        <th>Tare Weight (KG)</th>
                        <th>Gross Weight (KG)</th>
                        <th>Check weight</th>
                        <th> Testing Pressure (Bar)</th>
                        <th> Working Pressure (Bar)</th>
                        <th>Temp °C / °F</th>
                        <th>Working Code</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.all_forms.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'type_of_gas_cylinder_1', index)} name="type_of_gas_cylinder_1" value={item['type_of_gas_cylinder_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'cylinder_serial_no_1', index)} name="cylinder_serial_no_1" value={item['cylinder_serial_no_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'last_test_date_1', index)} name="last_test_date_1" value={item['last_test_date_1']} type="date" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'date_tested_1', index)} name="date_tested_1" value={item['date_tested_1']} type="date" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'cylinder_volume_kg_1', index)} name="cylinder_volume_kg_1" value={item['cylinder_volume_kg_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'tare_weight_kg_1', index)} name="tare_weight_kg_1" value={item['tare_weight_kg_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'gross_weight_kg_1', index)} name="gross_weight_kg_1" value={item['gross_weight_kg_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'check_weight_1', index)} name="check_weight_1" value={item['check_weight_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'testing_pressure_bar_1', index)} name="testing_pressure_bar_1" value={item['testing_pressure_bar_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'working_pressure_bar_1', index)} name="working_pressure_bar_1" value={item['working_pressure_bar_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'temp_1', index)} name="temp_1" value={item['temp_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows(event, 'working_code_1', index)} name="working_code_1" value={item['working_code_1']} type="text" className="form-control" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button type="button" onClick={(event) => handleRemoveFields(event, index)} className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default HighPressureCylinderTestingRecord;