import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import InnerHeader from '../Shared/InnerHeader';
import config from '../../config.json';
import axios from "axios";
import Avatar from '../../assets/img/company-logo.png';
import JobTemplates from './JobTemplates/JobTemplates';
import { appendObjectInFormData, getAPIHeadersForFile, getAPIHeaders, getEditProcessedObject } from '../../helpers/functions';
import CompanyUsers from './CompanyUsers/CompanyUsers';
import Forms from './Forms/Forms';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';

const ManageCompany = (props) => {

  const [formFields, setFormFields] = useState({ role_id: 2, name: '', contact_person: '', email: '', password: '', password_confirmation: '', contact_no: '', tenant_id: 1, block_chain: 'y', parent_company_id: '', });
  const [tenants, setTenants] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [toggleState, setToggleState] = useState(1);
  const { company_id } = useParams();
  const [profileImage, setProfileImage] = useState('');
  const { addToast } = useToasts();

  const toggleTab = (index) => {
    setToggleState(index);
  }

  const handleChange = (event) => {
    if (event.target.name !== 'block_chain') event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    if (typeof company_id !== 'undefined') {
      /** Updating logo image */
      if (profileImage !== '') {
        let formData = new FormData();
        if (profileImage !== '') formData.append('logo_image', profileImage, profileImage.name);
        formData.append('company_id', company_id);
        axios.post(config.BaseURL + "/v1/update-company-logo", formData, { headers: getAPIHeaders() }).then(function (response) {
          addToast("Company logo Updated Successfully!", { appearance: 'success', autoDismiss: true });
        }).catch(function (error) {
          addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
        });
      }
      /** Edit mode */
      axios.put(config.BaseURL + "/v1/companies/" + company_id, formFields, { headers: getAPIHeaders() }).then(function (response) {
        addToast("Company Updated Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-companies");
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    } else {
      let formData = new FormData();
      if (profileImage !== '') formData.append('logo_image', profileImage, profileImage.name);
      formData = appendObjectInFormData(formData, formFields);
      axios.post(config.BaseURL + "/v1/companies", formData, { headers: getAPIHeadersForFile() }).then(function (response) {
        addToast("Company Added Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-companies");
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    }
  }

  const loadCompany = () => {
    if (typeof company_id !== 'undefined') {
      axios.get(config.BaseURL + "/v1/companies/" + company_id, { headers: getAPIHeaders() }).then(function (response) {
        var response_ = getEditProcessedObject(response.data);
        setFormFields({ ...formFields, ...response_ });
        if (response.data.logo !== '') {
          document.getElementById("preview").src = response.data.logo;
        }
      });
    }
  }

  const loadTenants = () => {
    axios.get(config.BaseURL + "/v1/tenants", { headers: getAPIHeaders() }).then(function (response) {
      let tenantsArray = [];
      response.data.forEach(element => {
        tenantsArray.push(element);
      });
      setTenants(tenantsArray);
    });
  }

  const loadCompanies = () => {
    axios.get(config.BaseURL + "/v1/companies", { headers: getAPIHeaders(), params: { all: true } }).then(function (response) {
      let companiesArray = [];
      if (typeof company_id !== 'undefined') {
        response.data.forEach(element => {
          if (element.id !== company_id) companiesArray.push(element);
        });
      } else {
        response.data.forEach(element => {
          companiesArray.push(element);
        });
      }
      setCompanies(companiesArray);
    });
  }

  useEffect(() => {
    loadTenants();
    loadCompanies();
    loadCompany();
  }, []);

  const dropdownHandleChange = (event) => {
    setFormFields({
      ...formFields,
      tenant_id: event.target.value
    });
  }
  const dropdownParentCompanyChange = (event) => {
    setFormFields({
      ...formFields,
      parent_company_id: event.target.value
    });
  }

  const style = {
    display: 'flex',
    borderBottom: '1px solid #ddd',
  }

  const style1 = {
    background: '#868e96',
  }

  const fileChosenHandler = (event) => {
    setProfileImage(event.target.files[0]);
    var reader = new FileReader();
    reader.onload = function (e) {
      document.getElementById("preview").src = e.target.result;
    }
    reader.readAsDataURL(event.target.files[0]);
  }

  const openFileManager = (event) => {
    document.getElementById('logo_image').click();
  }

  return (
    <React.Fragment>
      <InnerHeader title={`${props.location.pathname === '/manage-companies/add' ? 'ADD' : 'EDIT'}`} hasAction={false} />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <nav style={style}>
                <div className="nav nav-tabs nav-fill custom-nav-tabs" id="nav-tab" role="tablist">
                  <a onClick={() => toggleTab(1)} className={toggleState === 1 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-basicinfo-tab">Basic info</a>
                  {
                    props.location.pathname !== '/manage-companies/add' &&
                    <React.Fragment>
                      <a onClick={() => toggleTab(2)} className={toggleState === 2 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-pending-tab">Admin</a>
                      { config.isJobTemplateVisible && <a onClick={() => toggleTab(3)} className={toggleState === 3 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-template-tab">Job Template</a> }
                      { config.isJobTemplateVisible && <a  onClick={() => toggleTab(4)} className={toggleState === 4 ? "nav-item nav-link active" : "nav-item nav-link"} id="nav-forms-tab">Forms</a> }
                    </React.Fragment>
                  }
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div className={toggleState === 1 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-basicinfo" role="tabpanel" aria-labelledby="nav-basicinfo-tab">
                  <div className="col-lg-6 col-sm-6 floatNone mt-4">
                    <form name="" action="" method="">
                      <div className="form-group d-none">
                        <label>Tenant</label>
                        <div className="styled-select">
                          <select name="" onChange={dropdownHandleChange} value={formFields['tenant_id']}>
                            <option value="">Select Tenant</option>
                            {
                              tenants.map((tenantObject, index) => {
                                return <option key={index} value={tenantObject.id}>{tenantObject.name}</option>
                              })
                            }
                          </select>
                          <span className="fa fa-angle-down"></span>
                        </div>
                      </div>
                      <div className="form-group text-center">
                        <img src={Avatar} id="preview" className="imgRadius" alt="" />
                        <div id="image-form">
                          <input onChange={fileChosenHandler} type="file" id="logo_image" name="logo_image" className="file display-n" accept="image/*" />
                          <div className="my-3">
                            <input type="text" className="form-control display-n" disabled placeholder="Upload File" id="file" />
                            <div onClick={openFileManager} className="browse">Change Logo</div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Company Name</label>
                        <input type="text" name="name" onChange={handleChange} value={formFields['name']} className="form-control" placeholder="Company Name" />
                      </div>
                      <div className="form-group">
                        <label>Select Parent Company</label>
                        <div className="styled-select">
                          <select name="" onChange={dropdownParentCompanyChange} value={formFields['parent_company_id']}>
                            <option value="">N/A</option>
                            {
                              companies.map((companyObject, index) => {
                                return <option key={index} value={companyObject.id}>{companyObject.name}</option>
                              })
                            }
                          </select>
                          <span className="fa fa-angle-down"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Contact Person</label>
                        <input type="text" name="contact_person" onChange={handleChange} value={formFields['contact_person']} className="form-control" placeholder="Contact Person" />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input type="text" name="email" onChange={handleChange} value={formFields['email']} className="form-control" placeholder="Email" />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input type="password" name="password" onChange={handleChange} value={formFields['password']} className="form-control" placeholder="Password" />
                      </div>
                      <div className="form-group">
                        <label>Confirm Password</label>
                        <input type="password" name="password_confirmation" onChange={handleChange} value={formFields['password_confirmation']} className="form-control" placeholder="Confirm Password" />
                      </div>
                      <div className="form-group">
                        <label>Contact No.</label>
                        <input type="text" name="contact_no" onChange={handleChange} value={formFields['contact_no']} className="form-control" placeholder="Contact No." />
                      </div>
                      <div className="form-group">
                        <label className="mr-3">Blockchain</label>
                        <input checked={formFields['block_chain'] === 'y'} type="radio" onChange={handleChange} value='y' name="block_chain" /> Enable &nbsp; &nbsp;
                        <input checked={formFields['block_chain'] === 'n'} type="radio" onChange={handleChange} value='n' name="block_chain" /> Disable
                      </div>
                      {
                        validationError &&
                        <div className="alert alert-danger" role="alert">
                          {validationError}
                        </div>
                      }
                      <div className="text-center mt-4">
                        <button type="button" onClick={submitHandler} name="submit" value="Submit" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save </button>&nbsp; &nbsp;
                        <Link to="/manage-companies" type="button" className="btn btn-secondary" data-dismiss="modal" style={style1}><i className="fa fa-close">&nbsp; </i>Cancel</Link>
                      </div>
                    </form>
                  </div>
                </div>
                {
                  props.location.pathname !== '/manage-companies/add' &&
                  <React.Fragment>
                    <div className={toggleState === 2 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-adminusers" role="tabpanel" aria-labelledby="nav-adminusers-tab">
                      <CompanyUsers companyId={company_id} />
                    </div>
                    <div className={toggleState === 3 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-template" role="tabpanel" aria-labelledby="nav-template-tab">
                      <JobTemplates companyId={company_id} />
                    </div>
                    <div className={toggleState === 4 ? "tab-pane fade show active" : "tab-pane fade"} id="nav-forms" role="tabpanel" aria-labelledby="nav-forms-tab">
                      <Forms companyId={company_id} />
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ManageCompany;