import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";


const FiremanSuite = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', maker_1: '', serial_no_1: '', manufacture_date_1: '', working_codes_1: '', maker_2: '', serial_no_2: '', manufacture_date_2: '', working_codes_2: '', make_middle_list_1: [], inspection_list_1: [], inspection_list_2: [], service_engineer_name: '', service_engineer_sign: '' });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows1 = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.make_middle_list_1[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addMakeRow = () => {
    const _formFields = { ...formFields };
    _formFields.make_middle_list_1.push({ maker_1: '', serial_no_1: '', manufacture_date_1: '', working_codes_1: '' });
    setFormFields(_formFields);
  }
  const addMakeRow1 = () => {
    const _formFields = { ...formFields };
    _formFields.inspection_list_1.push({ puncture_resistance_exposure_suit_1: false, helmate_1: false, boots_1: false, gloves_1: false, fire_fighting_belt_1: false, axe_1: false, crowbar_1: false, comments_1: '' });
    setFormFields(_formFields);
  }
  const addMakeRow2 = () => {
    const _formFields = { ...formFields };
    _formFields.inspection_list_2.push({ fireproof_lifesaving_line_1: false, explosion_prrof_flashlight_1: false, spare_batteries_1: false, explosion_proof_tech_1: false, breathing_apparatus_1: false, fireman_outfit_radio_1: false, bag_storage_box_1: false, comments_4: '' });
    setFormFields(_formFields);
  }

  const checkboxHandleChange1 = (event, index) => {
    const { name, checked } = event.target;
    const _formFields = { ...formFields };
    _formFields.inspection_list_1[index][name] = checked;
    setFormFields(_formFields);
  }
  const handleChangeRows2 = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.inspection_list_1[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }


  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.inspection_list_1.splice(index, 1);
    setFormFields(values);
  }

  const checkboxHandleChange2 = (event, index) => {
    const { name, checked } = event.target;
    const _formFields = { ...formFields };
    _formFields.inspection_list_2[index][name] = checked;
    setFormFields(_formFields);
  }

  const handleRemoveFields1 = (event, index) => {
    const values = { ...formFields };
    values.inspection_list_2.splice(index, 1);
    setFormFields(values);
  }

  const handleChangeRows3 = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.inspection_list_2[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }


  return (
    <React.Fragment>
      <InnerHeader title="Fireman Suit" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12">
                  <div className="form-group">
                    <label>Working Codes</label>
                    <div className="row p-0">
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>External maintenance  and Inspection</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Testing</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Repair</td>
                          </tr>
                        </table>
                      </div>
                      <div className="col-lg-6 col-sm-6 px-2">
                        <table className="table table-responsive table-striped table-hover">
                          <tr>
                            <td>4</td>
                            <td>Parts replacement  </td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>New Supply  </td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Condemned</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Maker</th>
                        <th>Serial No.</th>
                        <th>Manufacture Date</th>
                        <th>Working codes</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.make_middle_list_1.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows1(event, 'maker_1', index)} type="text" name="maker_1" value={item['maker_1']} className="form-control" placeholder="Maker" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows1(event, 'serial_no_1', index)} type="text" name="serial_no_1" value={item['serial_no_1']} className="form-control" placeholder="Maker" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows1(event, 'manufacture_date_1', index)} type="date" name="manufacture_date_1" value={item['manufacture_date_1']} className="form-control" placeholder="Serial No" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows1(event, 'working_codes_1', index)} type="text" name="working_codes_1" value={item['working_codes_1']} className="form-control" placeholder="Working Codes" autocomplete="off" disabled={props.can_edit_form} /></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3 mt-3">
                  <h4 className="mb-0">Description of Inspections / Tests / Set</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Serial No.</th>
                        <th>Puncture Resistant / Exposure Suits</th>
                        <th>Helmet</th>
                        <th>Boots</th>
                        <th>Gloves</th>
                        <th>Fire Fighting Belt</th>
                        <th>Axe</th>
                        <th>Crowbar</th>
                        <th>Comments</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.inspection_list_1.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              <td className="text-center"><input type="checkbox" name="puncture_resistance_exposure_suit_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['puncture_resistance_exposure_suit_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="helmate_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['helmate_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="boots_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['boots_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="gloves_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['gloves_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="fire_fighting_belt_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['fire_fighting_belt_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="axe_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['axe_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="crowbar_1" onClick={(event) => checkboxHandleChange1(event, index)} checked={item['crowbar_1']} autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input onChange={(event) => handleChangeRows2(event, 'comments_1', index)} type="text" name="comments_1" value={item['comments_1']} className="form-control" placeholder="Satisfactory" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow1()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 my-4">
                  <h4 className="mb-0">Description of Inspections / Tests / Set</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Serial No.</th>
                        <th>Fireproof lifesaving line (30M)</th>
                        <th>Explosion Proof Flashlight</th>
                        <th>Spare Batteries</th>
                        <th>Explosion Proof Torch</th>
                        <th>Breathing Apparatus</th>
                        <th>Fireman Outfit Radio</th>
                        <th>Bag / Storage Box</th>
                        <th>Comments</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.inspection_list_2.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td>{index + 1}</td>
                              <td className="text-center"><input type="checkbox" name="fireproof_lifesaving_line_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['fireproof_lifesaving_line_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="explosion_prrof_flashlight_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['explosion_prrof_flashlight_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="spare_batteries_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['spare_batteries_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="explosion_proof_tech_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['explosion_proof_tech_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="breathing_apparatus_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['breathing_apparatus_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="fireman_outfit_radio_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['fireman_outfit_radio_1']} disabled={props.can_edit_form} /></td>
                              <td className="text-center"><input type="checkbox" name="bag_storage_box_1" onClick={(event) => checkboxHandleChange2(event, index)} checked={item['bag_storage_box_1']} disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows3(event, 'comments_4', index)} type="text" name="comments_4" value={item['comments_4']} className="form-control" placeholder="Satisfactory" autocomplete="off" disabled={props.can_edit_form} /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields1(event, index)} type="button" className="removeBtn"><i className="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div className="text-left disable-button-custom">
                    <button onClick={() => addMakeRow2()} type="button" className="btn btn-success mr-2" disabled={props.can_edit_form}><i className="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} autocomplete="off" disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment >
  );
}
export default FiremanSuite;