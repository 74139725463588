import React from 'react';
import { Bar } from 'react-chartjs-2';


const NoOfJobsChart = (props) => {
  const data = {
    labels: props.classes,
    datasets: [
      {
        label: 'Value',
        data: props.classChartData,
        backgroundColor: [
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
        ],
        borderColor: [
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
          'rgba(92, 118, 228, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
  <Bar
    data={data}
    options={options}
  />
  )
}

export default NoOfJobsChart;