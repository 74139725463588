import React, { useState, useEffect, useRef } from 'react';
import AddEditForm from '../Forms/AddEditform';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config.json';
import { getAPIHeaders } from '../../../helpers/functions';
import { getFormBlankObject, getFormsObject } from '../../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import Pagination from "../../Shared/Pagination";

const Forms = (props) => {

  const [modalObject, setModalObject] = useState({ modalVisible: false });
  const [forms, setForms] = useState([]);
  const [formsLinks, setFormsLinks] = useState([]);
  const [editableObject, setEditableObject] = useState(getFormBlankObject());
  const { addToast } = useToasts();
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  let url = config.BaseURL + "/v1/companies/" + props.companyId + "/forms";

  useEffect(() => {
    loadForms(url);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchForm();
  }

  const searchForm = () => {
    setSearchTerm(searchtermRef.current.value);
  }

  const makeFormDefault = (event, form) => {
    const { checked } = event.target
    const index = forms.findIndex(item => item.id === form.id)
    if (index !== -1) {
      //call to make them default
      const _forms = [ ...forms ]
      _forms[index].default_checked = checked
      setForms(_forms)
      axios.put(config.BaseURL + "/v1/forms/" + form.id + "/mark-default", { default_checked: checked }, { headers: getAPIHeaders() }).then(function (response) {
        addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      }).catch((error) => {
        addToast('Something went wrong!', { appearance: 'error', autoDismiss: true });
      })
    }
  }

  function loadForms(url) {
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, customOnly: true } }).then(function (response) {
      setForms(response.data.data);
      setFormsLinks(response.data.links);
    });
  }

  const addFormHandler = (event) => {
    event.preventDefault();
    setModalObject({
      modalVisible: true,
    });
  }

  const editFormHandler = (event, object) => {
    event.preventDefault();
    setEditableObject(object);
    setModalObject({
      modalVisible: true
    });
  }

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setEditableObject(getFormsObject());
    setModalObject({
      modalVisible: modalVisible,
    });
  }

  const deleteForm = (event, id) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(config.BaseURL + "/v1/companies/" + props.companyId + "/forms/" + id, { headers: getAPIHeaders() }).then(function (response) {
        addToast("Form deleted successfully!", { appearance: 'success', autoDismiss: true });
        loadForms(url);
      });
    }
  }

  return (
      <React.Fragment>
        { ReactDOM.createPortal(<AddEditForm refreshList={() => loadForms(url)} editableObject={editableObject} isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} companyId={props.companyId} />, document.getElementById('modal-root'))}
        <div className="col-lg-12 col-sm-12 px-0 mt-4">
          <div className="row px-0 m-l-r py-0">
            <div className="col-lg-4 col-sm-4 btmSpace">
              <form className="w-100">
                <div className="input-group border rounded-pill">
                  <input onKeyDown={handleKeyDown} type="search" ref={searchtermRef} placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                  <div className="input-group-append border-0">
                    <button id="button-addon3" onClick={searchForm} type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-8 col-sm-8 text-right">
              <a href="#" onClick={(event) => addFormHandler(event)} className="news-job-btn"><i className="fa fa-plus"></i>&nbsp; Add</a>
            </div>
            <div className="col-lg-12 col-sm-12 mt-3">
              <table className="table table-responsive table-striped table-hover mb-0">
                <thead>
                <tr>
                  <th>No. <span className="pull-right"><i className="fa fa-sort-up" /><i className="fa fa-sort-down" /></span></th>
                  <th>Default Form?</th>
                  <th>Form Name <span className="pull-right"><i className="fa fa-sort-up" /><i className="fa fa-sort-down" /></span></th>
                  <th>Type <span className="pull-right"><i className="fa fa-sort-up" /><i className="fa fa-sort-down" /></span></th>
                  <th>Category <span className="pull-right"><i className="fa fa-sort-up" /><i className="fa fa-sort-down" /></span></th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {
                  forms.map((object, index) => {
                    return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td><input type="checkbox" onChange={(event) => makeFormDefault(event, object)} checked={object.default_checked}/></td>
                          <td>{object.name}</td>
                          <td>{object.type}</td>
                          <td>{object.category}</td>
                          <td>
                            <a href="#" className="download-btn bg-text-color-2a78e4-fff" onClick={(event) => editFormHandler(event, object)}><i className="fa fa-edit" /></a>&nbsp;
                            <a style={{ 'margin-right': '3px'}} onClick={(event) => deleteForm(event, object.id)} href="#" className="share-alt bg-color-cf2a2d"><i className="fa fa-trash" /></a>
                            <Link to={`/manage-companies/${props.companyId}/forms/${object.id}/fields`} className="view-btn">View Fields</Link>
                          </td>
                        </tr>
                    )
                  })
                }
                { forms.length === 0 ? <tr><td colSpan='6' className='text-center'>No Record Found</td></tr> : null }
                </tbody>
              </table>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
              {forms.length === 0 ? '' : <Pagination links={formsLinks} onPageClick={loadForms} />}
            </nav>
          </div>
        </div>
      </React.Fragment>
  );
}

export default Forms;