import React, { useEffect, useState } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './components/Auth/Login';
import ManageCompanies from './components/Companies/List';
import ManageCompany from './components/Companies/ManageCompany';
import Dashboard from './components/Dashboard/Index';
import Header from './components/Shared/Header';
import Footer from './components/Shared/Footer';
import Sidebar from './components/Shared/Sidebar';
import Profile from './components/Companies/Profile';
import Settings from './components/Companies/Settings';
import ManageUsers from './components/Users/List';
import AddUsers from './components/Users/Add';
import ManageCustomers from './components/Customers/List';
import ManageCustomer from './components/Customers/ManageCustomer';
import ManageViewCustomer from './components/Customers/View';
import ManageJobs from './components/Jobs/List';
import AddJob from './components/Jobs/Add';
import ManageJobDetail from './components/Jobs/Detail';
import ViewCustomer from './components/Customers/View';
import ManageClasses from './components/Classes/List';
import ManageFlags from './components/Flags/List';
import Fields from './components/Companies/Forms/Fields';
import NewJob from './components/ManageJob/New';
import ViewJob from './components/ManageJob/View';
import CertificateJob from './components/ManageJob/Certificate';
import ApprovedJob from './components/ManageJob/Approved';
import ApprovedViewJob from './components/ManageJob/ApprovedView';
import PdfJob from './components/ManageJob/Pdf';
import PendingJob from './components/ManageJob/Pending';
import PendingViewJob from './components/ManageJob/PendingView';
import RejectedViewJob from './components/ManageJob/RejectedView';
import '../src/assets/css/bootstrap.min.css';
import '../src/assets/css/custom.css';
import '../src/assets/css/fontastic.css';
import '../src/assets/css/style.default.css';
import '../src/assets/css/datepicker.css';
import '../src/assets/css/editor.css';
import '../src/assets/vendor/font-awesome/css/font-awesome.min.css';
import ResetPassword from './components/Auth/ResetPassword';
import FormMaster from './components/Forms/FormMaster';
import ManageJobMaster from './components/Jobs/ManageJobMaster';
import { getUserType } from './helpers/functions';
import VerifiedSuccessfully  from './components/Blockchain/VerifiedSuccessfully';
import Document  from './components/Blockchain/Document';
import CustomerFeedbackReport from './components/Forms/JobReportTemplate/CustomerFeedbackReport';
import ThankYou from './components/Shared/ThankYou';


const App = (props) => {
  const [sidebarState, setSideState] = useState('side-navbar height-auto');
  const [contentState, setContentState] = useState('content-inner');
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null ? true : false);
 
  useEffect(() => {
    signInSuccessHandler();
  }, []);

  const getRedirectionPath = () => {
    let userType = getUserType();
    if (userType === 'super_admin') return '/manage-companies';
    if (userType === 'company_admin') return '/dashboard';
    if (userType === 'company_user') return '/manage-jobs';
  }

  const signInSuccessHandler = () => {
    const token = localStorage.getItem('token');
    setLoggedIn((token !== null) ? true : false);
  }

  const onLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('menu');
    localStorage.removeItem('user_type');
    setLoggedIn(false);
  }

  const forSidebar = () => {
    if (sidebarState !== 'side-navbar height-auto shrinked') setSideState('side-navbar height-auto shrinked');
    else setSideState('side-navbar height-auto');
    if (contentState !== 'content-inner active') setContentState('content-inner active');
    else setContentState('content-inner');
  }

  return (
    <HashRouter>
      <React.Fragment>
        {
          isLoggedIn ?
            (
              <React.Fragment>
                <div className="page home-page">
                  <Header toShow={true} logoutHandler={onLogout} sideBarHandler={forSidebar} />
                  <div className="page-content d-flex align-items-stretch min-height-100-vh">
                    <Sidebar logoutHandler={onLogout} sidebarState={sidebarState} />
                    <div className={contentState}>
                      <Switch>
                        <Route exact path="/manage-companies" component={ManageCompanies} />
                        <Route exact path="/manage-companies/add" component={ManageCompany} />
                        <Route exact path="/manage-companies/:company_id/forms/:form_id/fields" component={Fields} />
                        <Route exact path="/manage-companies/:company_id/edit" component={ManageCompany} />
                        <Route exact path="/settings" component={Settings} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/manage-jobs" component={ManageJobMaster} />
                        <Route exact path="/manage-jobs/:job_id/detail" component={ManageJobDetail} />
                        <Route exact path="/manage-jobs/add" component={AddJob} />
                        <Route exact path="/manage-jobs/:job_id/edit" component={AddJob} />
                        <Route exact path="/manage-users" component={ManageUsers} />
                        <Route exact path="/manage-users/add" component={AddUsers} />
                        <Route exact path="/manage-customers" component={ManageCustomers} />
                        <Route exact path="/manage-customers/add" component={ManageCustomer} />
                        <Route exact path="/manage-customers/:customer_id/edit" component={ManageCustomer} />
                        <Route exact path="/manage-customers/:company_id/edit-job" component={ManageViewCustomer} />
                        <Route exact path="/manage-customers/view" component={ViewCustomer} />
                        <Route exact path="/manage-class" component={ManageClasses} />
                        <Route exact path="/manage-flag" component={ManageFlags} />
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/manage-job" component={ManageJobMaster} />
                        <Route exact path="/manage-job/new" component={NewJob}/>
                        <Route exact path="/manage-job/:job_id/detail" component={ManageJobDetail}/>
                        <Route exact path="/manage-job/certificate" component={CertificateJob}/>
                        <Route exact path="/manage-job/approved" component={ApprovedJob}/>
                        <Route exact path="/manage-job/approved-view" component={ApprovedViewJob}/>
                        <Route exact path="/manage-job/Pending" component={PendingJob}/>
                        <Route exact path="/manage-job/pdf" component={PdfJob}/>
                        <Route exact path="/manage-job/pending-view" component={PendingViewJob}/>
                        <Route exact path="/manage-job/rejected-view" component={RejectedViewJob}/>
                        <Route exact path="/manage-jobs/:job_id/detail/forms/:form_id" component={FormMaster} />
                        <Redirect from="/login" to={{ pathname: getRedirectionPath() }} />
                        <Route exact path="/view-document/:token" component={VerifiedSuccessfully} />
                        <Route exact path="/document-information/:token" component={Document} />
                        <Route exact path="/feedback/:token" component={CustomerFeedbackReport} />
                        <Route exact path="/thankyou/:token" component={ThankYou} />
                      </Switch>
                      <Footer />
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
            :
            (
              <Switch>
                <Route exact path="/view-document/:token" component={VerifiedSuccessfully} />
                <Route exact path="/document-information/:token" component={Document} />
                <Route exact path="/feedback/:token" component={CustomerFeedbackReport} />
                <Route exact path="/thankyou/:token" component={ThankYou} />
                <Route exact path="/login">
                  <Login signInSuccess={signInSuccessHandler} />
                </Route>
                <Route exact path="/reset-password/:token" component={ResetPassword} />
                <Redirect from="/" to="/login" />
              </Switch>
            )
        }
      </React.Fragment>
    </HashRouter>
  );
}

export default App;
