import React from 'react';

const Pagination = (props) => {
  const onPageClick = (event, url) => {
    event.preventDefault();
    if (url === null) return;
    props.onPageClick(url);
  }

  return (
    <React.Fragment>
      <ul className="pagination">
        {
          props.links.map((object, index) => {
            return (
              <React.Fragment key={index}>
                { index === 0 && <li onClick={(event) => onPageClick(event, object.url)} className={`page-item ${object.url == null ? 'disabled' : ''}`}><a className="page-link" href="#" aria-label="Previous"><span aria-hidden="true" className="mr-2">«</span><span className="sr-only">Previous</span>Previous</a></li>}
                { index !== 0 && index !== props.links.length - 1 && <li onClick={(event) => onPageClick(event, object.url)} className="page-item "><a className={`page-link ${object.active ? 'active' : ''}`} href="#">{object.label}</a></li>}
                { index === props.links.length - 1 && <li onClick={(event) => onPageClick(event, object.url)} className={`page-item ${object.url == null ? 'disabled' : ''}`}><a className="page-link" href="#" aria-label="Next">Next<span aria-hidden="true" className="ml-2">»</span><span className="sr-only">Next</span></a></li>}
              </React.Fragment>
            )
          })
        }
      </ul>
    </React.Fragment>
  )
}

export default Pagination;