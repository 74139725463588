import React, { useState, useEffect, useRef } from 'react';
import InnerHeader from "../Shared/InnerHeader";
import LoadingBar from '../Shared/LoadingBar';
import InputComponent from '../Jobs/Input';
import { getAPIHeaders, getJobsBlankObject, getFieldNameFromName, getFieldValueFromJSON } from '../../helpers/functions';
import axios from 'axios';
import config from '../../config.json';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
// import { Typeahead } from 'react-bootstrap-typeahead';
import SuggestionsListComponent from './SuggestionsListComponent';
import moment from "moment";

const Add = (props) => {
  // var Typeahead = require('react-bootstrap-typeahead').Typeahead;
  // const options = [
  //   {id: 1, label: 'John'},
  //   {id: 2, label: 'Miles'},
  //   {id: 3, label: 'Charles'},
  //   {id: 4, label: 'Herbie'},
  // ];

  const [isLoading, setIsLoading] = useState(false);
  const [jobfields, setJobFields] = useState(getJobsBlankObject());
  const [jobTemplateFields, setJobTemplateFields] = useState([]);
  const [companyForms, setCompanyForms] = useState([]);
  const [jobJSON, setJobJSON] = useState("{}");
  const [classField, setClassfield] = useState([]);
  const [flags, setFlags] = useState([]);
  const [documentCategory, setDocumentCategory] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [jobId, setJobId] = useState('');
  const [jobFormState, setJobFormState] = useState({ jobFormVisible: true, formFormVisible: false });
  const [users, setUsers] = useState([]);
  let { job_id } = useParams();
  const { addToast } = useToasts();
  const [settings, updateSettings] = useState([]);
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState('');
  const [type, setType] = useState('');
  const [checkedForms, setCheckedForms] = useState([]);

  /**Auto Complete customer name */
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestion] = useState([]);
  const [input, setInput] = useState("");

  /** */
  let existingForms = [];


  let url = config.BaseURL + "/v1/job-fields?all=true";
  let jobURL = config.BaseURL + "/v1/jobs";
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let getJobUrl = config.BaseURL + "/v1/jobs";
  let companyFormsURL = config.BaseURL + "/v1/forms";
  let userURL = config.BaseURL + "/v1/company-users";
  let categoryURL = config.BaseURL + "/v1/document-categories?all=true";
  let typeURL = config.BaseURL + "/v1/document-types?all=true";
  let validateJob = config.BaseURL + "/v1/jobs/validate";

  useEffect(() => {
    if (job_id == undefined) {
      generateRefNo();
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
    loadUsers(userURL);
    loadJob(jobURL);
    loadSettings();
    loadDocumentCategory(categoryURL);
    loadDocumentType(typeURL);
    loadCustomerDetails();
  }, []);

  useEffect(() => {
    loadCompanyForms(companyFormsURL);
  }, [searchTerm, category, type]);


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchJob();
  }
  const loadCustomerDetails = () => {
    axios.get(config.BaseURL + "/v1/customers?all", { headers: getAPIHeaders() }).then(function (response) {
      setSuggestion(response.data);
    }).catch((error) => {
      console.log(error)
    });
  }
  const searchJob = () => {
    setSearchTerm(searchtermRef.current.value);
  }
  const generateRefNo = () => {
    axios.get(config.BaseURL + "/v1/generate-ref-no", { headers: getAPIHeaders() }).then(function (response) {
      const newJobFields = { ...jobfields }
      newJobFields.ref_no = response.data;
      setJobFields(newJobFields);
    }).catch((error) => {
    });
  }

  function loadSettings() {
    let settingsArray = {};
    axios.get(config.BaseURL + "/v1/settings", { headers: getAPIHeaders() }).then(function (response) {
      response.data.forEach((element) => {
        settingsArray[element.meta_key.split(' ').join('')] = element.meta_value == 'true' ? true : false;
      });
      updateSettings(settingsArray);
    }).catch((error) => {
    });
  }

  const loadJob = () => {
    if (typeof job_id !== 'undefined') {
      axios.get(jobURL + '/' + job_id + '/details', { headers: getAPIHeaders() }).then(function (response) {
        setJobJSON(response.data.job_json);
        const checkedForms_ = [...checkedForms];
        response.data.forms.forEach((element) => {
          checkedForms_.push(element.form_id);
        });
        setCheckedForms(checkedForms_);
        let newData = { ...jobfields, ...response.data };
        newData.form_ids = checkedForms_;
        newData.service_date = moment(response.data.service_date,'DD/MM/YYYY').format('YYYY-MM-DD')
        console.log("newData.service_date",newData.service_date)
        setJobFields({ ...newData });
        loadJobTemplateFields(url);
        loadCompanyForms(companyFormsURL);
      })
    } else {
      loadJobTemplateFields(url);
      loadCompanyForms(companyFormsURL);
    }
  }

  const Back = (event) => {
    event.preventDefault();
    setJobFormState({ jobFormVisible: true, formFormVisible: false })
  }

  const setTemplateFieldValue = (key, value) => {
    const jobFieldsObject = { ...jobfields, [key]: value };
    setJobFields(jobFieldsObject);
  }

  function loadJobTemplateFields(url) {
    axios.get(url, { headers: getAPIHeaders() }).then(function (response) {
      setJobTemplateFields(response.data);
      console.log("Job templates loaded");
    });
  }

  function loadCompanyForms(url) {
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm, document_category: category, document_type: type } }).then(function (response) {
      let forms = response.data;
      if (typeof job_id !== 'undefined') {
        forms.forEach(element => {
          if (existingForms.includes(element.id)) element.default_checked = 1;
          else element.default_checked = 0;
        });
      }
      setCompanyForms(forms);
    }).catch((error) => { });
  }

  const loadUsers = (userURL) => {
    axios.get(userURL, { headers: getAPIHeaders() }).then(function (response) {
      setUsers(response.data.data);
    })
  }

  const onCheck = (event, formId) => {
    const { checked } = event.target
    let jobData = { ...jobfields };
    let formIds_ = [...jobData.form_ids]
    let checkedForms_ = [...checkedForms];
    if (checked) {
      formIds_.push(formId);
      checkedForms_.push(formId)
    } else {
      formIds_ = formIds_.filter(item => item !== formId);
      checkedForms_ = checkedForms_.filter(item => item !== formId);
    }
    jobData.form_ids = formIds_;
    setJobFields(jobData);
    setCheckedForms(checkedForms_)
    // var companyFormsArray = [...companyForms];
    // const { checked } = event.target
    // let checkedForms_ = [...checkedForms]
    // if (checked) checkedForms_.push(+formId)
    // else checkedForms_ = checkedForms_.filter(id => id !== formId)
    // console.log("Hola-->", checkedForms_);
    // setCheckedForms(checkedForms_);
    // companyFormsArray.forEach(function (companyForm) {
    //   if (companyForm.id === formId) companyForm.default_checked = !companyForm.default_checked;
    // })
    // setCompanyForms(companyFormsArray);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      setClassfield(response.data);
    });
  }

  function loadFlags() {
    axios.get(flagURL, { headers: getAPIHeaders() }).then(function (response) {
      setFlags(response.data);
    });
  }
  function loadDocumentCategory() {
    axios.get(categoryURL, { headers: getAPIHeaders() }).then(function (response) {
      setDocumentCategory(response.data);
    });
  }
  function loadDocumentType() {
    axios.get(typeURL, { headers: getAPIHeaders() }).then(function (response) {
      setDocumentType(response.data);
    });
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    const jobObject = {
      ...jobfields,
      [name]: value
    }
    setJobFields(jobObject);
  }

  const checkboxHandleChange = (event) => {
    const { name, checked } = event.target;
    const jobObject = {
      ...jobfields,
      [name]: checked ? 'Y' : 'N'
    }
    setJobFields(jobObject);
  }



  const handleChangeCategory = (event) => {
    var category = event.target.value;
    setCategory(category);
  }
  const handleChangeType = (event) => {
    var type = event.target.value;
    setType(type);
  }

  useEffect(() => {
    FilterForms()
  }, [searchtermRef, category, type])

  const FilterForms = () => {
    const companyForms_ = [...companyForms];
    console.log('ola category = ', category, 'type = ', type);
    // let forms = companyForms_.filter((item) => {
    //   if (category && !type) item.documentCategoryId === category
    //   if (type && !category) item.documentTypeId === type
    //   if (type && category) item.documentCategoryId === category && item.documentTypeId === type
    //   item.id > 0
    // });
    // console.log('ola forms', forms);
  }

  const Continue = (event) => {
    event.preventDefault();
    var jobData = { ...jobfields };
    jobData.service_date = moment(jobData.service_date).format('YYYY-MM-DD')
    axios.post(validateJob, jobData, { headers: getAPIHeaders() }).then((response) => {
      setJobFormState({ jobFormVisible: false, formFormVisible: true });
      console.log("asd",job_id , jobData)
      if (job_id === undefined && jobData['has_catalog'] === 'N') {
        setCheckedForms([]);
      } else if (job_id === undefined && jobData['has_catalog'] === 'Y') {
        const checkedForms_ = [...checkedForms];
        companyForms.forEach(element => {
          if (element.default_checked) checkedForms_.push(+element.id)
        });
        setCheckedForms(checkedForms_);
      }
    }).catch((error) => {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    })

  }
  const checkForm = (form_id) => {
    const jobData = { ...jobfields };
    if (jobData['has_catalog'] === 'N' && job_id === 'undefined') {
      return true;
    } else if (checkedForms.includes(+form_id)) {
      return true;
    } else {
      return false;
    }
  }

  const checkAll = (event) => {
    const { checked } = event.target
    const checkedForms_ = [];
    const jobData = { ...jobfields };
    const companyFroms_ = [...companyForms];
    if (checked) {
      companyFroms_.map(item => {
        checkedForms_.push(item.id)
      });
      jobData.form_ids = checkedForms_
    } else {
      jobData.form_ids = checkedForms_
    }
    setJobFields(jobData);
    setCheckedForms(checkedForms_);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var jobData = { ...jobfields };
    jobData.form_ids = { ...checkedForms };
    if (job_id) {
      axios.put(getJobUrl + '/' + job_id, jobData, { headers: getAPIHeaders() }).then((response) => {
        addToast("Job Saved Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-jobs");
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    } else {
      axios.post(getJobUrl, jobData, { headers: getAPIHeaders() }).then((response) => {
        addToast("Job Saved Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-jobs");
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    }

  }

  const onChange = (e) => {
    const userInput = e.target.value;
    const unLinked = suggestions.filter((suggestion) =>
      suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );
    const jobObject = {
      ...jobfields,
      ['customer_name']: e.target.value
    }
    setJobFields(jobObject);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };
  console.log("date",jobfields['service_date'])

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      <InnerHeader title="New Job" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className={jobFormState.jobFormVisible === true ? 'row px-0 pb-0' : 'row px-0 pb-0 display-n'} id="newForm">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>I JO/Ref No</label>
                    <input onChange={handleChange} type="text" name="ref_no" value={jobfields['ref_no']} className="form-control" placeholder="IJO/Ref No" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Certificate Number / Job ID</label>
                    <input onChange={handleChange} type="text" name="certificate_no" value={jobfields['certificate_no']} className="form-control" placeholder="Certificate Number / Job Id" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group" style={{ position: 'relative' }}>
                    <label>Customer Name</label>
                    <input onChange={onChange} type="text" name="customer_name" value={jobfields['customer_name']} className="form-control" placeholder="Customer Name" autoComplete="abc" />
                    {showSuggestions && jobfields['customer_name'] && <SuggestionsListComponent filteredSuggestions={filteredSuggestions} activeSuggestionIndex={activeSuggestionIndex} setFilteredSuggestions={setFilteredSuggestions} setActiveSuggestionIndex={setActiveSuggestionIndex} setShowSuggestions={setShowSuggestions} setJobFields={setJobFields} jobfields={jobfields} />}
                  </div>
                </div>
                {/* <div className="col-lg-6 col-sm-6">
                <div className="form-group">
                  <label>Certificate Number / Job ID</label>
                  <Typeahead
                  id="pagination-example"
                  maxResults={false}
                  options={options}
                  paginate={false}
                  placeholder="Pick a number..."
                  
                  />
                </div>
              </div> */}
                {/* <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={jobfields['customer_name']} className="form-control" placeholder="Customer Name" />
                  </div>
                </div> */}
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={jobfields['customer_pic']} className="form-control" placeholder="Customer Pic" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Email ID</label>
                    <input onChange={handleChange} type="email" name="customer_email" value={jobfields['customer_email']} className="form-control" placeholder="Customer Email Id" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port Of Service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={jobfields['service_port']} className="form-control" placeholder="Port of service" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date Of Service</label>
                    <div className="input-group input-daterange custom-input-daterange">
                      <input onChange={handleChange} type="date" name="service_date" value={jobfields['service_date']} className="form-control" placeholder="Date of service" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={jobfields['vessel_name']} className="form-control" placeholder="Vessel Name" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={jobfields['imo_number']} className="form-control" placeholder="IMO number" />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="class_id" value={jobfields['class_id']}>
                        <option value=""> Select Class </option>
                        {
                          classField.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="flag_id" value={jobfields['flag_id']}>
                        <option value="">Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Email ID</label>
                    <input onChange={handleChange} type="email" name="vessel_email" value={jobfields['vessel_email']} className="form-control" placeholder="Vessel Email Id" />
                  </div>
                </div>
                {
                  jobTemplateFields.map((field, index) => {
                    return <InputComponent readOnly={false} key={index} jobTemplateValue={getFieldValueFromJSON(jobJSON, getFieldNameFromName(field))} field={field} changeHandler={setTemplateFieldValue} />
                  })
                }
                <div className="col-lg-6 col-sm-6 mt-2">
                  <div className="form-group">
                    <label>Set catalog as default</label>
                    <input onChange={checkboxHandleChange} type="checkbox" name="has_catalog" className="ml-2" checked={jobfields['has_catalog'] === 'Y'} />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12"><hr /></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Assigned To</label>
                    <div className="styled-select">
                      <select onChange={handleChange} name="assigned_to" value={jobfields['assigned_to']}>
                        <option value=""> Select </option>
                        {
                          users.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                {
                  settings.ReviewedBy &&
                  <div className="col-lg-4 col-sm-4">
                    <div className="form-group">
                      <label>Reviewed By</label>
                      <div className="styled-select">
                        <select onChange={handleChange} name="reviewed_by" value={jobfields['reviewed_by']}>
                          <option value=""> Select </option>
                          {
                            users.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>{item.name} </option>
                              )
                            })
                          }
                        </select>
                        <span className="fa fa-angle-down"></span>
                      </div>
                    </div>
                  </div>
                }
                {
                  settings.ApprovedBy &&
                  <div className="col-lg-4 col-sm-4">
                    <div className="form-group">
                      <label>Approved By</label>
                      <div className="styled-select">
                        <select onChange={handleChange} name="approved_by" value={jobfields['approved_by']}>
                          <option value=""> Select </option>
                          {
                            users.map((item, index) => {
                              return (
                                <option key={index} value={item.id}>{item.name}</option>
                              )
                            })
                          }
                        </select>
                        <span className="fa fa-angle-down"></span>
                      </div>
                    </div>
                  </div>
                }
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-right">
                  <a href="#" className="continue-btn" onClick={Continue}> Continue &nbsp;<i className="fa fa-arrow-right"></i></a>
                </div>
              </div>
              <div className={jobFormState.formFormVisible === true ? 'row px-0' : 'row px-0 display-n'} id="saveForm">
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="md-font">Select Form</div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchtermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchJob} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <div className="styled-select">
                      <select onChange={(event) => handleChangeCategory(event)} name="">
                        <option value=""> Document Category </option>
                        {
                          documentCategory.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <div className="styled-select">
                      <select onChange={(event) => handleChangeType(event)} name="">
                        <option value=""> Document Type </option>
                        {
                          documentType.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-2">
                  <table className="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th><input type="checkbox" onChange={checkAll} className="mr-1" />Select</th>
                        <th>Form Title</th>
                        <th>Document Category</th>
                        <th>Document Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        companyForms.map((companyForm, index) => {
                          const jobData = { ...jobfields };
                          return (
                            <tr key={index}>
                              <td><input type="checkbox" name="form_ids[]" onClick={(event) => onCheck(event, companyForm.id)} value={companyForm.id} checked={checkedForms.includes(+companyForm.id)} /></td>
                              <td>{companyForm.name}</td>
                              <td>{companyForm.category_name}</td>
                              <td>{companyForm.document_type}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 text-right">
                  <Link to='/' onClick={Back} className="continue-btn color-868e96"><i className="fa fa-angle-left"></i>&nbsp; Back</Link>&nbsp;
                  <Link to='/' onClick={submitHandler} className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Add;