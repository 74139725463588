import React from 'react';
import { Bar } from 'react-chartjs-2';


const TotalJobsChart = (props) => {
  const monthChartData = props.monthChartData;
  const monthChartValues = props.monthChartValues;
  const data = {
    labels: monthChartData,
    datasets: [
      {
        label: 'Value',
        data: monthChartValues,
        backgroundColor: [
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
        ],
        borderColor: [
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
          'rgba(237, 77, 52, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  return (
  <Bar
    data={data}
    options={options}
  />
  )
}

export default TotalJobsChart;