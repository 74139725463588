import React, {useEffect, useState} from 'react';
import InnerHeader from "../Shared/InnerHeader";
import axios from 'axios';
import config from '../../config.json';
import { getCustomerBlankObject, getAPIHeaders, getEditProcessedObject } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const Add = (props) => {

  const [customerFields, setCustomerFields] = useState(getCustomerBlankObject());
  const { customer_id } = useParams();
  const { addToast } = useToasts();

  useEffect(() => {
    loadCustomer();
  },[])

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const customerFieldsObject = {
      ...customerFields,
      [name]: value
    }
    setCustomerFields(customerFieldsObject);
  }

  const loadCustomer = () => {
    if (typeof customer_id !== 'undefined') {
      axios.get(config.BaseURL + "/v1/customers/" + customer_id, { headers: getAPIHeaders() }).then(function (response) {
        var response_ = getEditProcessedObject(response.data);
        setCustomerFields({ ...customerFields, ...response_ });
      });
    }
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var customerData = {...customerFields};
    if (!customerData.hasOwnProperty("id")) {
       axios.post(config.BaseURL + "/v1/customers", customerData, { headers: getAPIHeaders() }).then((response) => {
        addToast("Customer Added Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-customers");
       }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      }); 
    } else {
      axios.put(config.BaseURL + "/v1/customers/" + customer_id, customerData, {
        headers: getAPIHeaders()
      }).then(function (response) {
        addToast("Customer Updated Successfully!", { appearance: 'success', autoDismiss: true });
        props.history.push("/manage-customers");
      }).catch(function (error) {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    }
  }

  return (
    <React.Fragment>
      <InnerHeader title={typeof customer_id !== 'undefined' ? 'Edit Customer' : 'ADD CUSTOMER'} />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-6 col-sm-6 floatNone">
              <form name="" action="" method="">
                <div className="form-group">
                  <label>Name</label>
                  <input onChange={handleChange} type="text" name="name" value={customerFields['name']} className="form-control" placeholder="Name" />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input onChange={handleChange} type="text" name="email" value={customerFields['email']} className="form-control" placeholder="Email" />
                </div>
                <div className="form-group">
                  <label>Contact No.</label>
                  <input onChange={handleChange} type="text" name="contact_no" value={customerFields['contact_no']} className="form-control" placeholder="Contact No." />
                </div>
                <div className="form-group text-center">
                  <button onClick={submitHandler} type="button" name="submit" value="Submit" className="continue-btn"><i className="fa fa-save"></i>&nbsp; Save </button>&nbsp;
                  <Link to='/manage-customers' type="button" className="btn btn-secondary color-868e96" data-dismiss="modal"><i className="fa fa-close">&nbsp; </i>Cancel</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default Add;