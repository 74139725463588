import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import InnerHeader from "../Shared/InnerHeader";
import LoadingBar from '../Shared/LoadingBar';
import axios from "axios";
import config from "../../config.json";
import AddEditClass from "../Classes/AddEdit";
import { getAPIHeaders, getClassBlankObject } from '../../helpers/functions';
import { useToasts } from 'react-toast-notifications';
import Pagination from '../Shared/Pagination';

const List = (props) => {
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalObject, setModalObject] = useState({ modalVisible: false });
  const [editableObject, setEditableObject] = useState(getClassBlankObject());
  const searchTermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { addToast } = useToasts();
  const [classLinks, setClassLinks] = useState([]);

  let url = config.BaseURL + "/v1/classes";
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }

  useEffect(() => {
    fetchClasses(url);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchClasses();
  }

  const searchClasses = () => {
    setSearchTerm(searchTermRef.current.value);
  }

  function fetchClasses(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm } }).then(function (response) {
      let classArray = [];
      response.data.data.forEach(element => {
        classArray.push(element);
      });
      setClasses(classArray);
      setIsLoading(false);
      setClassLinks(response.data.links);
    });
  }


  const removeData = (id, event) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
    axios.delete(config.BaseURL + "/v1/classes/" + id, { headers: headers }).then(function (response) {
      addToast("Class Deleted Successfully!", { appearance: 'error', autoDismiss: true });
      fetchClasses(url);
    });
  }
  }

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setEditableObject(getClassBlankObject());
    setModalObject({
      modalVisible: modalVisible,
    });
  }
  const editClassHandler = (event, object) => {
    event.preventDefault();
    setEditableObject(object);
    setModalObject({
      modalVisible: true
    });
  }

  return (
    <React.Fragment>
      {isLoading ? <LoadingBar /> : null}
      {ReactDOM.createPortal(<AddEditClass refreshList={() => fetchClasses(url)} editableObject={editableObject} isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}
      <InnerHeader title="Manage Class" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchTermRef} type="text" placeholder="Search here" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchClasses} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-8 col-sm-8 text-right">
                  <a onClick={(event) => modalHandler(event, true)} data-toggle="modal" className="news-job-btn"><i className="fa fa-plus"></i>&nbsp; Add</a>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>S.No <span className="pull-right"></span></th>
                        <th>Class Name <span className="pull-right"></span></th>
                        <th>Action <span className="pull-right"></span></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        classes.map((classObject, index) => {
                          return <tr key={classObject.id}>
                            <td>{index + 1}</td>
                            <td>{classObject.name}</td>
                            <td>
                              <Link to='/' onClick={(event) => editClassHandler(event, classObject)} data-toggle="modal" className="download-btn" style={{ backgroundColor: "#2a78e4", color: "#fff" }}><i className="fa fa-edit"></i></Link>&nbsp;
                              <Link to='/' onClick={(event) => removeData(classObject.id, event)} className="share-alt" style={{ backgroundColor: "#cf2a2d" }}><i className="fa fa-trash"></i></Link>
                            </td>
                          </tr>
                        })
                      }
                      {classes.length === 0 ? <tr><td colSpan='3' className='text-center'>No Record Found</td></tr> : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
            {classes.length === 0 ? '' : <Pagination links={classLinks} onPageClick={fetchClasses} />}
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default List;