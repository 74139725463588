import React, { useState, useEffect, useRef } from 'react';
import InnerHeader from "../Shared/InnerHeader";
import LoadingBar from '../Shared/LoadingBar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config.json';
import { getAPIHeaders } from '../../helpers/functions';
import Pagination from '../Shared/Pagination';
import { useToasts } from 'react-toast-notifications';

const List = (props) => {

  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [customerLinks, setCustomerLinks] = useState([]);
  const searchtermRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { addToast } = useToasts();

  let url = config.BaseURL + "/v1/customers";

  useEffect(() => {
    fetchCustomers(url);
  }, [searchTerm]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') searchCustomer();
  }

  const searchCustomer = () => {
    setSearchTerm(searchtermRef.current.value);
  }


  function fetchCustomers(url) {
    setIsLoading(true);
    axios.get(url, { headers: getAPIHeaders(), params: { squery: searchTerm } }).then(function (response) {
      let customerArray = [];
      response.data.data.forEach(element => {
        customerArray.push(element);
      });
      setCustomers(customerArray);
      setIsLoading(false);
      setCustomerLinks(response.data.links);
    });
  }

  const removeData = (id, event) => {
    event.preventDefault();
    if (window.confirm("Do you want to delete?")) {
      axios.delete(url + '/' + id, { headers: getAPIHeaders() }).then(function (response) {
        addToast("Customer Deleted Successfully!", { appearance: 'error', autoDismiss: true });
        fetchCustomers(url);
      });
    }
  }

  return (
    <React.Fragment>
      <InnerHeader title="Manage Customers" buttonTitle="Add" icon="fa fa-plus" path="/manage-customers/add" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <div className="row bg-white has-shadow">
            <div className="col-lg-12 col-sm-12 px-0">
              <div className="row px-0 m-l-r py-0">
                <div className="col-lg-4 col-sm-4 btmSpace">
                  <form className="w-100">
                    <div className="input-group border rounded-pill">
                      <input onKeyDown={handleKeyDown} ref={searchtermRef} type="search" placeholder="Search here" aria-describedby="button-addon3" className="form-control bg-none border-0" />
                      <div className="input-group-append border-0">
                        <button onClick={searchCustomer} id="button-addon3" type="button" className="btn btn-link"><i className="fa fa-search"></i></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-12 col-sm-12 mt-3">
                  <table className="table table-responsive table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th>No. <span className="pull-right"></span></th>
                        <th>Customer Name <span className="pull-right"></span></th>
                        <th>Email <span className="pull-right"></span></th>
                        <th>Contact Number <span className="pull-right"></span></th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        customers.map((customerObject, index) => {
                          return <tr key={customerObject.id}>
                            <td>{index + 1}</td>
                            <td>{customerObject.name}</td>
                            <td>{customerObject.email}</td>
                            <td>{customerObject.contact_no}</td>
                            <td>
                              {/* <Link to={`/manage-customers/${customerObject.id}/edit-job`} className="view-btn">View Jobs</Link>&nbsp; */}
                              <Link to={`/manage-customers/${customerObject.id}/edit`} className="download-btn bg-text-color-2a78e4-fff"><i className="fa fa-edit"></i></Link>&nbsp;
                              <Link to='/' onClick={(event) => removeData(customerObject.id, event)} className="share-alt bg-color-cf2a2d"><i className="fa fa-trash"></i></Link>
                            </td>
                          </tr>
                        })
                      }
                      {customers.length === 0 ? <tr><td colSpan='5' className='text-center'>No Record Found</td></tr> : null}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="page-bottom-pagination text-right mt-4">
            <nav aria-label="Page navigation example" className="d-inline-block">
              {customers.length === 0 ? '' : <Pagination links={customerLinks} onPageClick={fetchCustomers} />}
            </nav>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default List;