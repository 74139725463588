import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";







const MedicalOxygenCylinder = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', o2_cylinder_list: [] });

  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.o2_cylinder_list[index][name] = event.target.value;
    // console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.o2_cylinder_list.push({ no_1: '', type_of_gas_cylinder_1: '', cylinder_serial_number: '', last_test_date: '', cylinder_volume: '', tare_weight: '', gross_weight: '', check_weight: '', test_pressure: '', working_pressure: '', temp_1: '', working_codes: '' });
    setFormFields(_formFields);
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.o2_cylinder_list.splice(index, 1);
    setFormFields(values);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Medical O2 Cylinder" />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid">
          <form name="" action="" autoComplete="off">
            <div class="bg-white has-shadow">
              <div class="row px-0 pb-0">
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} class="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} class="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} class="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="email" name="imo_number" value={formFields['imo_number']} class="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} class="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} class="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Class</label>
                    <div class="styled-select">
                      <select onChange={handleChange} id="" name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Flag</label>
                    <div class="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} class="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span class="fa fa-calendar" id="fa-1" style={{ left: "calc(92% - 0px)" }}></span> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <div class="form-group">
                    <label>Working Codes</label>
                    <div class="row p-0">
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tr>
                            <td>1</td>
                            <td>Inspection</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Hydro Pressure test</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Refilling</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Pr. Check</td>
                          </tr>
                        </table>
                      </div>
                      <div class="col-lg-6 col-sm-6 px-2">
                        <table class="table table-responsive table-striped table-hover">
                          <tr>
                            <td>5</td>
                            <td>Weight Check</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Level Check</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Repair</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>New Supply </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Type of Gas Cylinder</th>
                        <th>Cylinder Serial Number</th>
                        <th>Last Test Date</th>
                        <th>Cylinder Volume (KG)</th>
                        <th>Tare Weight (KG)</th>
                        <th>Gross Weight (KG)</th>
                        <th>Check weight</th>
                        <th>Test Pressure (Bar)</th>
                        <th>Working Pressure (Bar)</th>
                        <th>Temp °C / °F</th>
                        <th>Working Codes</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        formFields.o2_cylinder_list.map((item, index) => {
                          return (
                            <tr key={{ index }}>
                              <td><input onChange={(event) => handleChangeRows(event, 'no_1', index)} type="text" name="no_1" value={item['no_1']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'type_of_gas_cylinder_1', index)} type="text" name="type_of_gas_cylinder_1" value={item['type_of_gas_cylinder_1']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_serial_number', index)} type="text" name="cylinder_serial_number" value={item['cylinder_serial_number']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'last_test_date', index)} type="date" name="last_test_date" value={item['last_test_date']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'cylinder_volume', index)} type="text" name="cylinder_volume" value={item['cylinder_volume']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'tare_weight', index)} type="text" name="tare_weight" value={item['tare_weight']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'gross_weight', index)} type="text" name="gross_weight" value={item['gross_weight']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'check_weight', index)} type="text" name="check_weight" value={item['check_weight']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'test_pressure', index)} type="text" name="test_pressure" value={item['test_pressure']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_pressure', index)} type="text" name="working_pressure" value={item['working_pressure']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'temp_1', index)} type="text" name="temp_1" value={item['temp_1']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input onChange={(event) => handleChangeRows(event, 'working_codes', index)} type="text" name="working_codes" value={item['working_codes']} class="form-control" disabled={props.can_edit_form} s /></td>
                              <td style={{ verticalAlign: "middle", padding: "0 !important" }}><button onClick={(event) => handleRemoveFields(event, index)} type="button" class="removeBtn"><i class="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div class="text-left disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" class="btn btn-success mr-2" disabled={props.can_edit_form}><i class="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default MedicalOxygenCylinder;