import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import config from '../../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../../helpers/functions";
import { useToasts } from 'react-toast-notifications';
import Header from "../../Shared/Header";

const CustomerFeedbackReport = (props) => {

  const [formFields, setFormFields] = useState({control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', overall_presentation: '', completion_job: '', safety_compliance: '', quality: '', clean_up: '', tools_availability: '', technical_knowledge: '', document_submitted: '', service_engineer_name: '', service_engineer_sign: ''});
  const [feedbackFields, setFeedbackFields] = useState({control_no: '', overall_presentation: '', completion_job: '', safety_compliance: '', quality: '', clean_up: '', tools_availability: '', technical_knowledge: '', document_submitted: '', suggestion: ''});
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('token') !== null ? true : false);
  const [isDisableField, setIsDisableField] = useState(false);
  const [backLink, setBackLink] = useState();
  const { token } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/feedback/" + token;
  

  useEffect(() => {
    getJobDetails();
  }, []);

  const getJobDetails = () => {
    axios.get(url, { headers: getAPIHeaders() }).then((response) => {
      let newJobFields = {...formFields, ...response.data};
      let FeedbackJson = JSON.parse(response.data.feedback_json)
      let newfeedbackFields = {...feedbackFields, ...FeedbackJson}
      if (response.data.job_status !== 'Accepted By Customer') {
        history.push('/login');
      }
      setFormFields(newJobFields);
      setFeedbackFields(newfeedbackFields);
      if (newJobFields['feedback_json'] !== null) {
        setIsDisableField(true);
      }
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(setFormFieldsObject);
  }

  const radioHandleChange = (event) => {
    const { name, value } = event.target;
    const setFormFieldsObject = {
      ...feedbackFields,
      [name]: value
    }
    setFeedbackFields(setFormFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    let json = {...formFields, ...feedbackFields}
    axios.put(url, { json: json }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.push('/thankyou/'+token);
      getJobDetails();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  let query = useQuery();
  const view = query.get("view");
  

  return (
    <React.Fragment>
      {!isLoggedIn ? (<Header />) : ''}
      <InnerHeader title="Customer Feedback Form" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="" autoComplete="off">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input onChange={radioHandleChange} type="text" name="control_no" value={feedbackFields['control_no']} className="form-control" placeholder="SHM Control No" disabled={isDisableField} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={isDisableField} readOnly/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" disabled={isDisableField} readOnly/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" disabled={isDisableField} readOnly/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" disabled={isDisableField} readOnly/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" disabled={isDisableField} readOnly/>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select select-disable">
                      <select  name="class_id" value={formFields['class_id']} disabled={isDisableField} readOnly>
                        <option value=""> {formFields['class_id']} </option>
                        
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select select-disable">
                      <select name="flag_id" value={formFields['flag_id']} disabled={isDisableField} readOnly>
                      <option value=""> {formFields['flag_id']} </option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" value={formFields['service_date']} name="service_date" id="start" className="form-control text-left mr-2" placeholder="From Date" disabled={isDisableField} readOnly/>
                      {/* <span className ="fa fa-calendar left-side" id="fa-1"></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  In the endeavor to continually improve the quality of services we provide; we request you to please fill the feedback form as below. We remain committed to always serve you better.
                </div>
                <div className="col-lg-12 col-sm-12 mb-3 mt-3">
                  <h4 className="mb-0">Tick as appropriate</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col className="width-50" />
                      <col className="width-50" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td>1. Overall presentation of SHM personnel</td>
                        <td>2. Timely completion of job</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['overall_presentation'] === '1'} type="radio" name="overall_presentation" disabled={isDisableField}/></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['overall_presentation'] === '2'} type="radio" name="overall_presentation" disabled={isDisableField}/></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['overall_presentation'] === '3'} type="radio" name="overall_presentation" disabled={isDisableField}/></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['completion_job'] === '1'} type="radio" name="completion_job" disabled={isDisableField}/></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['completion_job'] === '2'} type="radio" name="completion_job" disabled={isDisableField}/></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['completion_job'] === '3'} type="radio" name="completion_job" disabled={isDisableField}/></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                      </tr>
                      <tr>
                        <td>3. PPE and work safety compliance</td>
                        <td>4. Quality of work done</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['safety_compliance'] === '1'} type="radio" name="safety_compliance" disabled={isDisableField}/></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['safety_compliance'] === '2'} type="radio" name="safety_compliance" disabled={isDisableField}/></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['safety_compliance'] === '3'} type="radio" name="safety_compliance" disabled={isDisableField}/></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['quality'] === '1'} type="radio" name="quality" disabled={isDisableField}/></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['quality'] === '2'} type="radio" name="quality" disabled={isDisableField}/></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['quality'] === '3'} type="radio" name="quality" disabled={isDisableField}/></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                      </tr>
                      <tr>
                        <td>5. Did personnel clean up after work completion</td>
                        <td>6. Availability of tools with personnel</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['clean_up'] === '1'} type="radio" name="clean_up" disabled={isDisableField} /></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['clean_up'] === '2'} type="radio" name="clean_up" disabled={isDisableField} /></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['clean_up'] === '3'} type="radio" name="clean_up" disabled={isDisableField} /></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['tools_availability'] === '1'} type="radio" name="tools_availability" disabled={isDisableField}/></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['tools_availability'] === '2'} type="radio" name="tools_availability" disabled={isDisableField}/></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['tools_availability'] === '3'} type="radio" name="tools_availability" disabled={isDisableField}/></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                      </tr>
                      <tr>
                        <td>7. Technical knowledge exhibited</td>
                        <td>8. Completeness of documents submitted</td>
                      </tr>
                      <tr>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['technical_knowledge'] === '1'} type="radio" name="technical_knowledge" disabled={isDisableField} /></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['technical_knowledge'] === '2'} type="radio" name="technical_knowledge" disabled={isDisableField} /></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['technical_knowledge'] === '3'} type="radio" name="technical_knowledge" disabled={isDisableField} /></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                        <td>
                          <span className="pr-5">1.&nbsp; <input onChange={radioHandleChange} value="1" checked={feedbackFields['document_submitted'] === '1'} type="radio" name="document_submitted" disabled={isDisableField} /></span>
                          <span className="pr-5">2.&nbsp; <input onChange={radioHandleChange} value="2" checked={feedbackFields['document_submitted'] === '2'} type="radio" name="document_submitted" disabled={isDisableField} /></span>
                          <span className="pr-5">3.&nbsp; <input onChange={radioHandleChange} value="3" checked={feedbackFields['document_submitted'] === '3'} type="radio" name="document_submitted" disabled={isDisableField} /></span><br />
                          <small><span className="pr-4">Not Satisfactory</span> <span className="pr-4">Satisfactory</span> <span>Excellent</span></small>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 col-sm-12 mb-3 mt-3">
                  <textarea onChange={radioHandleChange} name="suggestion" value={feedbackFields['suggestion']} className="form-control height-100" placeholder="Specific suggestions for improvement" disabled={isDisableField}></textarea>
                </div>
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  { formFields['feedback_json'] === null ? (<a onClick={submitHandler} className="continue-btn bg-approve" style={{cursor: 'pointer'}}><i className="fa fa-check"></i>&nbsp; Submit </a>) : ""}
                  {isLoggedIn && view === 'readonly' ? (<Link to='/manage-jobs'  className="continue-btn"><i class="fa fa-arrow-left"></i>&nbsp;Back</Link>) : ''}
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border mt-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                {/* <label class="mb-0">Name</label> */}
                {/* <input onChange={radioHandleChange} type="text" name="approver_name" value={feedbackFields['approver_name']} class={ isDisableField ? 'select-disable form-control' : 'form-control'} style={{ border: "0", background: "none" }} autocomplete="off"  disabled={isDisableField}/> */}
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}

export default CustomerFeedbackReport;