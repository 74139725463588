import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const ODME = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', serial_no: '', type: '', last_cal_date: '', make: '', next_due_date: '', model: '', location: '', description_of_inspection_tests: '', main_control_unit_checked: '', flow_meter_checked: '', ppm_bilge_alarm_test: '', odme_unit: '', overboard_valve_function: '', slop_tank_valve_function: '', test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '' });
  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no =formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="ODME Calibration" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" onChange={handleChange} name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-sm-12 mt-1">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>Equipment</td>
                        <td><input type="text" name="equipment" onChange={handleChange} value={formFields['equipment']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial Number</td>
                        <td><input type="text" name="serial_no" onChange={handleChange} value={formFields['serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td><input type="text" name="type" onChange={handleChange} value={formFields['type']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Last Cal Date</td>
                        <td><input type="date" name="last_cal_date" onChange={handleChange} value={formFields['last_cal_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td><input type="text" name="make" onChange={handleChange} value={formFields['make']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Next Due Date</td>
                        <td><input type="date" name="next_due_date" onChange={handleChange} value={formFields['next_due_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td><input type="text" name="model" onChange={handleChange} value={formFields['model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Location</td>
                        <td><input type="text" name="location" onChange={handleChange} value={formFields['location']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input type="text" onChange={handleChange} name="description_of_inspection_tests" value={formFields['description_of_inspection_tests']} placeholder="This instrument was calibrated using the standard inspection procedures and the quality is fully conformed with the specification’s standards. ODME obligatory installation MARPOL 73/78 Annex 1, regulation 15" className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Calibration Results</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '10%' }} />
                      <col style={{ width: '60%' }} />
                      <col style={{ width: '30%' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-left">SR. No.</th>
                        <th className="text-left">TEST</th>
                        <th className="text-left">RESULT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>MAIN CONTROL UNIT CHECKED</td>
                        <td><input type="text" onChange={handleChange} name="main_control_unit_checked" value={formFields['main_control_unit_checked']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>FLOW METER CHECKED</td>
                        <td><input type="text" onChange={handleChange} name="flow_meter_checked" value={formFields['flow_meter_checked']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>15PPM BILGE ALARM TEST</td>
                        <td><input type="text" onChange={handleChange} name="ppm_bilge_alarm_test" value={formFields['ppm_bilge_alarm_test']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>ODME UNIT CALIBRATION</td>
                        <td><input type="text" onChange={handleChange} name="odme_unit" value={formFields['odme_unit']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>OVERBOARD VALVE FUNCTION</td>
                        <td><input type="text" onChange={handleChange} name="overboard_valve_function" value={formFields['overboard_valve_function']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>6.</td>
                        <td>SLOP TANK VALVE FUNCTION</td>
                        <td><input type="text" onChange={handleChange} name="slop_tank_valve_function" value={formFields['slop_tank_valve_function']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Test Result</label>
                  <textarea onChange={handleChange} name="test_result" value={formFields['test_result']} placeholder="Test done on the ODME System installed on above mentioned vessel found unit working satisfactory with accordance to the Makers requirement. We hereby certify that we have tested the above instrument in accordance with standard inspection procedures and that the quality is in full conformity with the specifications and the standards." className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea name="tracebility" onChange={handleChange} value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea name="remarks" onChange={handleChange} value={formFields['remarks']} placeholder="Remarks" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>

          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
               </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
export default ODME;