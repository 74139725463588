import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { Link, useHistory } from 'react-router-dom';
import { useParams } from "react-router";
import config from '../../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../../helpers/functions";
import { useToasts } from 'react-toast-notifications';
import FormButton from "../../Shared/FormButton";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

const ServiceChart = (props) => {
  const [formFields, setFormFields] = useState({ job_report_date: '', vessel_name: '', imo_number: '', certificate_no: '', service_port: '', classnameification: '', ships_email: '', service_scope: '', approver_name: '', service_engineer_sign: '' });
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();
  const year = (new Date()).getFullYear();
  const years = Array.from(new Array(15), (val, index) => year - index);

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      setFormFields(formObject);
    }
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const setFormFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(setFormFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }
  return (
    <React.Fragment>
      <InnerHeader title="Service Chart" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Year</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="year" value={formFields['year']} disabled={props.can_edit_form}>
                        {
                          years.map((year, index) => {
                            return <option key={`year${index}`} value={year}>{year}</option>
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Month</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="month" value={formFields['month']} disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Month </option>
                        <option value="January"> January</option>
                        <option value="February">February </option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 col-sm-2">
                  <div className="form-group">
                    <label>Date</label>
                    {/* <DatePicker
                      selected={formFields['job_report_date']}
                      onChange={(date) => setFormFields({ ...formFields, job_report_date: date })}
                      className="form-control"
                      placeholder="select Date"
                      dateFormat="dd/MM/yyyy"
                      placeholderText="DD/MM/YYYY"
                    /> */}
                    <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} id="start" className="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                    <div className="input-group input-daterange">
                      {/* <input onChange={handleChange} value={formFields['job_report_date']} type="date" name="job_report_date" id="start" className="form-control" placeholder="From Date" style={{ fontSize: "15px" }} disabled={props.can_edit_form} /> */}
                      {/* <span className="fa fa-calendar" id="fa-2" style={{ marginTop: "-3px" }}></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="IMO Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Job ID/Certificate No.</label>
                    <input onChange={handleChange} type="text" name="certificate_no" value={formFields['certificate_no']} className="form-control" placeholder="Job ID/Certificate No." disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Port of Service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of Service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Classification society</label>
                    <input onChange={handleChange} type="text" name="classnameification" value={formFields['classnameification']} className="form-control" placeholder="Classification society" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-3 col-sm-3">
                  <div className="form-group">
                    <label>Ships email ID</label>
                    <input onChange={handleChange} type="text" name="ships_email" value={formFields['ships_email']} className="form-control" placeholder="Ships email ID" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mb-4">
                  We hereby certify that the Fire Fighting Equipment & Life Saving Equipment as specified below and in the Service Chart/s have been inspected, serviced, and verified fit for operation.
                </div>
                <div className="col-lg-12 col-sm-12 mb-3">
                  <div className="md-font">Scope Of Service</div>
                </div>
                <div className="col-lg-12 col-sm-12 checkBoxWid">
                  <div className="form-group">
                    <textarea onChange={handleChange} name="service_scope" value={formFields['service_scope']} id="" className="form-control" style={{ height: "100px" }} disabled={props.can_edit_form}></textarea>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <strong>** This document to list down all documents selected for the particular job ID***</strong>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <hr />
                </div>
                {/* <div className="col-lg-12 col-sm-12">
                  <h4 className="mb-3">Company Address </h4>
                </div>
                <div className="col-lg-12 col-sm-12"><b>SHM SHIPCARE SDN BHD</b></div>
                <div className="col-lg-12 col-sm-12">No 11 &15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
                <div className="col-lg-12 col-sm-12"><b>Master/Chief Officer Endorsement</b></div>
                <div className="col-lg-12 col-sm-12"><b>Service Engineer</b></div>
                <div className="col-lg-12 col-sm-12 mt-1"></div>
                <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Approver Name</label>
                    <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} className="form-control" placeholder="Name" disabled={props.can_edit_form} />
                  </div>
                </div> */}
                {/* <div className="col-lg-4 col-sm-4">
                  <div className="form-group">
                    <label>Sign</label>
                    <input onChange={handleChange} type="text" name="service_engineer_sign" value={formFields['service_engineer_sign']} className="form-control" placeholder="Sign" disabled={props.can_edit_form} />
                  </div>
                </div> */}
                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
            <div class="row px-0 border mt-4">
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div class="mb-2"><strong>Company Address </strong></div>
                <div><b>SHM SHIPCARE SDN BHD</b></div>
                <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
              </div>
              <div class="col-lg-4 col-sm-4 brd-rht">
                <div><b>Master/Chief Officer<br />Endorsement</b></div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div><b>Service Engineer</b></div>
                <div>
                  {/* <label class="mb-0">Name</label> */}
                  <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: "0", background: "none" }} disabled={true} />
                </div>
                <div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ServiceChart;