import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const ThermometerTemperatureGuageCalibration = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', type_of_gauge: '', maker: '', range: '', model_no: '', next_calibration_date: '', serial_no: '', location: '', description_inspection: '', applied_temperature_increasing: '', applied_temperature_decreasing: '', reading_increasing: '', reading_decreasing: '', deviation_increasing: '', deviation_decreasing: '', test_result: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '', calibration_results: [] });
  const [flags, SetFlags] = useState([]);
  const [Classes, SetClasses] = useState([]);
  const { job_id, form_id } = useParams();
  const { addToast } = useToasts();
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let flagURL = config.BaseURL + "/v1/flags?all=true";
  let classesURL = config.BaseURL + "/v1/classes?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no = formObject.ref_no
      setFormFields(formObject);
    }
    loadClasses(classesURL);
    loadFlags(flagURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function loadClasses() {
    axios.get(classesURL, { headers: getAPIHeaders() }).then(function (response) {
      SetClasses(response.data);
    });
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const handleRemoveFields = (event, index) => {
    const values = { ...formFields };
    values.calibration_results.splice(index, 1);
    setFormFields(values);
  }

  const handleChangeRows = (event, name, index) => {
    const _formFields = { ...formFields };
    _formFields.calibration_results[index][name] = event.target.value;
    console.log(_formFields.calibration_results);
    setFormFields(_formFields);
  }

  const addCalibrationRow = () => {
    const _formFields = { ...formFields };
    _formFields.calibration_results.push({});
    setFormFields(_formFields);
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Temperature Guage" />
      <section class="dashboard-counts no-padding-bottom">
        <div class="container-fluid">
          <form name="" action="" autoComplete="off">
            <div class="bg-white has-shadow">
              <div class="row px-0 pb-0">
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>SHM Control No</label>
                    <input onChange={handleChange} type="text" name="control_no" value={formFields['control_no']} class="form-control" placeholder="SHM Control No" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Vessel Name</label>
                    <input onChange={handleChange} type="text" name="vessel_name" value={formFields['vessel_name']} class="form-control" placeholder="Vessel Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer Name</label>
                    <input onChange={handleChange} type="text" name="customer_name" value={formFields['customer_name']} class="form-control" placeholder="Customer Name" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>IMO Number</label>
                    <input onChange={handleChange} type="text" name="imo_number" value={formFields['imo_number']} class="form-control" placeholder="Number" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Customer PIC</label>
                    <input onChange={handleChange} type="text" name="customer_pic" value={formFields['customer_pic']} class="form-control" placeholder="Customer PIC" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Port of service</label>
                    <input onChange={handleChange} type="text" name="service_port" value={formFields['service_port']} class="form-control" placeholder="Port of service" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Class</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="class_id" value={formFields['class_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Flag</label>
                    <div class="styled-select">
                      <select onChange={handleChange} name="flag_id" value={formFields['flag_id']} disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span class="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="form-group">
                    <label>Date</label>
                    <div class="input-group input-daterange">
                      <input onChange={handleChange} type="date" name="job_report_date" value={formFields['job_report_date']} class="form-control text-left mr-2" placeholder="From Date" disabled={props.can_edit_form} />
                      {/* <span class="fa fa-calendar left-side"></span> */}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 mt-3">
                  <table class="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>Equipment</td>
                        <td><input onChange={handleChange} type="text" name="equipment" value={formFields['equipment']} class="form-control" disabled={props.can_edit_form} /></td>
                        <td>Type of Gauge</td>
                        <td><input onChange={handleChange} type="text" name="type_of_gauge" value={formFields['type_of_gauge']} class="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Maker</td>
                        <td><input onChange={handleChange} type="text" name="maker" value={formFields['maker']} class="form-control" disabled={props.can_edit_form} /></td>
                        <td>Range</td>
                        <td><input onChange={handleChange} type="text" name="range" value={formFields['range']} class="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model No.</td>
                        <td><input onChange={handleChange} type="text" name="model_no" value={formFields['model_no']} class="form-control" disabled={props.can_edit_form} /></td>
                        <td>Next calibration Date</td>
                        <td><input onChange={handleChange} type="date" name="next_calibration_date" value={formFields['next_calibration_date']} class="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Serial No.</td>
                        <td><input onChange={handleChange} type="text" name="serial_no" value={formFields['serial_no']} class="form-control" disabled={props.can_edit_form} /></td>
                        <td>Location</td>
                        <td><input onChange={handleChange} type="text" name="location" value={formFields['location']} class="form-control" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input onChange={handleChange} type="text" name="description_inspection" value={formFields['description_inspection']} placeholder="This instrument has been calibrated in accordance with our process and conforms to the quality and calibration standards laid down in our detailed procedures." class="form-control" disabled={props.can_edit_form} />
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <h4 class="mb-0">Calibration Results</h4>
                </div>
                <div class="col-lg-12 col-sm-12">
                  <table class="table table-responsive table-striped table-hover">
                    <thead>
                      <tr>
                        <th colspan="2" class="text-center">APPLIED TEMPERATURE ( Deg Celsius)</th>
                        <th colspan="2" class="text-center">READING (( Deg Celsius)</th>
                        <th colspan="2" class="text-center">DEVIATION (( Deg Celsius)</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td class="text-center">Increasing</td>
                        <td class="text-center">Decreasing</td>
                        <td>&nbsp;</td>
                      </tr>
                      {
                        formFields.calibration_results.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'applied_temperature_increasing', index)} value={item['applied_temperature_increasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'applied_temperature_decreasing', index)} value={item['applied_temperature_decreasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'reading_increasing', index)} value={item['reading_increasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'reading_decreasing', index)} value={item['reading_decreasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'deviation_increasing', index)} value={item['deviation_increasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td><input type="text" onChange={(event) => handleChangeRows(event, 'deviation_decreasing', index)} value={item['deviation_decreasing']} class="form-control" disabled={props.can_edit_form} /></td>
                              <td class="vertical-mid"><button type="button" onClick={() => handleRemoveFields(index)} class="removeBtn"><i class="fa fa-remove"></i></button></td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  <div class="text-left mb-3 disable-button-custom">
                    <button onClick={() => addCalibrationRow()} type="button" class="btn btn-success mr-2" disabled={props.can_edit_form}><i class="fa fa-plus"></i>&nbsp; Add</button>
                  </div>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Test Result</label>
                  <textarea onChange={handleChange} name="test_result" value={formFields['test_result']} placeholder="We hereby certify that we have tested the above instrument in accordance tostandard inspection procedures and that the quality is in full conformance with the specifications and the standards." class="form-control height-100" disabled={props.can_edit_form}></textarea>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea onChange={handleChange} name="tracebility" value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number:" class="form-control height-100" disabled={props.can_edit_form}></textarea>
                </div>

                <div class="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea onChange={handleChange} name="remarks" value={formFields['remarks']} placeholder="Remarks" class="form-control height-100" disabled={props.can_edit_form}></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                  <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div class="row px-0 border my-4">
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div class="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div class="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div class="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input onChange={handleChange} type="text" name="approver_name" value={formFields['approver_name']} class="form-control" style={{ border: 0, background: 'none' }} disabled={true} />
              </div>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </React.Fragment>
  );
}
export default ThermometerTemperatureGuageCalibration;