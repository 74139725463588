import React from 'react';

const Footer = (props) => {
  return (
    <React.Fragment>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <footer className="main-footer fixed-bottom mt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 text-center">
              <p>© 2016 SHM Shipcare. All rights reserved. ISO 9001:2015 Certified. <span className="float-right">Version - 1</span></p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;