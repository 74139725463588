import React, { useState, useEffect } from "react";
import InnerHeader from "../../Shared/InnerHeader";
import { getAPIHeaders } from "../../../helpers/functions";
import axios from 'axios';
import config from '../../../config.json';
import { useParams } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { Link, useHistory } from 'react-router-dom';
import FormButton from "../../Shared/FormButton";

const GasPumpLeakTest = (props) => {

  const [formFields, setFormFields] = useState({ control_no: '', vessel_name: '', customer_name: '', imo_number: '', customer_pic: '', service_port: '', class_id: '', flag_id: '', job_report_date: '', equipment: '', type: '', make: '', model: '', serial_no: '', last_cal_date: '', next_due_date: '', location: '', description_of_inspection_tests: '', ambient_temp: '', relative_humidity: '', draeger_tuber_used: '', observation_time: '', test_result: '', test_result_: '', test_procedure: '', tracebility: '', remarks: '', service_engineer_name: '', service_engineer_sign: '' });
  const { addToast } = useToasts();
  const { job_id, form_id } = useParams();
  const [Classes, SetClasses] = useState([]);
  const [Flags, SetFlags] = useState([]);
  let history = useHistory();

  let url = config.BaseURL + "/v1/jobs/" + job_id + "/forms/" + form_id;
  let classesURL = config.BaseURL + "/v1/classes?all=true";
  let flagURL = config.BaseURL + "/v1/flags?all=true";

  useEffect(() => {
    if (props.formFields != null) {
      const formObject = { ...formFields, ...props.formFields };
      formObject.control_no =formObject.ref_no
      setFormFields(formObject);
    }
    loadFlags(flagURL);
    loadClasses(classesURL);
  }, [props.formFields]);

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  const submitHandler = (event) => {
    event.preventDefault();
    axios.put(url, { json: formFields }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const loadClasses = () => {
    axios.get(classesURL, { headers: getAPIHeaders() }).then((response) => {
      SetClasses(response.data);
    })
  }

  const loadFlags = () => {
    axios.get(flagURL, { headers: getAPIHeaders() }).then((response) => {
      SetFlags(response.data);
    })
  }

  const actionHandler = (event, action) => {
    event.preventDefault();
    axios.put(url, { action: action }, { headers: getAPIHeaders() }).then((response) => {
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      history.goBack();
    }).catch(function (error) {
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  return (
    <React.Fragment>
      <InnerHeader title="Gas Pump Leak Test" />
      <section className="dashboard-counts no-padding-bottom">
        <div className="container-fluid">
          <form name="" action="">
            <div className="bg-white has-shadow">
              <div className="row px-0 pb-0">
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>SHM Control No</label>
                    <input type="text" onChange={handleChange} name="control_no" value={formFields['control_no']} className="form-control" placeholder="SHM Control No" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Vessel Name</label>
                    <input type="text" onChange={handleChange} name="vessel_name" value={formFields['vessel_name']} className="form-control" placeholder="Vessel Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer Name</label>
                    <input type="text" onChange={handleChange} name="customer_name" value={formFields['customer_name']} className="form-control" placeholder="Customer Name" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>IMO Number</label>
                    <input type="email" onChange={handleChange} name="imo_number" value={formFields['imo_number']} className="form-control" placeholder="Number" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Customer PIC</label>
                    <input type="text" onChange={handleChange} name="customer_pic" value={formFields['customer_pic']} className="form-control" placeholder="Customer PIC" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Port of service</label>
                    <input type="text" onChange={handleChange} name="service_port" value={formFields['service_port']} className="form-control" placeholder="Port of service" autocomplete="off" disabled={props.can_edit_form} />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Class</label>
                    <div className="styled-select">
                      <select id="" name="class_id" onChange={handleChange} value={formFields['class_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value="" selected="selected"> Class </option>
                        {
                          Classes.map((item, index) => {
                            return (
                              <option key={index} value={item.id}>{item.name}</option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Flag</label>
                    <div className="styled-select">
                      <select onChange={handleChange} id="" name="flag_id" value={formFields['flag_id']} autocomplete="off" disabled={props.can_edit_form}>
                        <option value=""> Select Flag </option>
                        {
                          Flags.map((item, index) => {
                            return (
                              <option key={index} value={item.id}> {item.name} </option>
                            )
                          })
                        }
                      </select>
                      <span className="fa fa-angle-down"></span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="input-group input-daterange">
                      <input type="date" onChange={handleChange} id="start" name="job_report_date" value={formFields['job_report_date']} className="form-control text-left mr-2 pointer-cursor-class" placeholder="From Date" autocomplete="off" disabled={props.can_edit_form} />
                      {/* <span className="fa fa-calendar left-side" id="fa-1" style={{ left: "calc(92% - 0px)" }} ></span> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-12 mt-1">
                  <table className="table table-responsive table-striped table-hover">
                    <tbody>
                      <tr>
                        <td>Equipment</td>
                        <td><input type="text" name="equipment" onChange={handleChange} value={formFields['equipment']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Serial Number</td>
                        <td><input type="text" name="serial_no" onChange={handleChange} value={formFields['serial_no']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td><input type="text" name="type" onChange={handleChange} value={formFields['type']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Last Cal Date</td>
                        <td><input type="date" name="last_cal_date" onChange={handleChange} value={formFields['last_cal_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Make</td>
                        <td><input type="text" name="make" onChange={handleChange} value={formFields['make']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Next Due Date</td>
                        <td><input type="date" name="next_due_date" onChange={handleChange} value={formFields['next_due_date']} className="form-control pointer-cursor-class" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>Model</td>
                        <td><input type="text" name="model" onChange={handleChange} value={formFields['model']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                        <td>Location</td>
                        <td><input type="text" name="location" onChange={handleChange} value={formFields['location']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Description Of Inspection / Tests</label>
                  <input type="text" onChange={handleChange} name="description_of_inspection_tests" value={formFields['description_of_inspection_tests']} placeholder="Leak Test as per manufacturers instructions." className="form-control" autocomplete="off" disabled={props.can_edit_form} />
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <h4 className="mb-0">Test Results</h4>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <table className="table table-responsive table-striped table-hover">
                    <colgroup>
                      <col style={{ width: '10 %' }} />
                      <col style={{ width: '60 %' }} />
                      <col style={{ width: '30 %' }} />
                    </colgroup>
                    <thead>
                      <tr>
                        <th className="text-left">SR. No.</th>
                        <th className="text-left">TEST</th>
                        <th className="text-left">RESULT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>AMBIENT TEMPERATURE</td>
                        <td><input type="text" name="ambient_temp" onChange={handleChange} value={formFields['ambient_temp']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>RELATIVE HUMIDITY</td>
                        <td><input type="text" name="relative_humidity" onChange={handleChange} value={formFields['relative_humidity']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>3.</td>
                        <td>DRAEGER TUBES USED</td>
                        <td><input type="text" name="draeger_tuber_used" onChange={handleChange} value={formFields['draeger_tuber_used']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>4.</td>
                        <td>OBSERVATION TIME</td>
                        <td><input type="text" name="observation_time" onChange={handleChange} value={formFields['observation_time']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                      <tr>
                        <td>5.</td>
                        <td>TEST RESULT</td>
                        <td><input type="text" name="test_result_" onChange={handleChange} value={formFields['test_result_']} className="form-control" autocomplete="off" disabled={props.can_edit_form} /></td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Test Procedure</label>
                  <textarea name="test_procedure" onChange={handleChange} value={formFields['test_procedure']} placeholder="We hereby certify that we have tested the above instrument in accordance tostandard inspection procedures and that the quality is in full conformance with the specifications and the standards." className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Tracebility</label>
                  <textarea name="tracebility" onChange={handleChange} value={formFields['tracebility']} placeholder="All our master equipment is traceable to NMIM. Master Equipment Calibration Certificate number" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 mb-3">
                  <label>Remarks</label>
                  <textarea name="remarks" onChange={handleChange} value={formFields['remarks']} placeholder="Remarks" className="form-control height-100" autocomplete="off" disabled={props.can_edit_form} ></textarea>
                </div>

                <div className="col-lg-12 col-sm-12 pb-4 pt-3 text-center">
                <FormButton role={props.role} form_status={props.form_status} actionHandler={actionHandler} submitHandler={submitHandler} job_id={job_id} disabled={props.can_edit_form} isRejectedByCustomer={props.isRejectedByCustomer} />
                </div>
              </div>
            </div>
          </form>
          <div className="row px-0 border my-4">
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div className="mb-2"><strong>Company Address </strong></div>
              <div><b>SHM SHIPCARE SDN BHD</b></div>
              <div>No 11 &amp;15 Jalan ,Rebena,Off Jalan Seruling 59 ,Taman Klang Jaya 41200, Klang Selangor, Malaysia</div>
            </div>
            <div className="col-lg-4 col-sm-4 brd-rht">
              <div><b>Master/Chief Officer<br />Endorsement</b></div>
            </div>
            <div className="col-lg-4 col-sm-4">
              <div><b>Service Engineer</b></div>
              <div>
                <input type="text" onChange={handleChange} name="approver_name" value={formFields['approver_name']} className="form-control" style={{ border: 0, background: 'none' }} autocomplete="off" disabled={true} />
              </div>
              <div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment >
  );
}
export default GasPumpLeakTest;