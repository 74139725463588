import React from 'react';
import { Link } from 'react-router-dom';

const InnerHeader = (props) => {

  const buttonTitle = props.buttonTitle;

  return (
    <React.Fragment>
      <header className="page-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-10 col-sm-9 col-8 my-auto">
              <h2 className="no-margin-bottom">{props.title}</h2>
            </div>
            {buttonTitle !== undefined &&
              <div className="col-lg-2 col-sm-3 col-4">
                <Link to={props.path} className="news-job-btn"><i className={props.icon}></i>&nbsp; {props.buttonTitle}</Link>
              </div>
            }
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default InnerHeader;