import React, { useState } from "react";
import InnerHeader from '../Shared/InnerHeader';
import ApprovedMail from './ApprovedMail';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';

const Approved = (props) => {

  const [modalObject, setModalObject] = useState({ modalVisible: false});

  const modalHandler = (event, modalVisible) => {
    event.preventDefault();
    setModalObject({
      modalVisible: modalVisible
    });
  } 
    return ( 
        <React.Fragment>
           {ReactDOM.createPortal(<ApprovedMail isModalVisible={modalObject.modalVisible} modalCloseHandler={modalHandler} />, document.getElementById('modal-root'))}
           
           <InnerHeader title="Manage Job > Approved" />
             <section className="dashboard-counts no-padding-bottom">
      <div className="container-fluid">
        <form name="" action="">
          <div className="bg-white has-shadow">
            <div className="row px-0 pb-0" id="newForm">
              <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>I JO/Ref No</label>
                <input type="text" name="" value="" className="form-control" placeholder="IJO/Ref No" />
              </div>
            </div>
             <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Certificate Number / Job ID Customer Name</label>
                <input type="text" name="" value="" className="form-control" placeholder="Certificate Number / Job Id Customer Name" />
              </div>
            </div> 
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Customer PIC</label>
                <input type="text" name="" value="" className="form-control" placeholder="Customer Pic" />
              </div>
            </div>  
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Customer Email ID</label>
                <input type="email" name="" value="" className="form-control" placeholder="Customer Email Id" />
              </div>
            </div>    
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Port Of Service</label>
                <input type="text" name="" value="" className="form-control" placeholder="Port of service" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Date Of Service</label>
                <input type="text" name="" value="" className="form-control" placeholder="Date of service" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Vessel Name</label>
                <input type="text" name="" value="" className="form-control" placeholder="Vessel Name" />
              </div>
            </div>
             <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>IMO Number</label>
                <input type="text" name="" value="" className="form-control" placeholder="IMO number" />
              </div>
            </div>   
            <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>className</label>
                <div className="styled-select">
                  <select id="" name="">
                    <option value="" selected="selected"> className </option>
                    <option value="1"> className</option>
                  </select>
                  <span className="fa fa-angle-down"></span>
                </div>
              </div>
            </div>
           <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Flag</label>
                <div className="styled-select">
                  <select id="" name="">
                    <option value="" selected="selected"> Flag </option>
                    <option value="1"> Flag</option>
                  </select>
                  <span className="fa fa-angle-down"></span>
                </div>
              </div>
            </div>
             <div className="col-lg-6 col-sm-6">
              <div className="form-group">
                <label>Vessel Email ID</label>
                <input type="email" name="" value="" className="form-control" placeholder="Vessel Email Id" />
              </div>
            </div> 
           <div className="col-lg-12 col-sm-12"><hr/></div>
             <div className="col-lg-4 col-sm-4">
             <div className="form-group">
                <label>Assigned To</label>
                <div className="styled-select">
                  <select id="" name="">
                    <option value="" selected="selected"> Assigned To </option>
                    <option value="1"> Assigned To </option>
                  </select>
                  <span className="fa fa-angle-down"></span>
                </div>
              </div>
           </div>
           <div className="col-lg-4 col-sm-4">
             <div className="form-group">
                <label>Reviewed By</label>
                <div className="styled-select">
                  <select id="" name="">
                    <option value="" selected="selected"> Select </option>
                    <option value="1"> Select </option>
                  </select>
                  <span className="fa fa-angle-down"></span>
                </div>
              </div>
           </div>
           <div className="col-lg-4 col-sm-4">
               <div className="form-group">
                <label>Approved By</label>
                <div className="styled-select">
                  <select id="" name="">
                    <option value="" selected="selected"> Select </option>
                    <option value="1"> Select</option>
                  </select>
                  <span className="fa fa-angle-down"></span>
                </div>
              </div> 
           </div>    
            <div className="clearfix">&nbsp;</div>    
            
              <div className="col-lg-6 col-sm-6">
                <h4 className="mb-0">Forms</h4>    
             </div>
             <div className="col-lg-6 col-sm-6 text-right">    
                <Link onClick={(event) => modalHandler(event, true)} to='/' data-toggle="modal" className="continue-btn" ><i className="fa fa-envelope"></i>&nbsp; Send Mail </Link>
             </div>     
              <div className="col-lg-12 col-sm-12 mt-3">
                <table className="table table-responsive table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Date Submited</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Cetificate Of Inspection</td>
                      <td>12/12/2021</td>
                      <td>Pending Approval</td>
                      <td>
                          <Link to="/manage-job/approved-view" className="view-btn"><a>View</a></Link>
                          <a href="#" className="download-btn"><i className="fa fa-download"></i></a>
                          <a href="#" className="share-alt"><i className="fa fa-share-alt"></i></a>
                      </td>
                    </tr>
                    <tr>
                      <td>Form Applicator</td>
                      <td>12/12/2021</td>
                      <td>Pending Approval</td>
                      <td>
                          <Link to="/manage-job/approved-view" className="view-btn"><a>View</a></Link>
                          <a href="#" className="download-btn"><i className="fa fa-download"></i></a>
                          <a href="#" className="share-alt"><i className="fa fa-share-alt"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-12 col-sm-12 pb-4 pt-1 text-right">
                <Link to="/manage-job" className="continue-btn"><i className="fa fa-arrow-left"></i>&nbsp; <a> Back </a> </Link>
              </div>
            </div>
            </div>
            </form>
            </div>
    </section>
        </React.Fragment>
     );
}
 
export default Approved;