import React from "react";
import InnerHeader from '../Shared/InnerHeader';
import PDF from '../../assets/img/pdf.png'
const Pdf = () => {
    return ( 
        <React.Fragment>
             <InnerHeader title="Manage Job > PDF" buttonTitle="Export PDF"  />
            <section className="dashboard-counts no-padding-bottom">
      <div className="container-fluid">
        <div className="row bg-white has-shadow px-0">
            <div className="col-lg-6 col-sm-6 col-8 mb-3">
                <div className="md-font">Certificate Of Inspection</div>
            </div>
            <div className="col-lg-6 col-sm-6 col-4 mb-3">
                <a href="mj-certificate-of-inspection.php" className="news-job-btn" style={{background: "#2f333e"}}><i className="fa fa-angle-left"></i>&nbsp; Back</a>  
            </div>
            <div className="col-lg-8 col-sm-8 floatNone text-center">
                <img src={PDF} alt="pdf image" className="img-fluid" />
            </div>
        </div>
      </div>
    </section>
        </React.Fragment>
     );
}
 
export default Pdf;