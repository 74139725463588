import React from 'react';

const SuggestionsListComponent = (props) => {
    const suggestion = [...props.filteredSuggestions];

    // Object.keys(props.filteredSuggestions).map((key, index) => {
    //     console.log('ola', props.filteredSuggestions[key]);
    // });
    const onClick = (e, row) => {
        e.preventDefault();
        props.setFilteredSuggestions([]);
        const jobObject = {
            ...props.jobfields,
            ['customer_name']: row.name,
            ['customer_email']: row.email
        }
        props.setJobFields(jobObject);
        props.setActiveSuggestionIndex(0);
        props.setShowSuggestions(false);
    };
    return (<ul className="suggestions" style={{ backgroundColor: '#fff', border: '1px solid #ddd', listStyle: 'none', boxShadow: '0px 1px 4px #d4cfcf', maxHeight: '200px', overflowY: 'scroll', position: 'absolute', zIndex: '999', width: '100%' }} >
        {Object.keys(suggestion).map((key, index) => {
            let classN;
            if (index === props.activeSuggestionIndex) {
                classN = "suggestion-active";
            }
            return (
                <li className={classN} className="suggestion-item" onClick={(e) => { onClick(e, suggestion[key]) }} style={{ cursor: 'pointer', padding: '0.2rem 0.75rem', borderBottom: '1px solid #ddd' }} >
                    {suggestion[key].name}
                </li>
            );
        })}
    </ul>
    )

};

export default SuggestionsListComponent;