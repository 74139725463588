import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../../config.json';
import { getJobBlankObject, getAPIHeaders } from '../../../helpers/functions';
import { useToasts } from 'react-toast-notifications';

const Modal = (props) => {

  const optionValue = { value: "" };
  const [formFields, setFormFields] = useState(getJobBlankObject());
  const [validationError, setValidationError] = useState('');
  const { addToast } = useToasts();

  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  function getClass() {
    if (formFields.hasOwnProperty('id')) return 'd-none';
    return '';
  }

  useEffect(() => {
    setFormFields(props.editableObject);
    setValidationError('');
  }, [props.editableObject]);

  const addOptionValue = (event) => {
    event.preventDefault();
    const optionValuesArray = [...formFields.field_options];
    optionValuesArray.push(optionValue);
    setFormFields({
      ...formFields,
      field_options: optionValuesArray
    });
  }

  const typeHandler = (event) => {
    const selectedType = event.target.value;
    if (selectedType === 'select' || selectedType === 'multiselect' || selectedType === 'radio' || selectedType === 'checkbox') {
      setFormFields({
        ...formFields,
        field_type: selectedType,
        field_options: [optionValue],
        options_visible: true
      });
    } else {
      setFormFields({
        ...formFields,
        field_type: selectedType,
        field_options: [optionValue],
        options_visible: false
      });
    }
  }

  const removeOption = (event, index) => {
    event.preventDefault();
    const optionValuesArray = [...formFields.field_options];
    optionValuesArray.splice(index, 1);
    setFormFields({
      ...formFields,
      field_options: optionValuesArray
    });
  }

  const optionChangeHandler = (event, index) => {
    const optionValuesArray = [...formFields.field_options];
    optionValuesArray[index] = { value: event.target.value };
    setFormFields({
      ...formFields,
      field_options: optionValuesArray
    });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    var optionValuesArray = [];
    var formData = { ...formFields };
    formFields.field_options.forEach(element => {
      optionValuesArray.push(element.value);
    });
    if (formData.field_type === 'text' || formData.field_type === 'number' || formData.field_type === 'date' || formData.field_type === 'time') {
      delete formData.field_options;
    } else {
      formData.field_options = optionValuesArray;
    }
    if (!formData.hasOwnProperty("id")) {
      axios.post(config.BaseURL + "/v1/companies/" + props.companyId + "/job-fields", formData, { headers: getAPIHeaders() }).then((response) => {
        console.log(response);
        addToast("Job Template Added Successfully!", { appearance: 'success', autoDismiss: true });
        resetForm(event);
        props.refreshList();
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    } else {
      axios.put(config.BaseURL + "/v1/companies/" + props.companyId + "/job-fields/" + formData.id, formData, { headers: getAPIHeaders() }).then((response) => {
        resetForm(event);
        addToast("Job template Updated successfully!", { appearance: 'success', autoDismiss: true });
        props.refreshList();
      }).catch((error) => {
        addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
      });
    }
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  function resetForm(event) {
    props.modalCloseHandler(event, false)
  }

  const onModalClose = (event) => {
    resetForm(event);
  }

  return (
    <React.Fragment>
      <div className="modal" id="jobtempaddModal" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content jobtemplate-modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Job Template</h3>
              <button onClick={onModalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="">
                <div className="fieldset-form">
                  <div className="row">
                    <div className="col-lg-12 col-sm-12">
                      {
                        validationError &&
                        <div className="alert alert-danger" role="alert">
                          {validationError}
                        </div>
                      }
                      <div className="row form-group">
                        <div className="col-md-7">
                          <label>Field Name</label>
                          <input value={formFields['field_name']} autoComplete="off" onChange={handleChange} type="text" name="field_name" className="form-control" placeholder="Field Name" />
                        </div>
                        <div className="col-md-5">
                          <label>Field Sequence</label>
                          <input value={formFields['sequence']} autoComplete="off" onChange={handleChange} type="text" name="sequence" className="form-control" placeholder="Field Sequence" />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className={`col-md-7 ${getClass()}`}>
                          <label>Field Type</label>
                          <div className="styled-select">
                            <select name="field_type" value={formFields['field_type']} onChange={typeHandler}>
                              <option value="">Select Type</option>
                              <option value="text">Text</option>
                              <option value="number">Number</option>
                              <option value="date">Date</option>
                              <option value="time">Time</option>
                              <option value="select">Select</option>
                              <option value="multiselect">Multi Select</option>
                              <option value="radio">Radio</option>
                              <option value="checkbox">Checkboxes</option>
                            </select>
                            <span className="fa fa-angle-down"></span>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <label>Is Required?</label>
                          <div className="styled-select">
                            <select onChange={handleChange} name="is_required" value={formFields['is_required']}>
                              <option value="">Select</option>
                              <option value="1">Yes</option>
                              <option value="0">No</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className={`form-group ${formFields.options_visible ? '' : 'd-none'} ${getClass()}`}>
                        <label>Option Values</label>
                        {
                          formFields.field_options.map((object, index) => {
                            return (
                              <div key={index} className="posRel mb-3">
                                <input type="text" onChange={(event) => optionChangeHandler(event, index)} value={object.value} className="form-control" placeholder="Enter option value" style={{ width: '95%' }} />
                                <span className="removeCross"><a href="#" onClick={(event) => removeOption(event, index)}><i className="fa fa-close"></i></a></span>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div onClick={addOptionValue} className={`form-group text-left ${formFields.options_visible ? '' : 'd-none'} ${getClass()}`}><a href="#"><i className="fa fa-plus"></i>&nbsp; Add</a></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={submitHandler} type="button" className="btn btn-primary"><i className="fa fa-save"></i>&nbsp; Save</button>
              <button onClick={onModalClose} type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
            </div>
          </div>
        </div>
      </div>
      {
        props.isModalVisible && (
          <div className="modal-backdrop fade show"></div>
        )
      }
    </React.Fragment>
  );
}

export default Modal;