import React, { useState, useEffect } from "react";
import config from '../../config.json';
import axios from "axios";
import { getAPIHeaders } from "../../helpers/functions";
import { useToasts } from 'react-toast-notifications';

const InfoModal = (props) => {
    
    const style = {
        display: props.isModalVisible ? 'block' : 'none'
    };
    
    return (
    <React.Fragment>
        <div class="modal" id="rejectedModal" aria-hidden="true" style={style}>
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title" id="exampleModalLongTitle">Info about rejection</h3>
                        <button onClick={(event) => { props.infoModalHandler(event, false) }} type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body text-left">
                        <div class="form-group">
                            <label><strong> Reason:</strong>{'\u00A0'} { props.rejectionData.comment }</label>
                        </div>
                        <div class="form-group">
                            <label><strong> Rejected By:</strong>{'\u00A0'} { props.rejectionData.user } </label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button onClick={(event) => { props.infoModalHandler(event, false) }} type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        {
            props.isModalVisible && (
            <div className="modal-backdrop fade show"></div>
            )
        }
    </React.Fragment>
    );
}

export default InfoModal;