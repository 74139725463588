import React, { useState } from 'react';
import axios from "axios";
import config from '../../config.json';
import { useToasts } from 'react-toast-notifications';

const ForgotPassword = (props) => {

  const [formFields, setFormFields] = useState({ email: '' });
  const [validationError, setValidationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { addToast } = useToasts();
  const style = {
    display: props.isModalVisible ? 'block' : 'none'
  };

  const resetPasswordHandler = (event) => {
    event.preventDefault();
    axios.post(config.BaseURL + "/v1/forgot-password", formFields).then(function (response) {
      // setSuccessMessage(response.data.message);
      addToast(response.data.message, { appearance: 'success', autoDismiss: true });
      props.setForgotObject({
        modalVisible: false
      });
    }).catch(function (error) {
      // setValidationError(error.response.data.errors[0]);
      addToast(error.response.data.errors[0], { appearance: 'error', autoDismiss: true });
    });
  }

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    const formFieldsObject = {
      ...formFields,
      [name]: value
    }
    setFormFields(formFieldsObject);
  }

  return (
    <React.Fragment>
      <div className="modal" id="forgotpasswordModal"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={style}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLongTitle">Forgot Password</h3>
              <button onClick={props.onModalClose} type="button" className="close" >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form name="" action="">
                {
                  validationError &&
                  <div className="alert alert-danger" role="alert">
                    {validationError}
                  </div>
                }
                {
                  successMessage &&
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                }
                <div className="form-group">
                  <label>Enter Email</label>
                  <input name="email" onChange={handleChange} value={formFields['email']} type="text" className="form-control" placeholder="Enter Email" />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button onClick={props.onModalClose} type="button" className="btn btn-secondary" data-dismiss="modal"><i className="fa fa-close"></i>&nbsp; Cancel</button>
              <button onClick={resetPasswordHandler} type="button" className="btn btn-primary"><i className="fa fa-paper-plane"></i>&nbsp; Submit</button>
            </div>
          </div>
        </div>
      </div>
      { props.isModalVisible && (
        <div className="modal-backdrop fade show"></div>
      )}
    </React.Fragment>
  );
}

export default ForgotPassword;